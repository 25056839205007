import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {from, Observable, of} from 'rxjs';
import {ShoppingCartService} from '../../shopping-cart/shopping-cart.service';
import {map, switchMap, tap} from 'rxjs/operators';
import {SpinnerService} from '../spinner/spinner.service';
import {MiniCartService} from '../mini-cart/mini-cart.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ClearCartGuardPromptComponent} from './clear-cart-guard-prompt.component';

@Injectable({
    providedIn: 'root'
})
export class ClearCartGuard  {

    constructor(
        private miniCartService: MiniCartService,
        private cartService: ShoppingCartService,
        private spinnerService: SpinnerService,
        private modalService: NgbModal
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
        : Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

        // Any page that this guard is meant to protect  has the mini cart displayed on it
        // so the already loaded mini cart value should work
        if (!this.miniCartService.itemCount) {
            return of(true);
        }

        // Ask the user if they want to clear the cart
        const modalObservable = from(this.modalService.open(ClearCartGuardPromptComponent)
            .result
            .then((result) => {
                return !!result;
            }));

        return modalObservable
            .pipe(switchMap(result => {

                // Pass through false
                if (!result) {
                    return of(false);
                }

                this.spinnerService.pushShow('Clearing Cart');

                // Clear the cart if they chose to proceed
                return this.cartService.clearCart()
                    .pipe(map(clearResult => {
                        this.spinnerService.popShow();
                        if (clearResult.success) {
                            this.miniCartService.refreshCartDetails();
                        }
                        return clearResult.success;
                    }));
            }));
    }
}
