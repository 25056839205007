import { ClientAnalyticsEventDto } from './shared-analytics-api.model';

export interface CartDetailsDto {
    cartValid: boolean;
    invalidCartMessage: string;
    cartEmpty: boolean;
    cartEmptyMessage: string;
    cartTotals: CartTotalsDto;
    extPaymentMethodId: string;
    paymentMethodName: string;
    paymentProcessor: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    canDonate: boolean;
    canShareCart: boolean;
    primaryAddress: CartDetailsPrimaryAddressDto;
    shippingAddress: CartDetailsShippingAddressDto;
    itemGroups: CartDetailItemGroupDto[];
    installmentBilling: CartDetailsInstallmentBillingDto;
    cartRevised: boolean;
    reviseCartMessage: string;
    previousCart: CartDetailsDto;
    defaultDeliveryMethod: CartDetailDeliveryMethodDto;
    deliveryMethods: CartDetailDeliveryMethodDto[];
    hasWaivers: boolean;
    hotelStay: CartDetailHotelStayDto;
}

export interface CartDetailsPrimaryAddressDto {
    countryId: number;
    countryName: string;
    address1: string;
    address2: string;
    city: string;
    stateProvinceId: number;
    stateProvinceAbbreviation: string;
    stateProvinceName: string;
    otherStateProvince: string;
    zipCode: string;
    phoneNumber: string;
}

export interface CartDetailsShippingAddressDto {
    firstName: string;
    lastName: string;
    countryId: number;
    countryName: string;
    address1: string;
    address2: string;
    city: string;
    stateProvinceId: number;
    stateProvinceAbbreviation: string;
    stateProvinceName: string;
    otherStateProvince: string;
    zipCode: string;
    phoneNumber: string;
}


export interface CartTotalsDto {
    productTotal: number;
    cartTotalNetTaxesAndFees: number;
    taxTotal: number;
    feeTotal: number;
    deliveryTotal: number;
    cartTotal: number;
}

export interface CartDetailsInstallmentBillingDto {
    selected: boolean;
    eligible: boolean;
    blocked: boolean;
    planMismatch: boolean;
    extPlanId: string;
    paymentDivisor: number;
    fee: number;
    apr: number;
    downPayment: number;
    downPaymentLabel: string;
    amountFinanced: number;
    selectionWarning: string;
    planTypeId: number;
    planDisclaimer: string;
    planDescription: string;
    financeCharge: number;
    payments: CartDetailInstallmentBillingPaymentDto[];
}


export interface CartDetailInstallmentBillingPaymentDto {
    paymentNumber: number;
    paymentDate: string;
    paymentAmount: number;
}

export interface CartDetailItemGroupDto {
    productName: string;
    productDisplayName: string;
    productId: string;
    showId: string;
    showName: string;
    performanceId: string;
    performanceDate: string;
    actualPerformanceDate: string;
    performanceTime: string;
    showCategoryId: string;
    showCategoryName: string;
    groupTotal: number;
    allowPerformanceChange: boolean;
    isPackage: boolean;
    packageDisplayName: string;
    packageDescription: string;
    displayPackageItemPricingDetails: boolean;
    packageDiscountDescription: string;
    packageDiscountAmount: number;
    packageDiscountPercentage: number;
    items: CartDetailItemDto[];
    packageItemGroups: CartDetailItemGroupDto[];
}

export interface CartDetailItemDto {
    id: string;
    productVariantId: string;
    productId: string;
    productName: string;
    productVariantName: string;
    displayName: string;
    quantity: number;
    unitPrice: number;
    itemTotal: number;
    firstName: string;
    lastName: string;
    productVariantIsPass: boolean;
    effectivePassLevelName: string;
    saleFlowId: number;
    saleFlowName: string;
    incompleteWaiversMessage: string;
    seats: CartDetailItemSeatDto[];
    entitlements: CartDetailItemEntitlementDto[];
    deliveryItem: CartDetailDeliveryItemDto;
    offers: CartDetailItemOfferDto[];
    performances: CartDetailItemPerformanceDto[];
    promotions: CartDetailsItemPromotionDto[];
    waivers: CartDetailItemWaiverDto[];
}

export interface CartDetailsItemPromotionDto {
    id: string;
    promotionTypeId: number;
    promotionTypeName: string;
    promotionCode: string;
    tags: string;
}

export interface CartDetailItemPerformanceDto {
    id: string;
    performanceId: string;
    showName: string;
    showUIPrompt: string;
    performanceDate: string;
    performanceTime: string;
    showCategoryId: string;
    showCategoryName: string;
    isSingleSeat: boolean;
    seatDescription: string;
}

export interface CartDetailItemOfferDto {
    id: string;
    displayName: string;
    quantity: number;
    unitPriceAdjustment: number;
    adjustmentTotal: number;
}

export interface CartDetailItemEntitlementDto {
    id: string;
    productVariantId: string;
    productId: string;
    productName: string;
    productVariantName: string;
    displayName: string;
    quantity: number;
}

export interface CartDetailItemSeatDto {
    seatCategoryName: string;
    row: string;
    seat: string;
}

export interface CartDetailDeliveryMethodDto {
    id: string;
    deliveryMethodId: string;
    name: string;
    isDefault: boolean;
    deliveryItems: CartDetailDeliveryItemDto[];
}

export interface CartDetailDeliveryItemDto {
    id: string;
    productVariantId: string;
    productId: string;
    productName: string;
    productVariantName: string;
    displayName: string;
    quantity: number;
    unitPrice: number;
    itemTotal: number;
    shippingAddressRequired: boolean;
    isDefault: boolean;
}

export interface CartDetailItemWaiverDto {
    displayName: string
}

export interface ModifyCartResponseDto {
    confirmationRequired: boolean;
    confirmationTitleHtml: string;
    confirmationMessageHtml: string;
    proceedButtonText: string;
    cancelButtonText: string;
    confirmationReSubmitContext: string;
    analyticsEvent: ClientAnalyticsEventDto;
}

export interface AddToCartDto {
    items: ShoppingCartAddItemDto[];
    reSubmitContext: string;
    promotionCode: string;
    tags: string[];
    specificPromotionTypeId: number;
    promotionSaleFlowId: number;
    extPromotionId: string;
    saleFlowId?: number;
    tokenId?: string;
    tokeValue?: string;
}

export interface NewPassesWithProductDto {
    newPasses: NewPassesWithProductNewPassDto[];
    tags: string[];
    promotionCode: string;
    specificPromotionTypeId: number;
    promotionSaleFlowId: number;
    extPromotionId: string;
    saleFlowId: number;
}

export interface NewPassesWithProductNewPassDto {
    productId: string;
    productVariantId: string;
    firstName: string;
    lastName: string;
    birthDate: string;
    entitlementId: string;
}

export interface ShoppingCartAddItemDto {
    productVariantId?: string;
    productId?: string;
    quantity: number;
    entitlementId?: string;
    cartVenueId?: string;
    menuVariantId?: string;
    price?: number;
    modifiers?: AddToCartModifierDto[];
    performances?: ShoppingCartAddItemPerformanceDto[];
}

export interface AddToCartModifierDto{
    modifierId: string;
    quantity: number
}

export interface ShoppingCartAddItemPerformanceDto {
    selectionIndex: number;
    performanceId?: string;
    showCategoryId?: string;
    seatId?: string;
}

export interface AddToCartResponseDto {
    errors: ShoppingCartAddItemsResultErrorDto[];
    confirmationRequired: boolean;
    confirmationTitleHtml: string;
    ConfirmationMessageHtml: string;
    ProceedButtonText: string;
    CancelButtonText: string;
    ConfirmationReSubmitContext: string;
    ProceedToPassFlow: number;
    AnalyticsEvent: ClientAnalyticsEventDto;
}

export interface ShoppingCartAddItemsResultErrorDto {
    message: string;
    productId: string;
    productVariantId: string;
}

export interface CartAddress {
    countryId?: number;
    phoneUseNanpFormat: boolean;
    firstName?: string;
    lastName?: string;
    address1?: string;
    address2?: string;
    city?: string;
    stateProvinceId?: number;
    otherStateProvince?: string;
    zipCode?: string;
    phoneNumber?: string;
}

export interface CartSettingsUpdate {
    billingAddress?: CartAddress;
    shippingAddress?: CartAddress;

    emailAddress?: string;
    deliveryMethodId?: string;
    paymentMethodId?: string;
    acceptTerms?: boolean;
    termsOfUseVersionId?: string;
    termsOfSaleVersionId?: string;
    ignoreValidationErrors: boolean;
    shippingAddressRequired: boolean;
    saveUserAccountBillingAddress?: boolean;
}

export interface CartSettingsUpdateDto {
    firstName?: string;
    lastName?: string;
    phoneNumber?: string;
    emailAddress?: string;
    countryId?: number;
    address1?: string;
    address2?: string;
    city?: string;
    stateProvinceId?: number;
    otherStateProvince?: string;
    zipCode?: string;
    shippingCountryId?: number;
    shippingFirstName?: string;
    shippingLastName?: string;
    shippingAddress1?: string;
    shippingAddress2?: string;
    shippingCity?: string;
    shippingStateProvinceId?: number;
    shippingOtherStateProvince?: string;
    shippingZipCode?: string;
    shippingPhoneNumber?: string;
    deliveryMethodId?: string;
    paymentMethodId?: string;
    acceptTerms?: boolean;
    termsOfUseVersionId?: string;
    termsOfSaleVersionId?: string;
    ignoreValidationErrors: boolean;
}

export class CartAddressValidation {
    countryId: boolean = false;
    firstName: boolean = false;
    lastName: boolean = false;
    address1: boolean = false;
    city: boolean = false;
    stateProvinceId: boolean = false;
    otherStateProvince: boolean = false;
    phoneNumber: boolean = false;
    zipCodeFormat: boolean = false;
    zipCodeLength: boolean = false;

    anyInvalid(): boolean {
        return !(this.countryId
            && this.firstName
            && this.lastName
            && this.address1
            && this.city
            && this.stateProvinceId
            && this.otherStateProvince
            && this.phoneNumber
            && this.zipCodeFormat
            && this.zipCodeLength);
    }

    getInvalidFieldNames(prefix?: string): string[] {
        const validationMessages = [];
        prefix = prefix || '';
        if (!this.firstName) {
            validationMessages.push(prefix + 'First Name');
        }
        if (!this.lastName) {
            validationMessages.push(prefix + 'Last Name');
        }
        if (!this.phoneNumber) {
            validationMessages.push(prefix + 'Phone');
        }
        if (!this.countryId) {
            validationMessages.push(prefix + 'Country');
        }
        if (!this.address1) {
            validationMessages.push(prefix + 'Address 1');
        }
        if (!this.city) {
            validationMessages.push(prefix + 'City');
        }
        if (!this.stateProvinceId || !this.otherStateProvince) {
            validationMessages.push(prefix + 'State');
        }
        if (!this.zipCodeFormat || !this.zipCodeLength) {
            validationMessages.push(prefix + 'Zip');
        }

        return validationMessages;
    }
}

export interface UpdateCartSettingsResultDto {
    paymentDue: number;
}

export interface GenerateLinkDto {
    generateEmail: boolean;
    emailAddress: string;
    expirationInSeconds: number;
    customUserMessage: string;
    loginRequired: boolean;
}

export interface GenerateLinkResultDto {
    linkUrl: string;
    expirationDateTime: string;
    expirationTimeZoneName: string;
}

export interface CartPassResponseDto {
    isInstallmentBillingEligible: boolean;
    installmentBillingBlocked: boolean;
    capturePicture: boolean;
    passes: CartPassDto[];
}

export interface CartPassDto {
    extCartTicketId: string;
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    country: string;
    birthDate: string;
    isRenewal: boolean;
    isTicketUpgrade: boolean;
    lastDemographicsUpdate: Date;
    demographicsUpdateRequired: boolean;
    platformAccountId: number;
    productName: string;
    requiresProductSelection: boolean;
    selectedExtProductVariantId: string;
    allowNameChange: boolean;
    allowBirthDateChange: boolean;
    purchaseDate: string;
    productCode: string;
}

export interface CartPassUpdateDto {
    firstName: string;
    lastName: string;
    emailAddress: string;
    phoneNumber: string;
    address1: string;
    address2: string;
    city: string;
    stateProvinceId: number;
    otherStateProvince: string;
    zip: string;
    countryId: number;
    country: string;
    birthDate: string;
}

export interface AvailableDeliveryMethodDto {
    id: string;
    name: string;
    shippingAddressRequired: boolean;
    emailAddressRequired: boolean;
}

export interface AvailablePaymentMethodDto {
    id: string;
    name: string;
    processorUi: string;
}

export interface OrderWorkflowSelectionDto {
    id: number;
    displayName: string;
    description: string;
    iconName: string;
    available: boolean;
    notAvailableReason: string;
    underConstruction: boolean;
}

export interface ShareCartDto {
    sendEmail: boolean;
    generateLink: boolean;
    emailAddress: string;
    copyAccountEmail: boolean;
    customerUserMessage: string;
}

export interface ShareCartResponseDto {
    shareUrl: string;
    linkExpiration: Date;
    linkExpirationDisplay: string;
    cartTotal: number;
}


export interface CartHotelStayUpdateDto {
    adultCount: number;
    childCount: number;
    checkInDate: string;
    checkOutDate: string;
    roomCount: number;
}

export interface CartDetailHotelStayDto {
    adultCount: number;
    childCount: number;
    checkInDate: string;
    checkOutDate: string;
    roomCount: number;
}
