import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SelectClientPopupComponent } from './select-client-popup.component';
import { ClientSummaryDto } from './select-client.model';
import { SelectClientService } from './select-client.service';

@Component({
  selector: 'hfe-select-client',
  templateUrl: './select-client.component.html',
  styleUrls: ['./select-client.component.less']
})
export class SelectClientComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private selectClientService: SelectClientService
  ) { }

  ngOnInit(): void {
    this.modalService.open(SelectClientPopupComponent);
  }
}
