import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {SelectClientComponent} from './select-client/select-client.component';
import {AppConfigService} from './app-config.service';
import {HttpClientModule, HttpClientXsrfModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {
    NgbModalModule,
    NgbDatepickerModule,
    NgbDateParserFormatter,
    NgbDateAdapter,
    NgbAccordionModule
} from '@ng-bootstrap/ng-bootstrap';
import {SelectClientPopupComponent} from './select-client/select-client-popup.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {ResourceComponent} from './common/resource/resource.component';
import {PageHeaderComponent} from './common/page-header/page-header.component';
import {PageSectionComponent} from './common/page-section/page-section.component';
import {FaIconLibrary, FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {
    faCog,
    faShoppingCart,
    faCalendarAlt,
    faSync,
    faEdit,
    faWindowClose,
    faSignOutAlt,
    faQuestion,
    faUndoAlt,
    faPrint,
    faTicketAlt,
    faReceipt,
    faEnvelopeOpenText,
    faUser
} from '@fortawesome/pro-solid-svg-icons';
import {PageFooterComponent} from './common/page-footer/page-footer.component';
import {ProductSelectionComponent} from './product-selection/product-selection.component';
import {ReportsComponent} from './reports/reports.component';
import {ShoppingCartComponent} from './shopping-cart/shopping-cart.component';
import {
    LibCommerceSharedModule,
    StandardDateParserFormatterService,
    StandardDateAdapterService,
    SafeHtmlPipe
} from 'lib-commerce-shared';
import {MiniCartComponent} from './common/mini-cart/mini-cart.component';
import {CartItemChangeComponent} from './shopping-cart/cart-item-change.component';
import {CanDeactivateCheckout, CheckoutComponent} from './checkout/checkout.component';
import {FormsModule} from '@angular/forms';
import {CommonModule, DatePipe} from '@angular/common';
import {PaymentFreedomPayComponent} from './payment/payment-freedom-pay.component';
import {FreedomPayComponent} from './payment/freedom-pay.component';
import {CartSummaryComponent} from './common/cart-summary/cart-summary.component';
import {ConfirmationComponent} from './confirmation/confirmation.component';
import {OrderViewComponent} from './order-view/order-view.component';
import {OrderChallengePopupComponent} from './order-view/order-challenge-popup.component';
import {ExternalPageHeaderComponent} from './common/page-header/external-page-header.component';
import {ExternalPageFooterComponent} from './common/page-footer/external-page-footer.component';
import {ClearCartGuardPromptComponent} from './common/clear-cart-guard/clear-cart-guard-prompt.component';
import {OrderLookupViewComponent} from './order-lookup/order-lookup-view.component';
import {OrderLookupComponent} from './order-lookup/order-lookup.component';
import {ReportComponent} from './reports/report.component';
import {SessionTimeoutComponent} from './session-timeout/session-timeout.component';
import {TimeoutPopupComponent} from './session-timeout/timeout-popup.component';
import {CartItemRemoveConfirmationComponent} from './shopping-cart/cart-item-remove-confirmation.component';
import {SessionExpirationWarningComponent} from './common/idle/session-expiration-warning.component';
import {ResendDialogComponent} from './common/resend-dialog/resend-dialog.component';
import {CacheInterceptor} from './common/b2b-api-client/cache-interceptor';
import { TermsComponent } from './terms/terms.component';
import { TermsContentComponent } from './terms/terms-content.component';

const appInitializerFn = (appConfig: AppConfigService) => {
    return () => {
        return appConfig.loadSettings();
    };
};

@NgModule({
    declarations: [
        AppComponent,
        SelectClientComponent,
        SelectClientPopupComponent,
        DashboardComponent,
        ResourceComponent,
        PageHeaderComponent,
        PageSectionComponent,
        PageFooterComponent,
        ProductSelectionComponent,
        ReportsComponent,
        ShoppingCartComponent,
        MiniCartComponent,
        CartItemChangeComponent,
        CheckoutComponent,
        ConfirmationComponent,
        PaymentFreedomPayComponent,
        FreedomPayComponent,
        CartSummaryComponent,
        OrderViewComponent,
        OrderChallengePopupComponent,
        ExternalPageHeaderComponent,
        ExternalPageFooterComponent,
        ClearCartGuardPromptComponent,
        OrderLookupViewComponent,
        OrderLookupComponent,
        ReportComponent,
        SessionTimeoutComponent,
        TimeoutPopupComponent,
        CartItemRemoveConfirmationComponent,
        SessionExpirationWarningComponent,
        ResendDialogComponent,
        TermsComponent,
        TermsContentComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgbModalModule,
        FontAwesomeModule,
        LibCommerceSharedModule,
        FormsModule,
        NgbDatepickerModule,
        NgbAccordionModule,
        CommonModule
    ],
    providers: [
        CanDeactivateCheckout,
        AppConfigService,
        {
            provide: APP_INITIALIZER,
            useFactory: appInitializerFn,
            multi: true,
            deps: [AppConfigService]
        },
        {
            provide: NgbDateParserFormatter,
            useClass: StandardDateParserFormatterService
        },
        {
            provide: NgbDateAdapter,
            useClass: StandardDateAdapterService
        },
        DatePipe,
        { provide: HTTP_INTERCEPTORS, useClass: CacheInterceptor, multi: true },
        SafeHtmlPipe
    ],
    exports: [
        ResourceComponent,
        PageHeaderComponent
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(
            faCog,
            faShoppingCart,
            faCalendarAlt,
            faSync,
            faEdit,
            faWindowClose,
            faSignOutAlt,
            faQuestion,
            faUndoAlt,
            faPrint,
            faTicketAlt,
            faReceipt,
            faEnvelopeOpenText,
            faUser);
    }
}
