<div id="footer" class="footer-container-item">
    <div class="row">
        <div class="col-md-6">
            <hfe-resource extResourceId="b2bSiteCopyright">
            </hfe-resource>
        </div>
        <div class="col-md-6">
            <a href="#">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="#">Terms of Sale</a>&nbsp;&nbsp;|&nbsp;&nbsp;
            <a href="#">Terms of Use</a>
        </div>
    </div>
</div>
