import { ElementRef, Injectable, Input, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalSpinnerService {
  @Input() element: ElementRef;
  renderer: Renderer2;
  wrapper: HTMLDivElement;

  constructor(private factory: RendererFactory2) {
    this.renderer = factory.createRenderer(this.element, null);
   }

  show() {    
    if (this.element.nativeElement.querySelector('div.hidden-spinner')) {
      this.renderer.removeClass(this.wrapper, 'hidden-spinner');
      this.renderer.addClass(this.wrapper, 'local-spinner');
    }
    else {
      const spinner: HTMLDivElement = this.renderer.createElement('div');
      this.wrapper = this.renderer.createElement('div');          
      this.renderer.appendChild(this.wrapper, spinner);  
      this.renderer.addClass(this.wrapper, 'local-spinner');
      this.renderer.appendChild(this.element.nativeElement, this.wrapper);
    }
  }
  
  hide() {
    this.renderer.removeClass(this.wrapper, 'local-spinner');
    this.renderer.addClass(this.wrapper, 'hidden-spinner');
  }
}
