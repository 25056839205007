import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {MiniCartService} from './mini-cart.service';
import {Router} from "@angular/router";
import {NgModel} from "@angular/forms";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {ShoppingCartService} from "../../shopping-cart/shopping-cart.service";
import {AnalyticsService} from '../analytics/analytics.service';
import { SpinnerService } from '../spinner/spinner.service';

@Component({
    selector: 'hfe-mini-cart',
    templateUrl: './mini-cart.component.html',
    styleUrls: ['./mini-cart.component.less']
})
export class MiniCartComponent implements OnInit {

    constructor(
        public miniCartService: MiniCartService,
        private router: Router,
        private modalService: NgbModal,
        private cartService: ShoppingCartService,
        private analyticsService: AnalyticsService,
        private spinnerService: SpinnerService
    ) {
    }

    clearCartError = '';
    @Output() clearCart = new EventEmitter<any>();

    ngOnInit(): void {
        if (!this.miniCartService.loaded) {
            this.miniCartService.refreshCartDetails();
        }
    }

    goToProducts(): void {
        this.router.navigate(['/product-selection']);
    }

    clearCartAndGoToProducts(): void {
        this.modalService.dismissAll();
        this.spinnerService.pushShow("Clearing cart");

        this.analyticsService.logPageEventWithPrefix(
            'click',
            'engagement',
            `b2b-clear-yes`);

        this.cartService.clearCart()
            .subscribe(result => {
                this.spinnerService.popShow();

                if (result.success) {
                    this.goToProducts();
                    this.clearCart.emit();
                } else {
                    this.clearCartError = result.message;
                }
            }, error => {
                this.spinnerService.popShow();
                console.error(error);
                this.clearCartError = error.message;
            });
    }

    closeModal(): void {
        this.modalService.dismissAll();
    }

    openModal(content: any): void {
        this.modalService.open(content);
    }

}
