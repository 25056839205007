import {Component, OnInit} from '@angular/core';
import {OrderViewService} from './order-view.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderDetailsDto} from 'lib-commerce-shared';

@Component({
    selector: 'hfe-order-challenge-popup',
    templateUrl: './order-challenge-popup.component.html',
    styleUrls: ['./order-challenge-popup.component.less']
})
export class OrderChallengePopupComponent implements OnInit {

    constructor(
        private orderViewService: OrderViewService,
        private modalService: NgbModal
    ) {
    }

    extOrderTokenId: string;
    token: string;
    phoneNumber: string;
    errorMessage: string;

    ngOnInit(): void {
    }

    validateOrder(): void {
        //clear error
        this.errorMessage = '';
        if(this.invalidPhoneNumber()){
            this.errorMessage = 'A valid 10 digit phone number is required';
        }
        else{
            this.orderViewService.lookupOrder(
                this.extOrderTokenId,
                this.token,
                this.phoneNumber
            ).subscribe(result => {
                if (result.success) {
                    this.modalService.dismissAll(result.value);
                } else {
                    this.errorMessage = result.message;
                }
            }, error => {
                console.log(error);
                this.errorMessage = error.message;
            });
        }

    }

    invalidPhoneNumber(): boolean {
        const phone = this.phoneNumber ? this.phoneNumber.replace(/[^0-9]/g, '') : '';
        const cleanPhone = (phone ? phone : '').replace(/\D/g, '');
        return cleanPhone.length !== 10;
    }
}
