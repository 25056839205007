import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class StandardDateParserFormatterService {

  constructor() { }

  readonly DELIMITER = '/';

  parse(value: string): NgbDateStruct | null {
    if (this.isValid(value)) {
      const date = value.split(this.DELIMITER);
      return {
        month: parseInt(date[0], 10),
        day: parseInt(date[1], 10),
        year: parseInt(date[2], 10)
      };
    }
    return null;
  }

  parseAsDate(value: string): Date{
      const valueAsNgbDate = this.parse(value);
      if (valueAsNgbDate === null) {
          return null;
      }
      return new Date(`${valueAsNgbDate.month}/${valueAsNgbDate.day}/${valueAsNgbDate.year}`);
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.month + this.DELIMITER + date.day + this.DELIMITER + date.year : '';
  }

  isValid(inputText: string): boolean {
    if (!inputText) { return false; }

    // check against format
    const format = new RegExp('^(0?[1-9]|1[0-2])[\\' + this.DELIMITER + '](0?[1-9]|[1-2][0-9]|3[01])[\\' + this.DELIMITER + ']\\d{4}$');
    if (!inputText.match(format)) {
      return false;
    }

    // get date parts
    const parts = inputText.split(this.DELIMITER);
    const month = parseInt(parts[0], 10);
    const day = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    // get feb last day
    let febLastDay = 28;
    if ((!(year % 4) && year % 100) || !(year % 400)) {
      febLastDay = 29;
    }

    const lastDay = [31, febLastDay, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // check date
    if (day > lastDay[month - 1]) {
      return false;
    }

    return true;
  }

  getAge(value: string): number{
    const valueAsNgbDate = this.parse(value);    
    const birthDate = new Date(`${valueAsNgbDate.month}/${valueAsNgbDate.day}/${valueAsNgbDate.year}`);
    const today = new Date();
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDiff = today.getMonth() - birthDate.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDate.getDate())) 
    {
        age--;
    }
    return age;
  }
}
