import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'hfe-clear-cart-guard-prompt',
    templateUrl: './clear-cart-guard-prompt.component.html',
    styleUrls: ['./clear-cart-guard-prompt.component.less']
})
export class ClearCartGuardPromptComponent implements OnInit {

    constructor(
        private modal: NgbActiveModal
    ) {
    }

    ngOnInit(): void {
    }

    clearCart(): void {
        this.modal.close(true);
    }

    cancel(): void {
        this.modal.close(false);
    }

}
