import {Injectable} from '@angular/core';
import {B2BApiClientService} from '../common/b2b-api-client/b2b-api-client.service';
import {Observable, of} from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { KeyValue } from '@angular/common';
import { AppConfigService } from '../app-config.service';
import { Report } from 'lib-commerce-shared';

@Injectable({
    providedIn: 'root'
})
export class ReportingService {

    constructor(
        private http: HttpClient,
        private apiClient: B2BApiClientService,
        private appConfigService: AppConfigService
    ) {
    }

    getReports(): Observable<KeyValue<number, string>[]> {
        return of<KeyValue<number, string>[]>([{ key: 1, value: 'Reservation Detail by Client' },
                                                { key: 2, value: 'Reservation Summary by Client' },
                                                { key: 3, value: 'Transaction Detail by Client' },
                                                { key: 4, value: 'User List by Client' }]);
    }

    getReport(id: number): Observable<Report> {
        let report: Report;
        switch (+id) {
            case 1: report = {title: 'Reservation Detail by Client', path: 'reservation-detail-by-client', hasExcel: true, parameters: [
                    { label: 'Reservation Creation From Date', ssrsName: 'StartDate', type: 7 },
                    { label: 'Reservation Creation To Date', ssrsName: 'EndDate', type: 8 },
                    { label: 'Client', ssrsName: 'Client', type: 5, dataSource: 'client-name'},
                    { label: 'Reservation Status', ssrsName: 'ReservationStatus', type: 5, dataSource: 'tdm-reservation-status-names'},
                    { label: 'Payment Status', ssrsName: 'PaymentStatus', type: 5, dataSource: 'tdm-payment-status-names'}
                ]};
                break;
            case 2: report = {title: 'Reservation Summary by Client', path: 'reservation-summary-by-client', hasExcel: true, parameters: [
                    { label: 'Reservation Creation From Date', ssrsName: 'StartDate', type: 7 },
                    { label: 'Reservation Creation To Date', ssrsName: 'EndDate', type: 8 },
                    { label: 'Client', ssrsName: 'Client', type: 5, dataSource: 'client-name'},
                    { label: 'Reservation Status', ssrsName: 'ReservationStatus', type: 5, dataSource: 'tdm-reservation-status-names'},
                    { label: 'Payment Status', ssrsName: 'PaymentStatus', type: 5, dataSource: 'tdm-payment-status-names'}
                ]};
                break;
            case 3: report = {title: 'Transaction Detail by Client', path: 'transaction-detail-by-client', hasExcel: true, parameters: [
                    { label: 'Start Date', ssrsName: 'StartDate', type: 7 },
                    { label: 'End Date', ssrsName: 'EndDate', type: 8 },
                    { label: 'Client', ssrsName: 'Client', type: 5, dataSource: 'client-name'},
                    { label: 'Seller', ssrsName: 'Seller', type: 5, dataSource: 'seller-name'}
                ]};
                break;
            case 4: report = {title: 'User List by Client', path: 'user-list-by-client', hasExcel: true, parameters: [
                    { label: 'Location', ssrsName: 'TenantId', type: 5, dataSource: 'tenant-name'},
                    { label: 'Client', ssrsName: 'Client', type: 5, dataSource: 'client-name'},
                    { label: 'Status', ssrsName: 'StatusId', type: 5, dataSource: 'user-account-status-name' }]};
        break;
        }
        return of<Report>(report);
    }

    generate(report: Report, format: string): Observable<Blob> {
        return this.apiClient.b2bPost<Blob>(`report/${report.path}-${format}`,
          report.parameters.map(param => { return { name: param.ssrsName, value: param.value }}),
          new HttpHeaders({ "Access-Control-Allow-Origin": "*" }),
          'blob' as 'json');
    }

    getParameterValues(url: string): Observable<KeyValue<string, string>[]> {
        return this.apiClient.b2bGet<KeyValue<string, string>[]>(`report/${url}`);
    }
}
