import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ValidationService } from 'lib-commerce-shared';
import { LocalSpinnerService } from '../../local-spinner/local-spinner.service';
import { ResendDialogService } from './resend-dialog.service';

@Component({
  selector: 'hfe-resend-dialog',
  templateUrl: './resend-dialog.component.html',
  styleUrls: ['./resend-dialog.component.less']
})
export class ResendDialogComponent implements OnInit {
  @Input() email: string;
  @Input() orderId: string;
  resendConfirmationErrorMessage: string[];
  resendConfirmationSuccessMessage = '';

  constructor(
    private element: ElementRef,
    private activeModal: NgbActiveModal,
    private resendService: ResendDialogService,
    private spinner: LocalSpinnerService,
    private validationService: ValidationService
    ) { }

  ngOnInit(): void {
    this.spinner.element = this.element;
  }

  closeModal(): void {
    this.resendConfirmationSuccessMessage = '';
    this.resendConfirmationErrorMessage = [];
    this.activeModal.dismiss();
  }

  send() {
    this.spinner.show();
    this.resendConfirmationErrorMessage = [];
    this.resendConfirmationSuccessMessage = '';

    if(!this.validationService.emailAddress(this.email)) {
      this.resendConfirmationErrorMessage.push('A valid email address is required');
      this.spinner.hide();
      return;
    }
    
    this.resendService.send(this.orderId, this.email).subscribe(result => {
      if (result.success) {
        this.resendConfirmationSuccessMessage = 'Email confirmation sent successfully';
      }
      else {
        this.resendConfirmationErrorMessage.push(result.message);
      }
      this.spinner.hide();
    }, e => {
      console.log(e);
      this.resendConfirmationErrorMessage.push(e.message);
      this.spinner.hide();
    });
  }
}
