import {Component, ElementRef, HostListener, OnInit, AfterViewInit, ViewChild, ViewChildren, QueryList, NgZone} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbDate, NgbDateStruct, NgbInputDatepicker} from '@ng-bootstrap/ng-bootstrap';
import {CapacityLevel, StandardDateParserFormatterService} from 'lib-commerce-shared';
import {MiniCartService} from '../common/mini-cart/mini-cart.service';
import {ShoppingCartAddItemDto} from '../shopping-cart/shopping-cart.model';
import {ShoppingCartService} from '../shopping-cart/shopping-cart.service';
import {ProductShowDateDto, SelectionProductListDto, SelectionProductDto} from './product-selection.model';
import {ProductSelectionService} from './product-selection.service';
import {CartSummaryComponent} from '../common/cart-summary/cart-summary.component';
import {SpinnerService} from '../common/spinner/spinner.service';
import {AnalyticsService} from '../common/analytics/analytics.service';

@Component({
    selector: 'hfe-product-selection',
    templateUrl: './product-selection.component.html',
    styleUrls: ['./product-selection.component.less']
})
export class ProductSelectionComponent implements OnInit, AfterViewInit {

    constructor(
        private productSelectionService: ProductSelectionService,
        private shoppingCartService: ShoppingCartService,
        private router: Router,
        private miniCartService: MiniCartService,
        private dateFormatter: StandardDateParserFormatterService,
        private route: ActivatedRoute,
        private spinnerService: SpinnerService,
        private zone: NgZone,
        private analyticsService: AnalyticsService
    ) {
    }

    @ViewChild('btnCheckout') btnCheckout: ElementRef;
    @ViewChild('footerMarker') footer: ElementRef;
    checkoutButtonSticky = true;
    productList: SelectionProductListDto;
    errorMessage = '';
    productErrors: any = {};
    variantErrors: any = {};
    showDates: ProductShowDateDto[];
    minDate: NgbDateStruct;
    @ViewChild('ipcs') inPageCartSummary: CartSummaryComponent;

    @ViewChildren('dp', {read: NgbInputDatepicker}) pickers: QueryList<NgbInputDatepicker>;

    ngAfterViewInit(): void {
        this.onWindowScroll();
    }

    ngOnInit(): void {
        this.analyticsService.logPageView('/product-selection');
        this.load();
        const today = new Date();
        this.minDate = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
    }

    load(): void {
        this.spinnerService.pushShow('Loading Products');
        this.productSelectionService.getProductList().subscribe(productList => {
            this.productList = productList;

            // console.log(this.productList);

            // get dates for products requiring shows
            const showProducts = [];
            this.productList.productCategories.forEach(c => {
                c.subCategories.forEach(sc => {
                    sc.products.forEach(p => {
                        if (p.hasShows) {
                            showProducts.push(p.id);
                        }
                    });
                });
            });
            if (showProducts.length > 0) {
                this.productSelectionService.getProductShowDates(showProducts).subscribe(result => {
                    if (result.success) {
                        this.showDates = result.productShowDates;
                    } else {
                        console.log(result.message);
                    }
                    this.spinnerService.popShow();
                }, error => {
                    console.log(error);
                    this.spinnerService.popShow();
                });
            } else {
                this.spinnerService.popShow();
            }

            setTimeout(() => {
                this.onWindowScroll();
            });
        });
    }

    unavailable(id: string): (date: NgbDate) => boolean {
        if (this.showDates) {
            return (date) => {
                const day = this.showDates
                    .find(showDate =>
                        (new Date(showDate.date)).getTime() === (new Date(date.year, date.month - 1, date.day)).getTime());
                if (day && day.productIds.find(productId => productId === id)) {
                    return false;
                }
                return true;
            };
        }
        return (date) => true;
    }

    dateChanged($event, product: SelectionProductDto): void {

        // wipe out previous selections and data
        this.productErrors = {};
        this.variantErrors = {};
        product.productMessage = null;
        product.showPerformances = undefined;
        product.shows = undefined;
        product.selectedShow = undefined;
        product.performances = undefined;
        product.selectedPerformance = undefined;
        product.performanceCategories = undefined;
        product.selectedPerformanceCategory = undefined;
        product.performanceVariants = undefined;
        product.categoryVariants = undefined;

        // need a valid date to continue
        if (!this.dateFormatter.isValid($event) || this.unavailable(product.id)(NgbDate.from(this.dateFormatter.parse($event)))) {
            return;
        }

        // get performances
        product.selectedDate = $event;
        this.productSelectionService.getProductDatePerformances(product.id, product.selectedDate)
            .subscribe(result => {
                if (result.success) {
                    if(result.performances.length == 0) {
                        product.productMessage = result.message;
                    }
                    else {
                        // populate the performances for each product queried
                        this.productList.productCategories.forEach(category => {
                            category.subCategories.forEach(subCategory => {
                                subCategory.products.forEach(prod => {

                                    // Only match on products with the same selected date
                                    if (product.id === prod.id
                                        && product.selectedDate === prod.selectedDate) {
                                        prod.showPerformances = result.performances.filter(perf => perf.productId === prod.id);
                                        this.setupShows(prod);
                                    }
                                });
                            });
                        });
                    }

                } else {
                    product.showPerformances = undefined;
                    product.shows = undefined;
                    product.selectedShow = undefined;
                    product.performances = undefined;
                    product.selectedPerformance = undefined;
                    product.performanceCategories = undefined;
                    product.selectedPerformanceCategory = undefined;
                    product.performanceVariants = undefined;
                    product.categoryVariants = undefined;
                    console.log(result.message);
                }
            }, error => {
                product.showPerformances = undefined;
                product.shows = undefined;
                product.selectedShow = undefined;
                product.performances = undefined;
                product.selectedPerformance = undefined;
                product.performanceCategories = undefined;
                product.selectedPerformanceCategory = undefined;
                product.performanceVariants = undefined;
                product.categoryVariants = undefined;
                console.log(error);
            });
    }

    setupShows(product: SelectionProductDto): void {

        // build show list
        const applicableShows = product.showPerformances.reduce((prev, current) => {
            const found = prev.find(item => item.key === current.showId);
            if (!found) {
                prev.push({key: current.showId, value: current.showName, alwaysPromptShow: current.alwaysPromptShow});
            }
            return prev;
        }, []);
        product.shows = [{key: '', value: 'Select a Show'}]
            .concat(applicableShows);

        // Determine whether we should prompt for show
        product.promptShows = product.shows.length > 2
            || (applicableShows.length === 1 && applicableShows[0].alwaysPromptShow);

        // set current selection
        if (product.shows.length === 2) {
            product.selectedShow = product.shows[1].key;
            this.setupPerformances(product);
        } else {
            product.selectedShow = '';
        }
    }

    selectShow(product: SelectionProductDto): void {
        this.productErrors = {};
        this.variantErrors = {};
        product.performances = undefined;
        product.selectedPerformance = undefined;
        product.performanceCategories = undefined;
        product.selectedPerformanceCategory = undefined;
        product.performanceVariants = undefined;
        product.categoryVariants = undefined;
        if (!product.selectedShow) {
            return;
        }

        this.setupPerformances(product);
    }

    setupPerformances(product: SelectionProductDto): void {

        // get performances
        const applicablePerformances = product.showPerformances.filter(performance => performance.showId === product.selectedShow);
        product.performances = ([{
            productId: null,
            date: null,
            performanceId: null,
            showId: null,
            showName: null,
            alwaysPromptShow: null,
            performanceTime: 'Select a Time',
            alwaysPromptPerformance: null,
            capacityLevelId: null,
            capacityDescription: null
        }]).concat(applicablePerformances);

        // set current selection
        if (product.performances.length === 2) {
            product.selectedPerformance = product.performances[1].performanceId;
            this.setupPerformanceCategories(product);
        } else {
            product.selectedPerformance = '';
        }

        // Determine whether we should show performances
        product.promptPerformances = product.performances.length > 2
            || (applicablePerformances.length === 1 && applicablePerformances[0].alwaysPromptPerformance);
    }

    selectPerformance(product: SelectionProductDto): void {
        this.productErrors = {};
        this.variantErrors = {};
        product.performanceCategories = undefined;
        product.selectedPerformanceCategory = undefined;
        product.performanceVariants = undefined;
        product.categoryVariants = undefined;
        if (!product.selectedPerformance) {
            return;
        }

        this.setupPerformanceCategories(product);
    }

    setupPerformanceCategories(product: SelectionProductDto): void {

        this.spinnerService.pushShow('Loading Product Details');
        this.productSelectionService.getPerformanceDetails(
            product.id,
            product.selectedPerformance)
            .subscribe(result => {
                if (result.success) {

                    product.productMessage = '';
                    product.empty = false;

                    if (result.productVariants.length === 0
                        && result.showCategories.length === 0) {
                        product.empty = true;
                    } else {
                        product.productMessage = '';
                    }

                    product.performanceCategories = ([{
                        productId: null,
                        performanceId: null,
                        showCategoryId: null,
                        name: 'Select a Category',
                        capacityLevelId: null,
                        capacityDescription: null
                    }]).concat(result.showCategories);
                    product.performanceVariants = result.productVariants;

                    // set current selection
                    if (product.performanceCategories.length === 2) {
                        product.selectedPerformanceCategory = product.performanceCategories[1].showCategoryId;
                        this.selectPerformanceCategory(product);
                    } else {
                        product.selectedPerformanceCategory = '';
                    }
                } else {
                    product.productMessage = result.message;
                    product.performanceCategories = product.performanceVariants = undefined;
                    console.log(result.message);
                }
                this.spinnerService.popShow();
            }, error => {
                product.performanceCategories = product.performanceVariants = undefined;
                console.log(error);
                this.spinnerService.popShow();
            });
    }

    selectPerformanceCategory(product: SelectionProductDto): void {
        product.categoryVariants = undefined;
        this.productErrors = {};
        if (!product.selectedPerformanceCategory) {
            return;
        }
        this.spinnerService.pushShow('Loading Product Details');
        this.productSelectionService.getCategoryDetails(
            product.id,
            product.selectedPerformance,
            product.selectedPerformanceCategory)
            .subscribe(result => {
                product.empty = false;
                product.productMessage = '';
                if (result.success) {
                    product.empty = result.variants.length === 0;
                    product.categoryVariants = result.variants;
                } else {
                    product.productMessage = result.message;
                    product.categoryVariants = undefined;
                    console.log(result.message);
                }
                this.spinnerService.popShow();
            }, error => {
                product.categoryVariants = undefined;
                console.log(error);
                this.spinnerService.popShow();
            });
    }

    updateVariantQuantity(product: SelectionProductDto, variantId: string, qty: number): void {
        product.staticVariants.find(v => v.id === variantId).quantity = qty;
    }

    updatePerformanceVariantQuantity(product: SelectionProductDto, variantId: string, qty: number): void {
        product.performanceVariants.find(v => v.productVariantId === variantId).quantity = qty;
    }

    updateCategoryVariantQuantity(product: SelectionProductDto, variantId: string, qty: number): void {
        product.categoryVariants.find(v => v.productVariantId === variantId).quantity = qty;
    }

    showAddToCart(product: SelectionProductDto): boolean {
        return (product.staticVariants && product.staticVariants.length > 0)
            || (product.performanceVariants && product.performanceVariants.length > 0)
            || (product.categoryVariants && product.categoryVariants.length > 0);
    }

    canAddToCart(product: SelectionProductDto): boolean {
        return (product.staticVariants && product.staticVariants.filter(o => o.quantity > 0).length > 0)
            || (product.performanceVariants && product.performanceVariants.filter(o => o.quantity > 0).length > 0)
            || (product.categoryVariants && product.categoryVariants.filter(o => o.quantity > 0).length > 0);
    }


    addToCart(product: SelectionProductDto): void {

        this.productErrors = {};
        this.variantErrors = {};

        const items: ShoppingCartAddItemDto[] = [];

        if (product.staticVariants) {
            product.staticVariants.forEach(v => {
                if (v.quantity > 0) {
                    items.push(
                        {
                            productId: product.id,
                            productVariantId: v.id,
                            quantity: v.quantity,
                            performanceId: null,
                            showCategoryId: null
                        });
                }
            });
        }
        if (product.performanceVariants) {
            product.performanceVariants.forEach(v => {
                if (v.quantity > 0) {
                    items.push(
                        {
                            productId: product.id,
                            productVariantId: v.productVariantId,
                            quantity: v.quantity,
                            performanceId: v.performanceId,
                            showCategoryId: v.showCategoryId
                        });
                }
            });
        }
        if (product.categoryVariants) {
            product.categoryVariants.forEach(v => {
                if (v.quantity > 0) {
                    items.push({
                        productId: product.id,
                        productVariantId: v.productVariantId,
                        quantity: v.quantity,
                        performanceId: v.performanceId,
                        showCategoryId: product.selectedPerformanceCategory
                    });
                }
            });
        }

        if (items.length === 0) {
            this.errorMessage = 'Ticket quantity cannot be zero. Please adjust the number of tickets needed and try again.';
        } else {

            this.spinnerService.pushShow('Adding Items To Cart');
            this.shoppingCartService
                .addToShoppingCart(items)
                .subscribe(result => {

                    // Reset error messages
                    this.productErrors = {};
                    this.variantErrors = {};
                    this.errorMessage = '';

                    if (result.success) {
                        this.refreshCart();

                        if (product.staticVariants) {
                            product.staticVariants.forEach(v => v.quantity = 0);
                        }
                        if (product.performanceVariants) {
                            product.performanceVariants.forEach(v => v.quantity = 0);
                        }
                        if (product.categoryVariants) {
                            product.categoryVariants.forEach(v => v.quantity = 0);
                        }


                    } else {
                        result.value.forEach(error => {
                            if (error.productId) {
                                this.productErrors[error.productId] = error.message;
                            }
                            if (error.productVariantId) {
                                this.variantErrors[error.productVariantId] = error.message;
                            }
                        });
                    }

                    this.spinnerService.popShow();
                }, error => {
                    console.log(error);
                    this.spinnerService.popShow();
                    this.errorMessage = error.message;
                });
        }
    }

    headerCartChange(): void {
        this.load();
        this.refreshCart();
    }

    refreshCart(): void {
        this.inPageCartSummary.load();
    }

    checkOut(): void {
        this.router.navigate(['/checkout']);
    }


    @HostListener('window:scroll', [])
    onWindowScroll(): void {

        // Calls before we know the elements don't mean anything
        if (!this.btnCheckout || !this.footer || !this.productList) {
            return;
        }

        this.zone.runOutsideAngular(() => {

            const footerOffsetTop = this.getOffsetTop(this.footer);
            if (document.documentElement.scrollTop + window.innerHeight < footerOffsetTop) {
                if (!this.checkoutButtonSticky) {
                    this.zone.run(() => {
                        this.checkoutButtonSticky = true;
                    });
                }
            } else {
                if (this.checkoutButtonSticky) {
                    this.zone.run(() => {
                        this.checkoutButtonSticky = false;
                    });
                }
            }
        });
    }

    getOffsetTop(element: ElementRef): number {
        const rect = element.nativeElement.getBoundingClientRect();
        return rect.top + window.pageYOffset - document.documentElement.clientTop;
    }

    canCheckOut(): boolean {
        return this.miniCartService.itemCount > 0;
    }

    getOptionLabel(label: string, capacityLevelId: CapacityLevel, capacityDescription: string): string {
        if (!capacityDescription
            || capacityLevelId === CapacityLevel.DataUnavailable
            || capacityLevelId === CapacityLevel.Unavailable) {
            return label;
        }

        return `${label} - ${capacityDescription}`
    }
}
