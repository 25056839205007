import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TermsService } from './terms.service';

@Component({
  selector: 'hfe-terms-content',
  templateUrl: './terms-content.component.html',
  styleUrls: ['./terms-content.component.scss']
})
export class TermsContentComponent {

  constructor(
    private termsService: TermsService
  ) { }

  @Input() extVersionId: string;
  @Output() loaded = new EventEmitter<boolean>();
  @Output() errorMessage = new EventEmitter<string>();
  termsContent: string;

  ngOnInit(): void {
    this.termsService.getTermsContent(this.extVersionId)
      .subscribe(
        termsContent => {
          this.termsContent = termsContent.contentText;
          this.loaded.emit(true);
        },
        error => {
          this.loaded.emit(false);
          console.error(error);
          this.errorMessage.emit('Unable to load terms');
        });
  }
}
