<hfe-page-header [hasCart]="false">
  <a routerLink="/dashboard">Dashboard</a>
  <span class="px-2">/</span>
  <a routerLink="/reports">Reports</a>
  <span class="px-2">/</span>
  {{report.title}}
</hfe-page-header>
<div *ngIf="pdfSource" class="action-container px-3 py-3">
  <button type="button" class="btn btn-primary" (click)="pdfSource=null">Change Parameters</button>  
</div>
<div class="section-container" *ngIf="!pdfSource">
  <ngb-accordion #accordion activeIds="mainPanel">
    <ngb-panel id="mainPanel">
      <ng-template ngbPanelHeader>
        <div class="header-container d-flex align-items-center justify-content-between w-100">
          <h5 class="m-3">Parameters</h5>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
        <hfe-report-parameters [parameters]="report.parameters"></hfe-report-parameters>
        <div *ngIf="error" class="row justify-content-end error">{{error}}</div>
        <div class="generate row justify-content-end">
          <button type="button" class="w-auto btn btn-primary" (click)="reset()">Reset Parameters</button>
          <button *ngIf="report.hasExcel" type="button" class="w-auto btn btn-primary" (click)="generate('xlsx')">Download to Excel</button>
          <button type="button" class="w-auto btn btn-primary" (click)="generate('pdf')">Generate Report</button>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
<iframe *ngIf="pdfSource" [attr.src]="pdfSource" type="application/pdf" #reportContainer (load)="iframeLoaded($event)"></iframe>