import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {DashboardComponent} from './dashboard/dashboard.component';
import {SelectClientComponent} from './select-client/select-client.component';
import {ClientSelectGuard} from './common/client-selection/client-select-guard.service';
import {AuthorizedGuard} from './common/authorization/authorized-guard.service';
import {ReportsComponent} from './reports/reports.component';
import {ProductSelectionComponent} from './product-selection/product-selection.component';
import {ShoppingCartComponent} from './shopping-cart/shopping-cart.component';
import {CanDeactivateCheckout, CheckoutComponent} from './checkout/checkout.component';
import {PaymentFreedomPayComponent} from './payment/payment-freedom-pay.component';
import {ConfirmationComponent} from './confirmation/confirmation.component';
import {OrderViewComponent} from './order-view/order-view.component';
import {ClearCartGuard} from './common/clear-cart-guard/clear-cart-guard.service';
import {OrderLookupComponent} from './order-lookup/order-lookup.component';
import {OrderLookupViewComponent} from './order-lookup/order-lookup-view.component';
import {ReportComponent} from './reports/report.component';
import {SessionTimeoutComponent} from './session-timeout/session-timeout.component';
import { TermsComponent } from './terms/terms.component';
import { ComponentStringResourceLoader } from './common/resource/ComponentStringResourceLoader';

const globalStringResources: string[] = [
    'b2bSiteCopyright'
];

const routes: Routes = [
    {
        path: 'select-client',
        component: SelectClientComponent,
        canActivate: [AuthorizedGuard]
    },
    {
        path: 'dashboard',
        component: DashboardComponent,
        canActivate: [
            AuthorizedGuard,
            ClientSelectGuard,
            ClearCartGuard
        ]
    },
    {
        path: 'product-selection',
        component: ProductSelectionComponent,
        canActivate: [AuthorizedGuard, ClientSelectGuard],
        runGuardsAndResolvers: 'always'
    },
    {
        path: 'reports',
        component: ReportsComponent,
        canActivate: [
            AuthorizedGuard,
            ClientSelectGuard,
            ClearCartGuard
        ]
    },
    {
        path: 'report/:id',
        component: ReportComponent,
        canActivate: [
            AuthorizedGuard,
            ClientSelectGuard,
            ClearCartGuard
        ]
    },
    {
        path: 'shopping-cart',
        component: ShoppingCartComponent,
        canActivate: [AuthorizedGuard, ClientSelectGuard]
    },
    {
        path: 'checkout',
        component: CheckoutComponent,
        canActivate: [AuthorizedGuard, ClientSelectGuard],
        canDeactivate: [CanDeactivateCheckout]
    },
    {
        path: 'payment/freedom-pay',
        component: PaymentFreedomPayComponent,
        canActivate: [AuthorizedGuard, ClientSelectGuard]
    },
    {
        path: 'confirmation',
        component: ConfirmationComponent,
        canActivate: [AuthorizedGuard, ClientSelectGuard]
    },
    {
        path: 'order-lookup',
        component: OrderLookupComponent,
        canActivate: [AuthorizedGuard, ClientSelectGuard]
    },
    {
        path: 'order-lookup/:extOrderId',
        component: OrderLookupViewComponent,
        canActivate: [AuthorizedGuard, ClientSelectGuard]
    },
    {
        path: 'order-view/:extOrderTokenId/:token',
        component: OrderViewComponent
    },
    {
        path: 'terms/:id',
        component: TermsComponent,
        canActivate: [ComponentStringResourceLoader],
        data: {
            stringResources: [
                ...globalStringResources,
                ...TermsComponent.stringResources
            ]
        }
    },
    {
        path: 'session-timeout',
        component: SessionTimeoutComponent
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard'
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        {
            onSameUrlNavigation: 'reload',
            anchorScrolling: 'enabled'
        })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
