<div class="modal-content">
    <div class="modal-header">
        Select Location
    </div>
    <div class="modal-body">
        <div class="alert alert-danger" *ngIf="clients && clients.length == 0">
            <hfe-resource extResourceId="B2BClientSelectNoClientsMessage"></hfe-resource>
        </div>
        <table class="table table-hover table-borderless" *ngIf="clients && clients.length > 0">
            <tbody>
                <tr *ngFor="let client of clients" (click)="selectClient(client)">
                    <td>
                        {{client.tenantDisplayName}} ({{client.name}})
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="alert alert-danger my-2" *ngIf="errorMessage">
            {{errorMessage}}
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-secondary" (click)="logout()">Log Out</button>
    </div>
</div>
