import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import {SessionService} from "../session/session.service";
import {Observable} from "rxjs";
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ClientSelectGuard  {

  constructor(
    private sessionService: SessionService,
    private router: Router
  ) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {

    return this.sessionService.getCurrentSessionData()
      .pipe(map(sessionData => {
        if (!sessionData
          || !sessionData.userAuthenticated
          || !sessionData.tenantDisplayName) {
          this.router.navigate(['/select-client'],
            {
              queryParams: {
                return: state.url
              }
            });
          return false;
        }
        return true;
      }));
  }
}
