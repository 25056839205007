import { Component } from '@angular/core';
import {SpinnerService} from './common/spinner/spinner.service';
import {SessionService} from './common/session/session.service';

@Component({
  selector: 'hfe-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent {
    constructor(
        public spinnerService: SpinnerService,
        public sessionService: SessionService
    ) {
    }

  title = 'Herschend B2B Portal';
}
