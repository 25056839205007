<form>
  <div class="row">
    <ng-container *ngFor="let param of nonCheckboxParams(); let i = index">
      <div class="form-field mb-3 col-6">
        <label *ngIf="param.type !== 2" for="param{{i}}">{{param.label}}</label>
        <div *ngIf="param.type === 1 || param.type === 7 || param.type === 8" class="input-group">
          <input class="form-control" placeholder="mm/dd/yyyy" id="param{{i}}"
                  name="param{{i}}"
                  ngbDatepicker [firstDayOfWeek]="7"
                  #dp="ngbDatepicker" [ngModel]="param.value"
                  (ngModelChange)="param.value = $event"/>
            <button class="h-100 btn btn-outline-secondary calendar" (click)="dp.toggle()" type="button">
              <fa-icon icon="calendar-alt"></fa-icon>
            </button>
        </div>
        <input *ngIf="param.type === 3" type="text" class="form-control" id="param{{i}}" [(ngModel)]="param.value" name="param{{i}}">
        <input *ngIf="param.type === 4" type="number" class="form-control" id="param{{i}}" [(ngModel)]="param.value" name="param{{i}}">
        <select *ngIf="param.type === 5" id="param{{i}}" class="form-control" [(ngModel)]="param.value" name="param{{i}}">
          <option *ngIf="!param.required" [value]="" selected></option>
          <option *ngFor="let item of param.dataSourceValues | async" [value]="item.key">{{item.value}}</option>
        </select>
        <!-- Does not work after multi-select refactoring from #11076; needs adaptation
        <hfe-multi-select *ngIf="param.type === 6" [items]="param.dataSourceValues" [(ngModel)]="param.value" name="param{{i}}"></hfe-multi-select>
        -->
      </div>
    </ng-container>
  </div>
  <div class="checkboxes">
    <ng-container *ngFor="let cb of checkboxParams(); let i = index">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" id="cb{{i}}" name="cb{{i}}" [(ngModel)]="cb.value">
        <label class="form-check-label" for="cb{{i}}">
          Check me out
        </label>
      </div>
    </ng-container>
  </div>
  <div *ngIf="errors.length > 0" class="row justify-content-end error">
    <div>
      <div *ngFor="let error of errors">{{error}}</div>
    </div>
  </div>
</form>
