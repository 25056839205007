import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpHeaders, HttpEvent} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class CacheInterceptor implements HttpInterceptor {

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.url.indexOf('/api/') >= 0 || req.url.indexOf('/settings') >= 0) {

            // Why isn't this on the server side?
            const newHeaders: HttpHeaders = req.headers ?? new HttpHeaders();
            newHeaders.set('Cache-Control', 'no-cache');
            newHeaders.set('Pragma', 'no-cache');
            newHeaders.set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
            const httpRequest = req.clone({
                headers: newHeaders
            });

            // Call the next handler
            return next.handle(httpRequest);
        }
        return next.handle(req);
    }
}
