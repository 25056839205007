import {Component, OnInit} from '@angular/core';
import {OrderDetailsDto, OrderDetailsPaymentDto} from 'lib-commerce-shared';
import {SpinnerService} from '../common/spinner/spinner.service';
import {OrderLookupService} from './order-lookup.service';
import {ActivatedRoute} from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResendDialogComponent } from '../common/resend-dialog/resend-dialog.component';

@Component({
    selector: 'hfe-order-lookup-view',
    templateUrl: './order-lookup-view.component.html',
    styleUrls: ['./order-lookup-view.component.less']
})
export class OrderLookupViewComponent implements OnInit {

    constructor(
        private route: ActivatedRoute,
        private spinner: SpinnerService,
        private orderLookupService: OrderLookupService,
        private modalService: NgbModal
    ) {
    }

    extOrderId: string;
    order: OrderDetailsDto;
    payment: OrderDetailsPaymentDto;
    ticketContent: string;
    errorMessage: string;

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.extOrderId = params.extOrderId;
            this.load();
        });
    }

    load(): void {
        this.spinner.pushShow('Loading Confirmation');
        this.orderLookupService.getOrderDetails(this.extOrderId)
            .subscribe(order => {
                this.order = order;
                if (order.payments.length > 0) {
                    this.payment = order.payments[0];
                }
                if (order.hasTickets) {
                    this.orderLookupService.getTicketContent(this.extOrderId)
                        .subscribe(content => {
                            this.spinner.popShow();
                            this.ticketContent = content;
                        }, error => {
                            console.log(error);
                            this.errorMessage = error.message;
                            this.spinner.popShow();
                        });
                } else {
                    this.spinner.popShow();
                }
            }, error => {
                console.log(error);
                this.errorMessage = error.message;
                this.spinner.popShow();
            });
    }

    print(): void {
        window.print();
    }

    resend() {
        const modalRef = this.modalService.open(ResendDialogComponent, { centered: true });
        modalRef.componentInstance.email = this.order.emailAddress;
        modalRef.componentInstance.orderId = this.order.id;
    }

    getTransactionString(): string {
        let returnString = '';
        this.order.trans.forEach(o => {
            if (o.transactionString) {
                returnString = returnString.concat(o.transactionString + ' | ');
            }
        });
        return returnString.slice(0, returnString.lastIndexOf('|'));
    }

    getReservationCode(): string {
        if (!this.order
            || this.order.trans.length === 0) {
            return "";
        }
        return this.order.trans[0].platformReservationCode;
    }
}
