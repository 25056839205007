import { Injectable } from '@angular/core';
import { ApiResultDto, ApiResultWithValueDto } from 'lib-commerce-shared';
import { Observable } from 'rxjs';
import { B2BApiClientService } from '../common/b2b-api-client/b2b-api-client.service';
import {
    CartDetailsDto,
    CartItemModifyDto,
    ShoppingCartAddItemDto,
    ShoppingCartAddItemsResultErrorDto
} from './shopping-cart.model';

@Injectable({
  providedIn: 'root'
})
export class ShoppingCartService {

  constructor(
    private apiClient: B2BApiClientService
  ) { }

  getCartDetails(): Observable<CartDetailsDto> {
    return this.apiClient.b2bGet<CartDetailsDto>('cart');
  }

  addToShoppingCart(items: ShoppingCartAddItemDto[]): Observable<ApiResultWithValueDto<ShoppingCartAddItemsResultErrorDto[]>> {
    return this.apiClient.b2bPost<ApiResultDto>('cart/add', items);
  }

  clearCart(): Observable<ApiResultDto> {
    return this.apiClient.b2bPost('cart/clear', {});
  }

  modifyCartItem(items: CartItemModifyDto[]): Observable<ApiResultDto>{
    return this.apiClient.b2bPost<ApiResultDto>(`cart/modify`, items);
  }
}
