import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, shareReplay, tap} from 'rxjs/operators';
import {B2BApiClientService} from '../b2b-api-client/b2b-api-client.service';
import {PreloadResultDto, ResourceLookupResponseDto} from './resource.model';
import { SpinnerService } from '../spinner/spinner.service';

@Injectable({
    providedIn: 'root'
})
export class ResourceService {

    resourceCache: Map<string, Observable<string>> = new Map();

    constructor(
        private b2bApiClient: B2BApiClientService,
        private spinner: SpinnerService
    ) {
    }

    preLoadValues(extResourceIds: string[]): Observable<boolean> {

        // Filter out resources we've already loaded
        extResourceIds = extResourceIds.filter(o => !this.resourceCache.has(o));

        // nothing to do
        if (extResourceIds.length == 0) {
            return of(true);
        }

        // When subscribed to tap into the results and save the preloaded values to the cache
        this.spinner.pushShow('Loading resources');
        return this.b2bApiClient.b2bPost<PreloadResultDto>('resource/pre-load', {
            extResourceIds: extResourceIds
        }).pipe(
            tap(result => {
                this.spinner.popShow();
                result.resourceValues.forEach(resourceValue => {
                    this.resourceCache.set(resourceValue.extResourceId, of(resourceValue.value));
                })
            }),
            map(result => {
                return true;
            })
        );

    }

    getResourceValue(extResourceId: string, variables?: any, forceRefresh: boolean = false): Observable<string> {

        // See if we've already looked up the record
        if (!forceRefresh) {
            const cached = this.resourceCache.get(extResourceId);
            if (cached) {
                return cached
                    .pipe(map(message => {
                        return this.replaceVariables(message, variables);
                    }));
            }
        }

        // Lookup the value
        const freshLookup = this.b2bApiClient
            .b2bGet<ResourceLookupResponseDto>(`resource/${extResourceId}`)
            .pipe(map(result => {
                if (!result) {
                    return '';
                }
                return result.resourceText;
            }), shareReplay(1));

        // Add to the map and return
        this.resourceCache.set(extResourceId, freshLookup);
        return freshLookup
            .pipe(map(message => {
                return this.replaceVariables(message, variables);
            }));
    }

    replaceVariables(message: string, variables?: any): string {

        if (!variables) {
            return message;
        }

        Object.keys(variables).forEach(prop => {
            message = message.replace('{{' + prop + '}}', variables[prop]);
        });

        return message;
    }

    clearCache(): void {
        this.resourceCache = new Map();
    }

}
