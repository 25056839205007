import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {AppConfigService} from '../../app-config.service';
import {ClientSummaryDto} from '../../select-client/select-client.model';
import {SelectClientService} from '../../select-client/select-client.service';
import {ShoppingCartService} from '../../shopping-cart/shopping-cart.service';
import {MiniCartService} from '../mini-cart/mini-cart.service';
import {SessionService} from '../session/session.service';
import {AnalyticsService} from '../analytics/analytics.service';
import { SpinnerService } from '../spinner/spinner.service';

@Component({
    selector: 'hfe-page-header',
    templateUrl: './page-header.component.html',
    styleUrls: ['./page-header.component.less']
})
export class PageHeaderComponent implements OnInit {

    @Output() cartChange = new EventEmitter<any>();
    @Input() title: string;
    @Input() hasCart = true;
    displayTitle: boolean;
    selectedClient: string;
    clientId: number;
    activeClient: number;
    canSellProducts: boolean;
    clients: ClientSummaryDto[];
    clearCartError: string;
    cartItems = 0;
    b2bPortalHelpUrl = '';

    constructor(
        private appConfigService: AppConfigService,
        public sessionService: SessionService,
        private selectClientService: SelectClientService,
        private miniCartService: MiniCartService,
        private shoppingCartService: ShoppingCartService,
        private modalService: NgbModal,
        private router: Router,
        private analyticsService: AnalyticsService,
        private spinnerService: SpinnerService
    ) {
    }

    ngOnInit(): void {
        this.b2bPortalHelpUrl = this.appConfigService.getConfig().b2bPortalHelpUrl;
        this.displayTitle = (this.title !== 'Dashboard');
        this.load();
    }

    load(): void {
        this.sessionService
            .getCurrentSessionData()
            .subscribe(result => {
                this.clientId = result.clientId;
                this.activeClient = result.clientId;
                this.selectedClient = `${result.tenantDisplayName} (${result.clientName})`;
                this.canSellProducts = result.canSellProducts;
            });

        this.selectClientService.getClientList()
            .subscribe(clients => {
                this.clients = clients;
            });
    }



    logout(): void {
        window.location.href = this.appConfigService
            .getConfig()
            .serverAppRoot + 'logout';
    }

    clientSelectionChange(cartCleared: boolean): void {
        this.sessionService
            .setClient(this.clientId)
            .subscribe(result => {
                if (result.success) {
                    // Cart is already clear
                    this.navigateUser(cartCleared);
                    this.cartChange.emit({});
                } else {
                    console.error(result.message);
                }
            }, error => {
                console.error(error);
            });
    }

    navigateUser(cartCleared: boolean): void {
        this.sessionService
            .getCurrentSessionData()
            .subscribe(sessionData => {
                // Send the user back to the product selection if they were selling before or if
                // that's all they can do
                if ((sessionData.canSellProducts && cartCleared)
                    || (sessionData.canSellProducts && !sessionData.canGenerateReports)) {
                    this.router.navigate(['/product-selection']);
                } else {
                    this.router.navigate(['/dashboard']);
                }
            });
    }

    checkCartItems(): boolean {
        if(!this.canSellProducts) {
            return false;          
        }

        if (!this.miniCartService.loaded) {
            this.miniCartService.refreshCartDetails();
        }

        return this.miniCartService.itemCount > 0;
    }

    openModal(content): void {
        this.clearCartError = '';
        this.modalService.open(content, {
            centered: true
        }).result.then(
            (result) => {
            },
            (reason) => {
            }
        );
    }

    closeModal(): void {
        // Revert the selection back
        this.clientId = this.activeClient;
        this.modalService.dismissAll();
    }

    clearCart(): void {
        this.modalService.dismissAll();
        this.spinnerService.pushShow("Clearing cart");

        this.analyticsService.logPageEventWithPrefix(
            'click',
            'engagement',
            `b2b-clear-yes`);

        this.shoppingCartService.clearCart()
            .subscribe(result => {
                this.spinnerService.popShow();

                if (result.success) {
                    this.clientSelectionChange(true);
                } else {
                    this.clearCartError = result.message;
                }
            }, error => {
                this.spinnerService.popShow();
                console.error(error);
                this.clearCartError = error.message;
            });
    }

    onMiniCartClear(): void {
        this.cartChange.emit({});
    }

    helpClick(): void {

        this.analyticsService.logPageEventWithPrefix(
            'click',
            'engagement',
            `b2b-help`);

    }
}
