<hfe-page-header (cartChange)="headerCartChange()">
    <a routerLink="/dashboard">Dashboard</a>
    <span class="px-2">/</span>
    Products
</hfe-page-header>
<hfe-page-section sectionClass="sales">
    <div class="row">
        <div class="col-lg-9 product-column">
            <div *ngFor="let category of productList?.productCategories" class="productCategory">
                <div class="alert alert-danger my-2" *ngIf="errorMessage">{{errorMessage}}</div>
                <h1>{{category.name}}</h1>
                <div *ngFor="let subCategory of category.subCategories">
                    <h2>{{subCategory.name}}</h2>
                    <div *ngFor="let product of subCategory.products" class="product">
                        <div class="row line-product">

                            <div class="col col-sm-6 product-name">
                                {{product.name}}
                                <div class="alert alert-danger" *ngIf="productErrors[product.id]">
                                    {{productErrors[product.id]}}
                                </div>
                            </div>
                            <div class="col col-sm-6 product-options">
                                <ng-container *ngIf="product.hasShows">
                                    <div class="form-group">
                                        <div class="input-group">
                                            <label class="form-label" for="dp{{product.id}}">Select Date</label>
                                            <input class="form-control" placeholder="mm/dd/yyyy" id="dp{{product.id}}"
                                                   name="dp"
                                                   ngbDatepicker [firstDayOfWeek]="7"
                                                   #dp="ngbDatepicker" [ngModel]="product.selectedDate"
                                                   (ngModelChange)="dateChanged($event, product)"
                                                   [markDisabled]="unavailable(product.id)"
                                                   [minDate]="minDate"/>
                                                <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()"
                                                        type="button">
                                                    <fa-icon icon="calendar-alt"></fa-icon>
                                                </button>
                                        </div>
                                    </div>
                                    <div class="form-group"
                                         *ngIf="product.promptShows">
                                        <div class="input-group">
                                            <label class="form-label" for="selectShow{{product.id}}">Select Show</label>
                                            <select class="form-select" [(ngModel)]="product.selectedShow"
                                                    (change)="selectShow(product)" id="selectShow{{product.id}}">
                                                <option *ngFor="let show of product.shows"
                                                        value="{{show.key}}">{{show.value}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group"
                                         *ngIf="product.promptPerformances">
                                        <div class="input-group">
                                            <label class="form-label" for="selectPerformance{{product.id}}">Select Time</label>
                                            <select class="form-select" [(ngModel)]="product.selectedPerformance"
                                                    (change)="selectPerformance(product)"
                                                    id="selectPerformance{{product.id}}">
                                                <option *ngFor="let performance of product.performances"
                                                        value="{{performance.performanceId}}"
                                                        class="perf-capacity-{{performance.capacityLevelId}}">
                                                    {{ getOptionLabel(performance.performanceTime, performance.capacityLevelId, performance.capacityDescription) }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="form-group"
                                         *ngIf="product.selectedPerformance && product.performanceCategories && product.performanceCategories.length >= 2">
                                        <div class="input-group">
                                            <label class="form-label" for="selectShowCategory{{product.id}}">Select Section</label>
                                            <select class="form-select"
                                                    [(ngModel)]="product.selectedPerformanceCategory"
                                                    (change)="selectPerformanceCategory(product)"
                                                    id="selectShowCategory{{product.id}}">
                                                <option *ngFor="let category of product.performanceCategories"
                                                        value="{{category.showCategoryId}}"
                                                        class="perf-capacity-{{category.capacityLevelId}}">
                                                    {{ getOptionLabel(category.name, category.capacityLevelId, category.capacityDescription) }}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                        </div>
                        <div class="container-fluid">
                            <div class="row line-title">
                                <div class="col col-sm-6">Tickets</div>
                                <div class="col col-sm-3">Price</div>
                                <div class="col col-sm-3">Quantity</div>
                            </div>
                            <div class="alert alert-info" *ngIf="product.empty">
                                <hfe-resource extResourceId="B2BProductSelectionEmptyMessage">
                                    No products available for this selection
                                </hfe-resource>
                            </div>
                            <div class="alert alert-info" *ngIf="product.productMessage">
                                {{product.productMessage}}
                            </div>
                            <div *ngFor="let variant of product.staticVariants" class="row variant pt-2">
                                <div class="col col-sm-6">
                                    {{variant.displayName}}
                                    <div *ngIf="variantErrors[variant.id]">
                                        <div class="alert alert-danger mx-3">
                                            {{variantErrors[variant.id]}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-sm-3">
                                    {{variant.price | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="col col-sm-3 justify-content-center">
                                    <hfe-quantity-selector [quantity]="variant.quantity"
                                                           (quantityChanged)="updateVariantQuantity(product, variant.id, $event)"
                                                           [min]="0">
                                    </hfe-quantity-selector>
                                </div>
                            </div>
                            <div *ngFor="let variant of product.performanceVariants" class="row variant pt-2">
                                <div class="col col-sm-6">
                                    {{variant.displayName}}
                                    <div *ngIf="variantErrors[variant.productVariantId]">
                                        <div class="alert alert-danger mx-3">
                                            {{variantErrors[variant.productVariantId]}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-sm-3">
                                    {{variant.price | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="col col-sm-3">
                                    <hfe-quantity-selector [quantity]="variant.quantity"
                                                           (quantityChanged)="updatePerformanceVariantQuantity(product, variant.productVariantId, $event)"
                                                           [min]="0">
                                    </hfe-quantity-selector>
                                </div>
                            </div>
                            <div *ngFor="let variant of product.categoryVariants"
                                 class="row variant pt-2">
                                <div class="col col-sm-6">
                                    {{variant.displayName}}
                                    <div *ngIf="variantErrors[variant.productVariantId]">
                                        <div class="alert alert-danger mx-3">
                                            {{variantErrors[variant.productVariantId]}}
                                        </div>
                                    </div>
                                </div>
                                <div class="col col-sm-3">
                                    {{variant.price | currency:'USD':'symbol':'1.2-2'}}
                                </div>
                                <div class="col col-sm-3">
                                    <hfe-quantity-selector [quantity]="variant.quantity"
                                                           (quantityChanged)="updateCategoryVariantQuantity(product, variant.productVariantId, $event)"
                                                           [min]="0">
                                    </hfe-quantity-selector>
                                </div>
                            </div>
                        </div>

                        <div class="row d-flex justify-content-end " style="text-align: right; padding: 30px 60px;">
                            <button class="w-auto btn btn-secondary m-2" *ngIf="showAddToCart(product)"
                                    [disabled]="!canAddToCart(product)" (click)="addToCart(product)"> Add To
                                Cart
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-lg-3 summary-column">
            <div class="cart-summary-container">
                <hfe-cart-summary #ipcs>
                </hfe-cart-summary>
                <button #btnCheckout id="checkout-btn-sticky" class="btn checkout-btn"
                        [class.bottom-btn-notsticky]="!checkoutButtonSticky" (click)="checkOut()"
                        [disabled]="!canCheckOut()" [hidden]="!productList">
                    Proceed to Checkout
                </button>
            </div>
        </div>
    </div>

</hfe-page-section>
<div #footerMarker></div>
<hfe-page-footer></hfe-page-footer>
