import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {OrderChallengePopupComponent} from './order-challenge-popup.component';
import {OrderViewService} from './order-view.service';
import {ActivatedRoute} from '@angular/router';
import {OrderDetailsDto, OrderDetailsPaymentDto} from 'lib-commerce-shared';
import {SessionService} from '../common/session/session.service';

@Component({
    selector: 'hfe-order-view',
    templateUrl: './order-view.component.html',
    styleUrls: ['./order-view.component.less']
})
export class OrderViewComponent implements OnInit {

    constructor(
        private modalService: NgbModal,
        private orderViewService: OrderViewService,
        private route: ActivatedRoute,
        private sessionService: SessionService
    ) {
    }

    extOrderTokenId: string;
    token: string;
    order: OrderDetailsDto;
    payment: OrderDetailsPaymentDto;
    ticketContent: string;

    ngOnInit(): void {
        this.route.params.subscribe(params => {
            this.extOrderTokenId = params.extOrderTokenId;
            this.token = params.token;
            this.load();
        });
    }

    load(): void {

        // Try to lookup the order without the phone number in case the previous lookup
        // in the session used the same number
        this.orderViewService.lookupOrder(
            this.extOrderTokenId,
            this.token,
            '')
            .subscribe(result => {
                if (result.success) {
                    this.setOrder(result.value);
                } else {
                    const modalRef = this.modalService.open(OrderChallengePopupComponent,
                        {
                            centered: true,
                            backdrop: 'static',
                            keyboard: false,
                        });
                    modalRef.componentInstance.extOrderTokenId = this.extOrderTokenId;
                    modalRef.componentInstance.token = this.token;
                    modalRef.result.then(
                        () => {
                        },
                        (order) => {
                            this.setOrder(order);
                        });
                }
            });
    }

    setOrder(order: OrderDetailsDto): void {
        this.order = order;
        if (order.payments.length > 0) {
            this.payment = order.payments[0];
        } else {
            this.payment = null;
        }

        this.sessionService.clearCachedData();
        this.sessionService.getCurrentSessionData()
            .subscribe(session => {

            });

        if (this.order.hasTickets) {
            this.orderViewService.getTicketContent()
                .subscribe(result => {
                    this.ticketContent = result;
                });
        } else {
            this.ticketContent = null;
        }
    }

    print(): void {
        window.print();
    }

    getTransactionString(): string {
        let returnString = '';
        this.order.trans.forEach(o => {
            if (o.transactionString) {
                returnString = returnString.concat(o.transactionString + ' | ');
            }
        });
        return returnString.slice(0, returnString.lastIndexOf('|'));
    }

    getReservationCode(): string {
        if (!this.order
            || this.order.trans.length === 0) {
            return "";
        }
        return this.order.trans[0].platformReservationCode;
    }
}
