import {Component, OnInit} from '@angular/core';
import {NgbDate, NgbDateStruct, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CartDetailItemDto, CartDetailItemGroupDto} from './shopping-cart.model';
import {ShoppingCartService} from './shopping-cart.service';
import {DatePipe, KeyValue} from '@angular/common';
import {PerformanceDto} from '../product-selection/product-selection.model';
import {StandardDateParserFormatterService} from 'lib-commerce-shared';
import {ProductSelectionService} from '../product-selection/product-selection.service';
import {CartItemRemoveConfirmationComponent} from './cart-item-remove-confirmation.component';
import {AnalyticsService} from '../common/analytics/analytics.service';

interface ItemChangeSelection {
    id: string;
    displayName: string;
    performanceId: string;
    showCategoryId: string;
    quantity: number;
    unitPrice: number;
}

@Component({
    selector: 'hfe-cart-item-change',
    templateUrl: './cart-item-change.component.html',
    styleUrls: ['./cart-item-change.component.less']
})
export class CartItemChangeComponent implements OnInit {

    constructor(
        private modalService: NgbModal,
        private shoppingCartService: ShoppingCartService,
        private datePipe: DatePipe,
        private dateFormatter: StandardDateParserFormatterService,
        private productSelectionService: ProductSelectionService,
        private analyticsService: AnalyticsService
    ) {
    }

    productGroup: CartDetailItemGroupDto;
    updateItemError: string;
    selections: ItemChangeSelection[] = [];
    minDate: NgbDateStruct;
    performances: PerformanceDto[];
    perfDates: string[];
    showPerformance: boolean;

    ngOnInit(): void {

        if (this.productGroup.allowPerformanceChange) {
            this.productSelectionService.getProductShowDates(
                [this.productGroup.productId],
                this.productGroup.showId,
                this.productGroup.items.map(o => o.productVariantId)
            ).subscribe(dates => {
                this.perfDates = dates.productShowDates
                    .map(o => o.date);
            });
            this.loadPerformances();
        }


        const today = new Date();
        this.minDate = {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()};
        this.productGroup.items.forEach(o => {
            this.selections.push({
                id: o.id,
                performanceId: o.performanceId,
                showCategoryId: o.showCategoryId,
                quantity: o.quantity,
                displayName: o.displayName,
                unitPrice: o.unitPrice
            });
        });
    }

    unavailable(): (date: NgbDate) => boolean {
        if (this.perfDates) {
            return (date) => {
                const found = this.perfDates.filter(showDate => {
                    const perfDateAsTime = new Date(showDate).getTime();
                    const ngbDateAsTime = new Date(date.year, date.month - 1, date.day).getTime();
                    return perfDateAsTime === ngbDateAsTime;
                }).length > 0;
                return !found;
            };
        }
    }

    closeModal(): void {
        this.modalService.dismissAll(false);
    }

    updateItem(): void {
        const removeItems = this.selections
            .filter(f => {
                return f.quantity === 0;
            })
            .map(o => {
                return {
                    id: o.id,
                    displayName: o.displayName
                };
            });

        if (removeItems.length > 0) {
            const modalRef = this.modalService.open(CartItemRemoveConfirmationComponent, {centered: true});

            modalRef.componentInstance.items = removeItems;

            modalRef.result.then(result => {
                if (result) {
                    this.modifyCartItems(true);
                }
            }, reason => {
                if (reason) {
                    this.modifyCartItems(true);
                }
            });
        } else {
            this.modifyCartItems(false);
        }
    }

    removeItem(): void {
        const modalRef = this.modalService.open(CartItemRemoveConfirmationComponent, {centered: true});

        modalRef.componentInstance.items = this.productGroup.items.map(i => {
            return {
                id: i.id,
                displayName: i.displayName
            };
        });

        modalRef.result.then(result => {
            if (result) {
                this.selections.forEach(o => o.quantity = 0);
                this.modifyCartItems(true);
            }
        }, reason => {
            if (reason) {
                this.selections.forEach(o => o.quantity = 0);
                this.modifyCartItems(true);
            }
        });
    }

    modifyCartItems(removeAll: boolean): void {

        this.shoppingCartService.modifyCartItem(
            this.selections
                .map(o => {
                    return {
                        id: o.id,
                        quantity: o.quantity,
                        performanceId: this.productGroup.performanceId, // Send the updated show
                        showCategoryId: o.showCategoryId
                    };
                }))
            .subscribe(result => {
                if (result.success) {

                    if (removeAll) {
                        this.analyticsService.logPageEventWithPrefix(
                            'click',
                            'engagement',
                            `b2c-remove-removed`);
                    } else {
                        this.analyticsService.logPageEventWithPrefix(
                            'click',
                            'engagement',
                            `b2c-remove-updated`);
                    }

                    this.modalService.dismissAll(true);
                } else {
                    this.updateItemError = result.message;
                }
            }, error => {
                console.log(error);
                this.updateItemError = error.message;
            });
    }

    dateChanged(date: any): void {
        if (!this.dateFormatter.isValid(date)) {
            return;
        }
        this.productGroup.actualPerformanceDate = date;
        this.productGroup.performanceId = null;
        this.loadPerformances();
    }

    loadPerformances(): void {

        this.productSelectionService.getProductDatePerformances(
            this.productGroup.productId,
            this.productGroup.actualPerformanceDate
        ).subscribe(performances => {
            this.performances = performances.performances;
            if (this.performances.length === 1) {
                this.productGroup.performanceId = this.performances[0].performanceId;
            }
            const alwaysPromptPerf = performances.performances.length === 1
                && performances.performances[0].alwaysPromptPerformance;
            this.showPerformance = alwaysPromptPerf || this.performances.length > 1;
        });
    }

    performanceChange($event: any): void {

    }
}
