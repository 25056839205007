import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MiniCartService } from '../common/mini-cart/mini-cart.service';
import { ShoppingCartService } from '../shopping-cart/shopping-cart.service';
import { ConfirmationService } from './confirmation.service';
import { OrderDetailsDto, OrderDetailsPaymentDto, OrderDetailsTransDto } from 'lib-commerce-shared';
import { SpinnerService } from '../common/spinner/spinner.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResendDialogComponent } from '../common/resend-dialog/resend-dialog.component';
import { AnalyticsService } from '../common/analytics/analytics.service';

@Component({
    selector: 'hfe-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.less']
})
export class ConfirmationComponent implements OnInit {

    constructor(
        private shoppingCartService: ShoppingCartService,
        private miniCartService: MiniCartService,
        private route: ActivatedRoute,
        private confirmService: ConfirmationService,
        private spinner: SpinnerService,
        private modalService: NgbModal,
        private analyticsService: AnalyticsService,
        private router: Router
    ) {
    }

    order: OrderDetailsDto;
    payment: OrderDetailsPaymentDto;
    errorMessage = '';
    successMessage = '';
    ticketContent = '';

    ngOnInit(): void {
        this.analyticsService.logPageView(this.router.url);
        this.load();
    }

    load(): void {
        this.spinner.pushShow('Loading Confirmation');
        this.miniCartService.refreshCartDetails();
        this.confirmService.getOrderDetails()
            .subscribe(order => {
                this.order = order;
                if (order.payments.length > 0) {
                    this.payment = order.payments[0];
                }
                this.successMessage = 'Checkout Successful!';
                if (order.hasTickets) {
                    this.confirmService.getTicketContent()
                        .subscribe(content => {
                            this.spinner.popShow();
                            this.ticketContent = content;
                        }, error => {
                            console.log(error);
                            this.errorMessage = error.message;
                            this.spinner.popShow();
                        });
                } else {
                    this.spinner.popShow();
                }
            }, error => {
                console.log(error);
                this.errorMessage = error.message;
                this.spinner.popShow();
            });
    }

    print(): void {
        window.print();
    }

    resend() {
        const modalRef = this.modalService.open(ResendDialogComponent, {centered: true});
        modalRef.componentInstance.email = this.order.emailAddress;
    }

    getTransactionString(): string {
        let returnString = '';
        this.order.trans.forEach(o => {
            if (o.transactionString) {
                returnString = returnString.concat(o.transactionString + ' | ');
            }
        });
        return returnString.slice(0, returnString.lastIndexOf('|'));
    }

    getReservationCode(): string {
        if (!this.order
            || this.order.trans.length === 0) {
            return "";
        }
        return this.order.trans[0].platformReservationCode;
    }

}
