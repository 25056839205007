import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ReportingService } from './reports.service';
import { ReportParametersComponent, Report } from 'lib-commerce-shared';
import { SpinnerService } from '../common/spinner/spinner.service';

@Component({
  selector: 'hfe-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.less']
})
export class ReportComponent implements OnInit {
  report: Report;
  @ViewChild(ReportParametersComponent) parameters: ReportParametersComponent;
  pdfSource: any;
  error: string;
  @ViewChild('reportContainer', {static: false}) reportContainer: ElementRef;

  constructor(private route: ActivatedRoute, 
    private reportingService: ReportingService,
    private domSanitizer: DomSanitizer,
    private spinner: SpinnerService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if (params.id) {
        this.reportingService.getReport(params.id).subscribe(report => {
          this.report = report
          this.report.parameters.filter(param => param.dataSource).forEach(param => {
            param.dataSourceValues = this.reportingService.getParameterValues(param.dataSource);
          });
        });
      }
    });
  }

  generate(format: string) {
    if (!this.parameters.isValid()) return;
    this.error = '';
    this.spinner.pushShow('Generating...');
    this.reportingService.generate(this.report, format).subscribe((result: any) => {
      
      //did an error occur
      if (result.type === 'application/json') {
        result.text().then(json => {
          this.error = JSON.parse(json).error;
        });
      }
      
      //deliver report
      else {
        const binaryData = [];
        binaryData.push(result);
        const blob = new Blob(binaryData, { type: result.type });

        //excel
        switch (result.type) {
          case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            this.handleDownload(blob, '.xlsx');
            break;
          case 'application/pdf':
            this.handlePdf(blob);
            break;
          default:
            this.error = 'An unexpected error has occurred';
        }
      }
      
      this.spinner.popShow();    

    }, error => {
      this.error = 'An unexpected error has occurred';
      console.log(error);
      this.spinner.popShow();
    });
  }

  handlePdf(blob: Blob) {
    this.pdfSource = this.domSanitizer.bypassSecurityTrustResourceUrl(window.URL.createObjectURL(blob));
  }

  handleDownload(blob: Blob, extension: string) {
    const objectUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = objectUrl;
    link.setAttribute('download', this.report.title);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }

  iframeLoaded($event) {
    if (this.reportContainer) {
      this.reportContainer.nativeElement.height = window.innerHeight - (this.reportContainer.nativeElement.offsetTop + 10);
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    if (this.reportContainer) {
      this.reportContainer.nativeElement.height = window.innerHeight - (this.reportContainer.nativeElement.offsetTop + 10);
    }
  }

  reset() {
    this.parameters.clear();
  }
}
