import {Component, OnInit} from '@angular/core';
import {AppConfigService} from '../app-config.service';

@Component({
    selector: 'hfe-timeout-popup',
    templateUrl: './timeout-popup.component.html',
    styleUrls: ['./timeout-popup.component.less']
})
export class TimeoutPopupComponent implements OnInit {

    constructor(
        private appConfig: AppConfigService
    ) {
    }

    message: string;

    ngOnInit(): void {
        this.message = this.appConfig.getConfig().sessionTimeoutMessage;
    }

    logOut(): void {
        window.location.href = this.appConfig.getConfig().serverAppRoot + 'logout';
    }


}
