<hfe-page-header (cartChange)="headerCartChange()">
    <a routerLink="/dashboard">Dashboard</a>
    <span class="px-2">/</span>
    <a routerLink="/product-selection">Products</a>
    <span class="px-2">/</span>
    Check Out
</hfe-page-header>
<hfe-page-section>
    <div class="row">
        <div class="col-lg-9">
            <div class="alert alert-success w-100 m-2" *ngIf="successMessage">
                {{successMessage}}
            </div>
            <div class="alert alert-danger w-100 m-2 errors" *ngIf="errorMessages && errorMessages.length > 0">
                <ul>
                    <li *ngFor="let errorMessage of errorMessages">
                        {{errorMessage}}
                    </li>
                </ul>
            </div>
            <div class="container-fluid" *ngIf="loaded">
                <div class="row">
                    <div class="form-field mb-3 col-12">
                        <label class="form-label" for="deliveryMethodId"> Delivery Method </label>
                        <select ngbAutoFocus name="deliveryMethodId" id="deliveryMethodId" class="form-select"
                                [(ngModel)]="deliveryMethodId" [ngClass]="{'is-invalid': invalidDeliveryMethod}">
                            <option *ngFor="let deliveryMethod of deliveryMethods" [ngValue]="deliveryMethod.id">
                                {{deliveryMethod.name}}
                            </option>
                        </select>
                        <div class="invalid-feedback">
                            <hfe-resource extResourceId="b2bClientInvalidDelivery" >Delivery method is required
                            </hfe-resource>
                        </div>
                    </div>
                    <div class="form-field mb-3 col-12">
                        <label class="form-label" for="paymentMethodId"> Payment Method </label>
                        <select name="paymentMethodId" id="paymentMethodId" class="form-select"
                                [(ngModel)]="paymentMethodId" [ngClass]="{'is-invalid': invalidPaymentMethod}">
                            <option *ngFor="let paymentMethod of paymentMethods" [ngValue]="paymentMethod.id">
                                {{paymentMethod.name}}
                            </option>
                        </select>
                        <div class="invalid-feedback">
                            <hfe-resource extResourceId="b2bClientInvalidPayment">Payment method is required
                            </hfe-resource>
                        </div>
                    </div>
                    <div class="form-field mb-3 col col-12">
                        <label class="form-label" for="referenceNumber">Reference Number</label>
                        <input class="form-control" type="text" name="referenceNumber" id="referenceNumber" aria-label="Reference Number"
                               [(ngModel)]="referenceNumber">
                    </div>
                    <div class="form-field mb-3 col col-12">
                        <label class="form-label" for="firstName">Guest First Name</label>
                        <input class="form-control" type="text" name="firstName" id="firstName" aria-label="First Name"
                               [(ngModel)]="firstName" [ngClass]="{'is-invalid': invalidFirstName}">
                        <div class="invalid-feedback">
                            <hfe-resource extResourceId="b2bClientInvalidPhone">First name must be between 1 and 35 characters in length
                            </hfe-resource>
                        </div>
                    </div>
                    <div class="form-field mb-3 col col-12">
                        <label class="form-label" for="lastName">Guest Last Name</label>
                        <input class="form-control" type="text" name="lastName" id="lastName" aria-label="Last Name"
                               [(ngModel)]="lastName" [ngClass]="{'is-invalid': invalidLastName}">
                        <div class="invalid-feedback">
                            <hfe-resource extResourceId="b2bClientInvalidPhone">Last name must be between 1 and 35 characters in length
                            </hfe-resource>
                        </div>
                    </div>
                    <div class="form-field mb-3 col col-12">
                        <label class="form-label" for="lastName">Guest Phone Number</label>
                        <input class="form-control" type="text" name="phoneNumber" id="phoneNumber"
                               aria-label="Phone Number" [(ngModel)]="phoneNumber" [ngClass]="{'is-invalid': invalidPhone}">
                        <div class="invalid-feedback">
                            <hfe-resource extResourceId="b2bClientInvalidPhone">Phone number must be a 10 digit numeric value
                            </hfe-resource>
                        </div>
                    </div>
                    <div class="form-field mb-3 col col-12">
                        <label class="form-label" for="lastName">Guest Email Address</label>
                        <input class="form-control" type="text" name="emailAddress" id="emailAddress"
                               aria-label="Email Address" [(ngModel)]="emailAddress" [ngClass]="{'is-invalid': invalidEmailRequired || invalidEmail}">
                        <div class="invalid-feedback" *ngIf="invalidEmailRequired">
                            <hfe-resource extResourceId="b2bClientInvalidEmailRequired">Email address is required
                            </hfe-resource>
                        </div>
                        <div class="invalid-feedback" *ngIf="invalidEmail">
                            <hfe-resource extResourceId="b2bClientInvalidEmail">Please provide a valid email address
                            </hfe-resource>
                        </div>
                    </div>
                    <div class="form-field mb-3 col col-12">
                        <label class="form-label" for="zipCode">Guest Zip Code</label>
                        <input class="form-control" type="text" name="zipCode" id="zipCode" aria-label="Zip Code"
                               [(ngModel)]="zipCode" [ngClass]="{'is-invalid': invalidZip}">
                        <div class="invalid-feedback">
                            <hfe-resource extResourceId="b2bClientInvalidEmail">A 5 digit US or 6 character Canadian postal code is required
                            </hfe-resource>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col col-12 form-check ms-3 my-3">
                        <input id="chkTerms" type="checkbox" class="form-check-input"
                               [(ngModel)]="acceptTerms"/>
                        <label for="chkTerms" class="form-check-label">
                            I agree to the
                            <a href="/terms/{{termsOfSaleVersionId}}" target="_blank" (click)="showTerms(termsOfSaleVersionId, $event)">
                                Terms of Sale
                            </a>
                            and
                            <a href="/terms/{{termsOfUseVersionId}}" target="_blank" (click)="showTerms(termsOfUseVersionId, $event)">
                                Terms of Use
                            </a>.
                        </label>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger w-100 m-2 errors" *ngIf="errorMessages && errorMessages.length > 0">
                <ul>
                    <li *ngFor="let errorMessage of errorMessages">
                        {{errorMessage}}
                    </li>
                </ul>
            </div>
            <div class="d-flex justify-content-end">
                <button class="btn btn-secondary" (click)="saveNewCheckout()">Checkout</button>
            </div>
        </div>
        <div class="col-lg-3 summary-column">
            <div class="cart-summary-container">
                <hfe-cart-summary #ipcs>
                </hfe-cart-summary>
            </div>
        </div>
    </div>
</hfe-page-section>
<hfe-page-footer></hfe-page-footer>
