<hfe-page-header [hasCart]="false">
    <a routerLink="/dashboard">Dashboard</a>
    <span class="px-2">/</span>
    <a routerLink="/order-lookup">Order History</a>
    <span class="px-2">/</span>
    Order Confirmation
</hfe-page-header>

<div class="checkout m-3">

    <div class="alert alert-danger w-100 m-2" *ngIf="errorMessage">
        {{errorMessage}}
    </div>

    <div class="order-details-section">
        <h1>Order Details</h1>

        <div class="alert alert-warning mt-3 mb-3 p-3" *ngIf="order.confirmationGenerationMessage">
            {{order.confirmationGenerationMessage}}
        </div>

        <div class="checkout-info row" *ngIf="order">
            <div class="col-sm-3 order-tools">
                <div class="cursor-pointer">
                    <a [routerLink]="[]" (click)="print()">
                        <fa-icon icon="print"></fa-icon>
                        Print Page
                    </a>
                </div>
                <div class="cursor-pointer" *ngIf="order.hasTickets">
                    <a routerLink="./" fragment="viewTickets">
                        <fa-icon icon="ticket-alt"></fa-icon>
                        View Tickets
                    </a>
                </div>
                <div class="cursor-pointer"  *ngIf="order.allowConfirmationResend">
                    <a [routerLink]="[]" (click)="resend()">
                        <fa-icon icon="envelope-open-text"></fa-icon>
                        Resend Email
                    </a>
                </div>
            </div>
            <div class="col-sm-9 order-contact">
                <div>
                    <label> Order Date:</label>
                    {{order.orderDate}}
                </div>
                <div>
                    <label> Confirmation Number:</label>
                    {{getReservationCode()}}
                </div>
                <div>
                    <label> Performed By:</label>
                    {{order.performedBy}}
                </div>
                <br>
                <div>
                    <label> Name:</label>
                    {{order.firstName}} {{order.lastName}}
                </div>
                <div *ngIf="order.phoneNumber">
                    <label>Phone Number:</label>
                    {{order.phoneNumber}}
                </div>
                <div *ngIf="order.emailAddress">
                    <label>Email Address:</label>
                    {{order.emailAddress}}
                </div>
                <div *ngIf="order.referenceNumber">
                    <label>Reference Number:</label>
                    {{order.referenceNumber}}
                </div>
                <br>
                <div>
                    <label> Delivery Method:</label>
                    {{order.deliveryMethodName}}
                </div>
                <div *ngIf="payment">
                    <label> Payment Method:</label>
                    {{payment.paymentMethodName}}
                </div>
                <div *ngIf="payment && payment.maskedCardNumber" class="creditCard">
                    <label> Credit Card Number:</label>
                    {{payment.maskedCardNumber}}
                </div>
                <br>
                <div>
                    <div *ngIf="getTransactionString()">
                        <label>Ticket Order:</label>
                        {{getTransactionString()}}
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="order" class="shoppingCartDetails m-3">
            <table class="table table-striped table-hover" *ngIf="order.items.length > 0">
                <thead>
                <tr class="line-title">
                    <th class="w-10">Quantity</th>
                    <th class="w-75">Description</th>
                    <th class="w-15">Price</th>
                    <th class="w-10">Total</th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let item of order.items">
                    <td>{{item.quantity}}</td>
                    <td>
                        <div>{{item.productVariantDisplayName}}</div>
                        <!-- Performances -->
                        <div *ngFor="let perf of item.performances; let i = index;">
                            <div *ngIf="perf.showName">{{perf.showName}}</div>
                            <div *ngIf="perf.performanceDate && i == 0">
                                {{perf.performanceDate}}
                            </div>
                            <div *ngIf="perf.performanceTime">
                                {{item.performances.length > 1 && perf.showUIPrompt ? perf.showUIPrompt : ''}}
                                {{perf.performanceTime}}
                            </div>
                            <div *ngIf="perf.showCategoryName">
                                {{perf.showCategoryName}}
                            </div>
                            <div *ngIf="perf.seatDescription">
                                {{perf.seatDescription}}
                            </div>
                        </div>
                    </td>
                    <td>{{item.unitPrice | currency:'USD':'symbol':'1.2-2'}}</td>
                    <td>{{item.itemTotal | currency:'USD':'symbol':'1.2-2'}}</td>
                </tr>
                <tr class="taxFeeBreakdown">
                    <td colspan="2"></td>
                    <td>
                        <div class="d-flex flex-column">
                            <span>Sub-Total</span>
                            <span>Taxes</span>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex flex-column">
                            <span>{{order.orderTotalNetTaxesAndFees| currency:'USD':'symbol':'1.2-2'}}</span>
                            <span>{{order.taxTotal| currency:'USD':'symbol':'1.2-2'}}</span>
                        </div>
                    </td>
                </tr>
                </tbody>
                <tfoot>
                <tr>
                    <td colspan="2"></td>
                    <td>
                        <div>
                            <strong>Total Price</strong>
                        </div>
                    </td>
                    <td>
                        <div>
                            <strong>{{order.orderTotal| currency:'USD':'symbol':'1.2-2'}}</strong>
                        </div>
                    </td>
                </tr>
                </tfoot>
            </table>
        </div>
    </div>

    <h1 *ngIf="order && order.ticketingReservationCode && !order.hasTickets">
        Reservation Lookup
    </h1>
    <div *ngIf="order && order.ticketingReservationCode && !order.hasTickets"
         class="reservation-barcode-container d-flex flex-column justify-content-center align-items-center ">
        <img src="/api/ticket/barcode/{{order.ticketingReservationCode}}" alt=""/>
        <span>{{order.ticketingReservationCode}}</span>
    </div>

    <div [innerHTML]="ticketContent" id="viewTickets" class="order-tickets-section page-break"
         *ngIf="order && order.hasTickets">
    </div>
</div>

<hfe-page-footer></hfe-page-footer>
