import {Injectable} from '@angular/core';
import {B2BApiClientService} from '../b2b-api-client/b2b-api-client.service';
import {Observable} from 'rxjs';
import { ApiResultDto } from 'lib-commerce-shared';

@Injectable({
    providedIn: 'root'
})
export class ResendDialogService {

    constructor(
        private apiClient: B2BApiClientService
    ) {
    }

    send(orderId: string, email: string): Observable<ApiResultDto> {
      return this.apiClient.b2bPost<ApiResultDto>(`order/resend-confirm${!orderId ? '-current-order' : ''}`, { extOrderId: orderId, emailAddress: email });
    }
}