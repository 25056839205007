import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'hfe-credit-warning',
  templateUrl: './credit-warning.component.html'
})
export class CreditWarningComponent implements OnInit {
  @Input() message: string;

  constructor(
    private modalService: NgbModal
  ) { }

  ngOnInit(): void {
    
  }

  close(): void {
    this.modalService.dismissAll();
  }
}
