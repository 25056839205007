<div class="d-flex flex-row">
    <div class="alert alert-danger mb-0 me-3 py-0 px-2" *ngIf="clearCartError">
        {{clearCartError}}
    </div>

    <div class="icon-container cursor-pointer"
         (click)="miniCartService.itemCount == 0?goToProducts(): openModal(clearCartWarning)">
        <fa-icon icon="undo-alt"></fa-icon>
    </div>
    <div routerLink="/shopping-cart" class="icon-container cursor-pointer">
        <fa-icon icon="shopping-cart" class="shopping-cart"></fa-icon>
        <span *ngIf="miniCartService.itemCount">
            {{miniCartService.itemCount}}
        </span>
    </div>
</div>

<ng-template #clearCartWarning let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Begin a New Order?</h4>
    </div>
    <div class="modal-body">
        <p>Are you sure that you would like to remove the items currently in your shopping cart and begin a new order?</p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="clearCartAndGoToProducts()">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="closeModal()">No</button>
    </div>
</ng-template>

