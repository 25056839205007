<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Cart Warning</h4>
</div>
<div class="modal-body">
    There are items still in your cart.  Navigating away will clear your cart.  Are you sure you wish to proceed?
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary ghost" (click)="cancel()">No</button>
    <button type="button" class="btn btn-secondary" (click)="clearCart()">Yes</button>
</div>
