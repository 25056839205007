import {Component, OnInit} from '@angular/core';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CartItemRemoveConfirmationDto} from './shopping-cart.model';
import {ResourceService} from '../common/resource/resource.service';

@Component({
    selector: 'hfe-cart-item-remove-confirmation',
    templateUrl: './cart-item-remove-confirmation.component.html',
    styleUrls: ['./cart-item-remove-confirmation.component.less']
})
export class CartItemRemoveConfirmationComponent implements OnInit {

    constructor(
        private activeModal: NgbActiveModal,
        private resourceService: ResourceService
    ) {
    }

    message = '';
    items: CartItemRemoveConfirmationDto[];

    ngOnInit(): void {
        if (this.items.length === 1) {
            this.resourceService.getResourceValue('CartRemoveSingleItemConfirmation',
                {
                    itemDisplayName: this.items[0].displayName
                })
                .subscribe(message => this.message = message);
        } else {
            this.resourceService.getResourceValue('CartRemoveMultiItemsConfirmation')
                .subscribe(message => this.message = message);
        }
    }

    removeItems(): void {
        this.activeModal.dismiss(true);
    }

    closeModal(): void {
        this.activeModal.dismiss(false);
    }
}
