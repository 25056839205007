<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Update shopping cart items</h4>
</div>
<div class="modal-body">
    <div class="group-header">
        <h4>{{productGroup.productName}}</h4>
        <div *ngIf="productGroup.showName">Show: {{productGroup.showName}}</div>
        <div *ngIf="!productGroup.allowPerformanceChange && productGroup.performanceDate">
            Date: {{productGroup.performanceDate}}</div>
        <div *ngIf="!productGroup.allowPerformanceChange && productGroup.performanceTime">
            Time: {{productGroup.performanceTime}}</div>
        <div *ngIf="productGroup.showCategoryName">Section: {{productGroup.showCategoryName}}</div>

        <div class="row mt-3" *ngIf="productGroup.allowPerformanceChange">
            <div class="col-6 d-flex flex-column justify-content-center">
                Change Date:
            </div>
            <div class="col-6 d-flex flex-column justify-content-center">
                <div class="form-field w-100 mb-0">
                    <div class="input-group">
                        <input class="form-control" placeholder="mm/dd/yyyy" id="selectDate"
                               name="dp"
                               ngbDatepicker [firstDayOfWeek]="7"
                               #dp="ngbDatepicker" [ngModel]="productGroup.actualPerformanceDate"
                               (ngModelChange)="dateChanged($event)"
                               [markDisabled]="unavailable()"
                               [minDate]="minDate"/>
                            <button class="btn btn-outline-secondary calendar" (click)="dp.toggle()"
                                    type="button">
                                <fa-icon icon="calendar-alt"></fa-icon>
                            </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="row mt-3" *ngIf="productGroup.allowPerformanceChange && showPerformance">
            <div class="col-6 d-flex flex-column justify-content-center">
                Change Time:
            </div>
            <div class="col-6 d-flex flex-column justify-content-center">
                <div class="form-field w-100 mb-0">
                    <div class="input-group">
                        <select class="form-select" [(ngModel)]="productGroup.performanceId"
                                (change)="performanceChange($event)"
                                id="selectPerformance">
                            <option *ngFor="let performance of performances"
                                    value="{{performance.performanceId}}"
                                    class="perf-capacity-{{performance.capacityLevelId}}">
                                {{performance.performanceTime}} - {{performance.capacityDescription}}</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <div class="item-row" *ngFor="let item of selections">
        <div class="row">
            <div class="col-12">
                <div>
                    {{item.displayName}}
                </div>
            </div>
        </div>
        <div class="row mt-2">
            <div class="col-7">
                <div>
                    Price: {{item.unitPrice | currency}} each
                </div>
            </div>
            <div class="col-5 d-flex flex-row justify-content-end">
                <hfe-quantity-selector class="align-self-center" [quantity]="item.quantity"
                                       (quantityChanged)="item.quantity = $event" [min]="0">
                </hfe-quantity-selector>
            </div>
        </div>
    </div>
</div>

<div class="alert alert-danger" *ngIf="updateItemError">
    {{updateItemError}}
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="removeItem()">Remove All</button>
    <button type="button" class="btn btn-secondary ghost" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-primary" (click)="updateItem()">Update</button>
</div>
