<hfe-page-header *ngIf="!isModal">
</hfe-page-header>

<div class="modal-body">
  <div class="alert alert-danger" *ngIf="errorMessage">
    {{errorMessage}}
  </div>

  <hfe-terms-content
    [extVersionId]="extVersionId"
    (errorMessage)="errorMessage = $event">
  </hfe-terms-content>
</div>

<div class="modal-footer" *ngIf="isModal">
  <button type="button" class="btn btn-primary" (click)="closeModal()">Close</button>
</div>

<hfe-page-footer *ngIf="!isModal">
</hfe-page-footer>
