<div class="navbar header-container-item">
    <div class="col-md-6">
        <div class="navbar-logo">
            <img *ngIf="sessionService?.siteLogUrl"
                 [src]="sessionService.siteLogUrl"
                 alt="Logo">
        </div>
    </div>
</div>

<div class="title-container px-3 py-2">
    <div class="row">
        <div class="col-sm-6 mr-auto">
            <ng-content></ng-content>
            {{title}}
        </div>
        <div class="col-sm-6 ml-auto d-flex flex-row justify-content-end">
        </div>
    </div>
</div>
