import { PerformanceDetailVariantDto, ShowCategoryDetailVariantDto } from "./shared-performance-api.model";

export interface MenuSummaryDto {
    id: string;
    menuName: string;
    menuDescription: string;
    priority: number;
}

export interface MenuDetailDto {
    id: string;
    menuName: string;
    menuDescription: string;
    products: MenuDetailProductDto[];
}

export interface MenuDetailProductDto {
    id: string;
    productName: string;
    productShortDescription: string;
    productLongDescription: string;
    MinTotalVariantQuantity: number;
    MaxTotalVariantQuantity: number;
    BasePrice: number;
    requiresPerformance: boolean;
    variants: MenuDetailProductVariantDto[];
}

export interface MenuDetailProductVariantDto {
    id: string;
    displayName: string;
    variantShortDescription: string;
    sourceSystemName: string;
    platformVariantCode: string;
    platformVariantAk: string;
    minQuantity: number;
    maxQuantity: number;
    ageRestrictionId: number;
    ageRestrictionText: string;
    isPass: boolean;
    passLevelName: string;
    unitPrice: number;
    unitTax: number;
    displayPrice: number;
    installmentBillingPlanDescription: string;
    minBirthDate: string;
    maxBirthDate: string;
}

export interface DynamicPricingResultDto {
    dates: DynamicPricingResultDateDto[];
    product: DynamicPricingResultProductDto;
}

export interface DynamicPricingResultProductDto {
    productDisplayName: string;
    productLongDescription: string;
    prePurchaseDisclaimer: string;
}

export interface DynamicPricingResultDateDto {
    date: string;
    minDefaultPrice: number;
    maxDefaultPrice: number;
    capacityLevelId: number;
    capacityDescription: string;
    isClosed: boolean;
    performances: DynamicPricingResultPerformanceDto[];
}

export interface DynamicPricingResultPerformanceDto {
    id: string;
    productId: string;
    performanceTime: string;
    capacityLevelId: number;
    capacityDescription: string;
    minPrice: number;
    maxPrice: number;
    slotPrices: number[];
    showId: string;
    showName: string;
    selectionIndex: number;
    selectionUIPrompt: string;
    delayTypeId: number;
    delayDateTime: Date;
    performanceDateTime: string;
    alwaysPromptShow: boolean;
    alwaysPromptPerformance: boolean;
    performanceGroupId: string;
    performanceGroupName: string;
    performanceGroupPriority: number;
    sectionId: string;
    sectionName: string;
    sectionPriority: number;
    isAssignedSeating: boolean;
    variants: DynamicPricingResultVariantDto[];
}

export interface DynamicPricingResultVariantDto {
    id: string;
    priority: number;
    shortName: string;
    displayName: string;
    displayPrice: number;
    ageRestrictionText: string;
    minQuantity: number;
    maxQuantity: number;

    // Not applicable for dynamic pricing but needed for selector
    isPass?: boolean;
    passLevelName?: string;
}

export class SelectedPerformanceVariant {

    constructor(dynamicDto: DynamicPricingResultVariantDto, performanceId: string);
    constructor(assignedSeatingDto: PerformanceDetailVariantDto);
    constructor(assignedSeatingDto: ShowCategoryDetailVariantDto);

    constructor(dto: DynamicPricingResultVariantDto | PerformanceDetailVariantDto | ShowCategoryDetailVariantDto, performanceId?: string)
    {
        this.performanceId = (typeof performanceId === "string")
            ? performanceId
            : dto['performanceId'];
        this.shortName = (typeof dto['shortName'] === "string")
            ? dto['shortName']
            : dto['name'];

        // All interface overlap for these properties
        this.variantId = dto.id;
        this.displayName = dto.displayName;
        this.displayPrice = dto.displayPrice;
        this.ageRestrictionText = dto.ageRestrictionText;
        this.minQuantity = dto.minQuantity;
        this.maxQuantity = dto.maxQuantity;
    }

    variantId: string;
    performanceId: string;
    shortName: string;
    displayName: string;
    displayPrice: number;
    ageRestrictionText: string;
    minQuantity: number;
    maxQuantity: number;
}

export interface GenericProduct {
    id: string;
    productName: string;
    productShortDescription: string;
    productLongDescription: string;
    MinTotalVariantQuantity: number;
    MaxTotalVariantQuantity: number;
    BasePrice: number;
    requiresPerformance: boolean;
    variants: GenericProductVariant[];
}

export interface GenericProductVariant {
    id: string;
    displayName: string;
    variantShortDescription: string;
    sourceSystemName: string;
    platformVariantCode: string;
    platformVariantAk: string;
    minQuantity: number;
    maxQuantity: number;
    ageRestrictionId: number;
    ageRestrictionText: string;
    isPass: boolean;
    passLevelName: string;
    unitPrice: number;
    unitTax: number;
    displayPrice: number;
    installmentBillingPlanDescription: string;
    minBirthDate: string;
    maxBirthDate: string;
}

export class DynamicPricingDate {

    constructor(dto: DynamicPricingResultDateDto) {
        this.date = dto.date;
        this.dt = new Date(dto.date);
        this.minDefaultPrice = dto.minDefaultPrice;
        this.maxDefaultPrice = dto.maxDefaultPrice;
        this.capacityLevelId = dto.capacityLevelId;
        this.capacityDescription = dto.capacityDescription;
        this.isClosed = dto.isClosed;
        this.performances = dto.performances
            .map(p => new DynamicPricingPerformance(p));
    }

    date: string;
    dt: Date;
    minDefaultPrice: number;
    maxDefaultPrice: number;
    capacityLevelId: number;
    capacityDescription: string;
    isClosed: boolean;
    performances: DynamicPricingPerformance[];

    getDate(): number {
        return this.dt.getDate();
    }

    getMonth(): string {
        return this.dt.toLocaleString('en-US', { month: 'short' }).toString();
    }

}

export enum CapacityLevel {
    Available = 1,
    Limited = 2,
    AlmostFull = 3,
    Full = 4,
    DataUnavailable = 98,
    Unavailable = 99
}

export class DynamicPricingPerformance {

    constructor(dto: DynamicPricingResultPerformanceDto) {
        Object.assign(this, dto);
    }

    id: string;
    performanceTime: string;
    capacityLevelId: number;
    capacityDescription: string;
    minPrice: number;
    maxPrice: number;
    slotPrices: number[];
    showId: string;
    showName: string;
    selectionIndex: number;
    selectionUIPrompt: string;
    delayTypeId: number;
    delayDateTime: Date;
    performanceDateTime: Date;
    alwaysPromptShow: boolean;
    alwaysPromptPerformance: boolean;
    performanceGroupId: string;
    performanceGroupName: string;
    performanceGroupPriority: number;
    sectionId: string;
    sectionName: string;
    sectionPriority: number;
    isAssignedSeating: boolean;
    variants: DynamicPricingResultVariantDto[];
}

export interface RedeemableEntitlementProductDetailDto {
    productId: string;
    productName: string;
    productVariantId: string;
    productVariantName: string;
    prePurchaseDisclaimer: string;
    productLongDescription: string;
    remainingQuantity: number;
    totalQuantity: number;
    validityStartDate: string;
    validityEndDate: string;
    variantShortDescription: string;
    entitlements: RedeemableEntitlementProductItemDto[]
}

export interface RedeemableEntitlementProductItemDto {
    id: string;
    remainingQuantity: number;
    totalQuantity: number;
    accountFirstName: string;
    accountLastName: string;
}

export enum ProductSaleFlowValues {
    Public = 1,
    TicketingEntitlementRedemption = 2,
    TagSalePromotion = 3,
    AddOnTagSalePromotion = 4,
    PromotionCode = 5,
    CrossSellPromotion = 6,
    VerifiedOfferPromotion = 7,
    Donation = 8,
    BundleBuilder = 9,
    AdvancedSale = 14,
    AccountEntitlement = 15
}

export enum PromotionTypeValues {
    DigonexDynamicPricing = 1,
    CartEntitlement = 2,
    ProductSalePromotion = 3,
    VariantSalePromotion = 4,
    TagSalePromotion = 5,
    PassRenewalPromotion = 6,
    NewPassTagPromotion = 7,
    DynamicPricingChildPromotion = 8,
    AddOnTagSalePromotion = 9,
    VerifiedOfferTagSalePromotion = 10,
    PromotionCodePromotion = 11,
    ReservationTicketEntitlement = 12,
    ReservationTicketCounter = 13,
    TicketingDynamicOffer = 14,
    TicketingPackage = 15,
    TicketRenewalPromotion = 16,
    TicketUpgradePromotion = 17,
    ProductCrossSellPromotion = 18,
    BundleBuilderPromotion = 19
}

export enum PromotionSaleFlowValues {
    SeasonPass = 1,
    NonSeasonPass = 2
}

export enum PromotionCrossSellTypeValues {
    Primary = 1,
    Promotional = 2,
    Secondary = 3
}

export interface SingleProductSelectionPayloadDto {
    extProductId: string;
    extProductVariantId?: string;
    saleFlowId?: ProductSaleFlowValues;
    promotionTypeId?: PromotionTypeValues;
    promotionSaleFlowId?: PromotionSaleFlowValues;
    tagsCsv?: string;
    promotionCode?: string;
    promotionCrossSellTypeId?: PromotionCrossSellTypeValues;
    tokenId?: string;
    tokenValue?: string;
}

export interface MultiProductSelectionPayloadDto {
    extProductIds?: string[];
    specificPromotionTypeId?: PromotionTypeValues;
    promotionSaleFlowId?: PromotionSaleFlowValues;
    saleFlowId?: ProductSaleFlowValues;
    tagsCsv?: string;
    promotionCode?: string;
    promotionCrossSellTypeId?: PromotionCrossSellTypeValues;
    extPromotionId?: string;
    tokenId?: string;
    tokenValue?: string;
}

export interface ProductResultDto {
    id: string;
    requiresLoginChallenge: boolean;
    productDisplayName: string;
    productShortDescription: string;
    productLongDescription: string;
    prePurchaseDisclaimer: string;
    basePrice: number;
    hasPerformances: boolean;
    hideVariantsInMultiProductView: boolean;
    variants: VariantResultDto[];
    productImages: ProductImageResultDto[];
}

export interface VariantResultDto {
    id: string;
    displayName: string;
    displayPrice: number;
    defaultQuantity: number;
    minQuantity: number;
    maxQuantity: number;
    ageRestrictionText: string;
    minBirthDate: string;
    maxBirthDate: string;
    isPass: boolean;
    productVariantShortDescription: string;
    installmentBillingPlanId: string;
    installmentBillingPlanDescription: string;
    installmentBillingPlanDisclaimer: string;
    effectivePassLevelName: string;
    isNonAdmission: boolean;
    entitlement: VariantResultEntitlementDto;
    sourceSystemTypeId: number;
}

export interface VariantResultEntitlementDto {
    id: string;
    maxRedemptions: number;
    redemptionCount: number;
    remainingRedemptions: number;
}

export interface ProductImageResultDto {
    url: string;
    priority: number;
}

export interface MultiProductResultDto {
    requiresLoginChallenge: boolean;
    pageTitle: string;
    pageDescription: string;
    products: ProductResultDto[];
    promotion: MultiProductPromotionDetailDto;
}

export interface MultiProductPromotionDetailDto {
    extPromotionId: string;
    imageUrl: string;
    groupingTitle: string;
    groupingDescription: string;
}

/**
 * SourceSystemTypeValues
 */
export enum SourceSystemType {
    Unknown = 0,
    TicketingOverview6 = 1,
    FoodPos = 2,
    TicketingBos = 3,
    PinkJeepToursPos = 4
}

/**
 * PerformanceProductShowSelectionDelayTypeValues
 */
export enum PerformanceDelayType {
    NoDelay = 1,
    SecondsAfterFirstSelection = 2,
    SecondsAfterPreviousSelection = 3
}
