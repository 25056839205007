import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {B2BApiClientService} from '../common/b2b-api-client/b2b-api-client.service';
import {
    SelectionProductListDto,
    ProductShowDatesDto,
    ProductDatePerformancesDto,
    PerformanceDetailsDto,
    ShowCategoryDetailsDto
} from './product-selection.model';

@Injectable({
    providedIn: 'root'
})
export class ProductSelectionService {

    constructor(
        private apiClient: B2BApiClientService
    ) {
    }

    getProductList(): Observable<SelectionProductListDto> {
        return this.apiClient.b2bGet<SelectionProductListDto>('product/product-selection');
    }

    getProductShowDates(
        productIds: string[],
        extShowId?: string,
        requiredProductVariantIds?: string[])
        : Observable<ProductShowDatesDto> {
        return this.apiClient.b2bPost<ProductShowDatesDto>('performance/product-date', {
            productIds,
            extShowId,
            requiredProductVariantIds
        });
    }

    getProductDatePerformances(
        productId: string,
        date: string)
        : Observable<ProductDatePerformancesDto> {
        return this.apiClient.b2bPost<ProductDatePerformancesDto>('performance/product-date-performance', {
            productId,
            date
        });
    }

    getPerformanceDetails(productId: string, performanceId: string): Observable<PerformanceDetailsDto> {
        return this.apiClient.b2bPost<PerformanceDetailsDto>('performance/performance-detail', {
            productId,
            performanceId
        });
    }

    getCategoryDetails(
        productId: string,
        performanceId: string,
        showCategoryId: string)
        : Observable<ShowCategoryDetailsDto> {
        return this.apiClient.b2bPost<ShowCategoryDetailsDto>('performance/show-category-detail', {
            productId,
            performanceId,
            showCategoryId
        });
    }
}
