import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SessionService} from '../common/session/session.service';
import {AnalyticsService} from '../common/analytics/analytics.service';
import {timeout} from 'rxjs/operators';

@Component({
    selector: 'hfe-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.less']
})
export class DashboardComponent implements OnInit {

    canSellProducts: boolean;
    canGenerateReports: boolean;
    canReviewOrders: boolean;

    constructor(
        private sessionService: SessionService,
        private router: Router,
        private analyticsService: AnalyticsService
    ) {
    }

    @Input() title = 'Title';
    @Input() icon = 'users';

    ngOnInit(): void {
        this.sessionService
            .getCurrentSessionData()
            .subscribe(sessionData => {
                this.canSellProducts = sessionData.canSellProducts;
                this.canGenerateReports = sessionData.canGenerateReports;
                this.canReviewOrders = sessionData.canReviewOrders;


                if (this.canSellProducts && !this.canGenerateReports) {
                    this.router.navigate(['/product-selection']);
                    return;
                }

                this.analyticsService.logPageView('/dashboard');

            });
    }

}
