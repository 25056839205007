<div class="modal-header">
    <h4 class="modal-title">Verify Phone Number</h4>
</div>
<div class="modal-body text-center">
    <p>For security purposes, you must verify the phone number associated with  your order before you can view it.</p>
    <div>
        <input type="text" class="form-control" id="phoneNumber" [(ngModel)]="phoneNumber" name="phoneNumber"
               aria-label="Order Phone Number" (keyup.enter)="validateOrder()" />
    </div>
    <div *ngIf="errorMessage" class="mt-2">
        <div  class="alert alert-danger">{{errorMessage}}</div>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="validateOrder()">Continue</button>
</div>
