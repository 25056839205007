import { Injectable } from '@angular/core';
import { ApiResultDto, CommerceApiResultDto } from 'lib-commerce-shared';
import { Observable, of } from 'rxjs';
import { B2BApiClientService } from '../common/b2b-api-client/b2b-api-client.service';
import { FreedomPayResponse } from './freedom-pay.model';

@Injectable({
  providedIn: 'root'
})
export class FreedomPayService {

  constructor(
    private apiClient: B2BApiClientService
  ) { }

  getSession(): Observable<FreedomPayResponse> {
    return this.apiClient.b2bPost<FreedomPayResponse>('payment/freedom-pay/setup', {});
  }

  processCard(sessionKey: string, paymentKey: string, priceOverride: string): Observable<FreedomPayResponse> {
    return this.apiClient.b2bPost<FreedomPayResponse>('payment/freedom-pay/process-card', { sessionKey: sessionKey, paymentKey: paymentKey, priceOverride: priceOverride });
  }
}
