import { Component, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'hfe-terms',
  templateUrl: './terms.component.html',
  styleUrls: ['./terms.component.scss']
})
export class TermsComponent {
  public static stringResources: string[] = [];

  constructor(
    private route: ActivatedRoute,
    private modalService: NgbModal
  ) { }

  @Input() isModal: boolean;
  @Input() extVersionId: string;
  termsContent: string;
  errorMessage: string;

  ngOnInit(): void {
    if (!this.isModal) {
      this.route.params.subscribe(params => {
        this.extVersionId = params.id;
      });
    }
  }

  closeModal(): void {
    this.modalService.dismissAll(false);
  }
}
