import { Injectable } from '@angular/core';
import { ApiResultDto, CommerceApiResultDto } from 'lib-commerce-shared';
import { Observable } from 'rxjs';
import { B2BApiClientService } from '../common/b2b-api-client/b2b-api-client.service';
import {
    AvailableDeliveryMethodDto,
    AvailablePaymentMethodDto,
    CheckoutCreateDto,
    OrderConvertResultDto,
    UpdateCartSettingsDto
} from './checkout.model';

@Injectable({
  providedIn: 'root'
})
export class CheckoutService {

  constructor(
    private apiClient: B2BApiClientService
  ) { }

  getDeliveryMethods(): Observable<AvailableDeliveryMethodDto[]> {
    return this.apiClient.b2bGet<AvailableDeliveryMethodDto[]>('cart/delivery-method');
  }

  getPaymentMethods(): Observable<AvailablePaymentMethodDto[]> {
    return this.apiClient.b2bGet<AvailablePaymentMethodDto[]>('cart/payment-method');
  }

  updateCartSettings(payload: UpdateCartSettingsDto): Observable<CommerceApiResultDto> {
    return this.apiClient.b2bPost<CommerceApiResultDto>('cart/settings', payload);
  }

  createCheckout(payload: CheckoutCreateDto): Observable<ApiResultDto> {
    return this.apiClient.b2bPost<ApiResultDto>('checkout', payload);
  }

  submitOrder(paymentKey: string, cardholderName: string, cardholderAddress: string, overridePaymentAmount: number): Observable<OrderConvertResultDto>{
      return this.apiClient.b2bPost<OrderConvertResultDto>('order/submit', {
          paymentKey,
          overridePaymentAmount,
          cardholderName,
          cardholderAddress
      });
  }
}
