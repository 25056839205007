<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Recipient Email Address</h4>
  <button type="button" class="btn-close close" aria-label="Close" (click)="closeModal()">
  </button>
</div>
<div class="modal-body">
  <input type="text" class="form-control" [(ngModel)]="email">
</div>
<div class="modal-footer">
  <div class="alert alert-danger w-100"
    *ngIf="resendConfirmationErrorMessage && resendConfirmationErrorMessage.length > 0">
    {{resendConfirmationErrorMessage}}
  </div>
  <div class="alert alert-success w-100" *ngIf="resendConfirmationSuccessMessage">
    {{resendConfirmationSuccessMessage}}
  </div>
  <button type="button" class="btn btn-secondary ghost" (click)="closeModal()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="send()">Send</button>
</div>