import {Injectable} from '@angular/core';
import {B2BApiClientService} from '../common/b2b-api-client/b2b-api-client.service';
import {Observable} from 'rxjs';
import {ApiResultDto, CommerceApiResultDto} from 'lib-commerce-shared';

@Injectable({
    providedIn: 'root'
})
export class OrderViewService {

    constructor(
        private apiClient: B2BApiClientService
    ) {
    }

    lookupOrder(extOrderTokenId: string, token: string, phoneNumber: string): Observable<ApiResultDto> {
        return this.apiClient.b2bPost<ApiResultDto>('order/details/external', {
            extOrderTokenId,
            token,
            phoneNumber
        });
    }

    getTicketContent(): Observable<string> {
        return this.apiClient.b2bGetText('order/digital-tickets/external');
    }
}
