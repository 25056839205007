import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { B2BApiClientService } from '../common/b2b-api-client/b2b-api-client.service';
import { ClientSummaryDto } from './select-client.model';

@Injectable({
  providedIn: 'root'
})
export class SelectClientService {

  constructor(
    private apiClient: B2BApiClientService
  ) { }

  getClientList(): Observable<ClientSummaryDto[]> {
    return this.apiClient.b2bGet<ClientSummaryDto[]>('client');
  }
}
