<div class="cart-summary-content">
    <h4>Cart Summary</h4>
    <div class="empty-cart-message" *ngIf="!cartDetails || cartDetails.itemGroups.length == 0">There are no items in
        your cart
    </div>
    <div class="summary-line-items" *ngIf="cartDetails?.itemGroups">
        <div class="summary-line" *ngFor="let group of cartDetails.itemGroups">
            <div class="product-name">
                {{group.productName}}
            </div>
            <div *ngFor="let item of group.items">
                <div class="row">
                    <div class="col">
                        <div class="product-each">
                            {{item.displayName}}
                        </div>
                        <div class="perf-each" *ngIf="item.showName">
                            {{item.showName}}
                        </div>
                        <div class="perf-each" *ngIf="item.showCategoryName">
                            {{item.showCategoryName}}
                        </div>
                        <div class="perf-each" *ngIf="item.performanceDate">
                            {{item.performanceDate}}
                        </div>
                        <div class="time-each" *ngIf="item.performanceTime">
                            {{item.performanceTime}}
                        </div>
                    </div>
                    <div class="col">
                        <div class="col qty-each">
                            {{item.quantity}}
                        </div>
                        <div class="col price-each">
                            {{item.unitPrice | currency:'USD':'symbol':'1.2-2'}}
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="item.seats.length>0">
                    <div class="col">
                        <div class="mt-2 mb-1">
                            <strong>Seats:</strong>
                        </div>
                        <div *ngFor="let seat of item.seats">
                            <span *ngIf="seat.seatCategoryName">
                                {{seat.seatCategoryName}} /
                            </span>
                            {{seat.row}}
                            <span *ngIf="seat.seat">
                                / {{seat.seat}}
                            </span>
                        </div>
                    </div>
                </div>
                <div class="line-totals">
                    <div class="product-controls">
                        <button type="button" class="btn btn-danger" title="Remove" (click)="removeItem(item)">
                            <fa-icon class="far" icon="window-close"></fa-icon>
                        </button>
                        <button type="button" class="btn btn-primary" title="Update" (click)="updateItem(group, item)">
                            <fa-icon class="far" icon="edit"></fa-icon>
                        </button>
                    </div>
                    <div class="price-total">
                        {{item.itemTotal | currency }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="order-summary-content" *ngIf="cartDetails && cartDetails.itemGroups.length > 0">
    <h4>Order Summary</h4>
    <div class="order-line-items">
        <div class="row order-line">
            <div class="col">
                Subtotal
            </div>
            <div class="col price-each">
                {{cartDetails?.cartTotalNetTaxesAndFees | currency}}
            </div>
        </div>
        <div class="row order-line">
            <div class="col">
                Delivery
            </div>
            <div class="col price-each">
                {{cartDetails?.deliveryTotal | currency}}
            </div>
        </div>
        <div class="row order-line">
            <div class="col">
                Tax
            </div>
            <div class="col price-each">
                {{cartDetails?.taxTotal | currency}}
            </div>
        </div>
        <div class="row order-line">
            <div class="col">
                Fees
            </div>
            <div class="col price-each">
                {{cartDetails?.feeTotal | currency}}
            </div>
        </div>
    </div>
</div>
<div class="order-value-content" *ngIf="cartDetails && cartDetails.itemGroups.length > 0">
    <div class="row">
        <div class="col price-total">
            Order Total
        </div>
        <div class="col price-total">
            {{cartDetails?.cartTotal | currency }}
        </div>
    </div>
</div>
