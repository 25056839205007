import {Injectable} from '@angular/core';
import {SessionService} from '../session/session.service';

declare function gtag(a1: any, a2: any, a3: any): void;

@Injectable({
    providedIn: 'root'
})
export class AnalyticsService {

    constructor() {
    }

    public trackingId: string;
    public prefix: string;

    loadAnalyticsIfNotAlreadyLoaded(
        trackingId: string,
        prefix: string): void {

        if (trackingId && !this.trackingId) {
            this.trackingId = trackingId;
            this.prefix = prefix;

            const gaScript = document.createElement('script');
            gaScript.setAttribute('async', 'true');
            gaScript.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${trackingId}`);

            const gaScript2 = document.createElement('script');
            // tslint:disable-next-line: max-line-length
            gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingId}\', {\'linker\': {\'accept_incoming\': true}});`;

            document.documentElement.firstChild.appendChild(gaScript);
            document.documentElement.firstChild.appendChild(gaScript2);
        }
    }

    logPageView(path: string): void {
        if (this.trackingId && gtag) {
            gtag('config', this.trackingId, {page_path: path});
        }
    }

    logPageEvent(eventName: string, category: string, label: string): void {
        if (this.trackingId && gtag) {
            gtag('event', eventName, {event_category: category, event_label: label});
        }
    }

    logPageEventWithPrefix(eventName: string, category: string, label: string): void {
        if (this.trackingId && gtag) {
            gtag('event', eventName, {event_category: category, event_label: `${this.prefix}-${label}`});
        }
    }

}
