<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Confirmation</h4>
</div>
<div class="modal-body">
    <span [innerHTML]="message"></span>
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary ghost" (click)="closeModal()">Cancel</button>
    <button type="button" class="btn btn-danger" (click)="removeItems()">Remove</button>
</div>
