<div class="quantity-container d-flex align-items-center">
    <button class="btn btn-sm btn-outline-secondary me-1" (click)="incrementDown()">
        <span class="decrement">-</span>
    </button>
    <input class="form-field quantity-input" type="text" [ngModel]="quantity" (ngModelChange)="textChange($event)"/>
    <button class="btn btn-sm btn-outline-secondary ms-1" (click)="incrementUp()">
        <span class="increment">+</span>
    </button>        
</div>
<div class="quantity-invalid" [hidden]="!invalidSelection">
    Minimum quantity is {{min}}, Maximum quantity is {{max}}
</div>