import { Component, ElementRef, HostListener, Input, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ReportParameter, ReportParameterType } from './report-parameters.model';

@Component({
  selector: 'hfe-report-parameters',
  templateUrl: './report-parameters.component.html',
  styleUrls: ['./report-parameters.component.less']
})
export class ReportParametersComponent implements OnInit {
  @Input() parameters: ReportParameter[];
  errors: string[] = [];  

  constructor(private route: ActivatedRoute, 
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  nonCheckboxParams(): ReportParameter[] {
    return this.parameters.filter(param => param.type !== 2);
  }

  checkboxParams(): ReportParameter[] {
    return this.parameters.filter(param => param.type === 2);
  }

  isValid(): boolean {
    this.errors = [];
    let startDate: Date = null;
    let endDate: Date = null;
    this.parameters.forEach(param => {
      if (param.required && !param.value) {
        this.errors.push(`${param.label} requires a value`);
      }
      if (param.value && (param.type === ReportParameterType.date || param.type === ReportParameterType.startDate || param.type === ReportParameterType.endDate)) {
        const testDate = new Date(param.value);
        if (isNaN(testDate.getTime())) {
          this.errors.push(`${param.label} is not a valid date`);
        }
      }
      if (param.type === ReportParameterType.startDate && param.value) {
        startDate = new Date(param.value);
      }
      if (param.type === ReportParameterType.endDate && param.value) {
        endDate = new Date(param.value);
      }
    });
    if (startDate && endDate && startDate > endDate) {
      this.errors.push('A start date must be before an end date');
    }
    return this.errors.length === 0;
  }

  clear() {
    this.parameters.forEach(parameter => parameter.value = undefined);
  }
}