import {Injectable, NgZone} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {

    constructor(
        private zone: NgZone
    ) {
    }

    visibleCount = 0;
    messages: string[] = [];

    get visible(): boolean {
        return this.visibleCount > 0;
    }

    get loadingMessage(): string {
        if (this.messages.length > 0) {
            return this.messages[this.messages.length - 1];
        }
        return 'Loading';
    }

    pushShow(message: string): void {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.zone.run(() => {
                    this.visibleCount++;
                    if (message) {
                        this.messages.push(message);
                    }
                });
            });
        });
    }

    popShow(): void {
        this.zone.runOutsideAngular(() => {
            setTimeout(() => {
                this.zone.run(() => {
                    if (this.visibleCount > 0) {
                        this.visibleCount--;
                    }
                    if (this.messages.length > 0) {
                        this.messages.pop();
                    }
                });
            });
        });
    }

}
