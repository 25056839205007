import { Injectable } from '@angular/core';
import { B2BApiClientService } from '../common/b2b-api-client/b2b-api-client.service';
import { Observable } from 'rxjs';
import { CurrentTermsSummaryDto, TermsContentDto } from './terms.model';

@Injectable({
  providedIn: 'root'
})
export class TermsService {

  constructor(
    private apiClient: B2BApiClientService
  ) { }

  getTermsContent(extVersionId: string): Observable<TermsContentDto> {
    return this.apiClient.b2bGet<TermsContentDto>(`terms/by-version/${extVersionId}`);
  }

  getCurrentTermsSummary(): Observable<CurrentTermsSummaryDto> {
    return this.apiClient.b2bGet<CurrentTermsSummaryDto>('terms/current');
  }

}
