<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Session Ended</h4>
</div>
<div class="modal-body text-center">
    <p>
        {{message}}
    </p>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="logOut()">Log In Again</button>
</div>
