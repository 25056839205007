import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ClientSummaryDto} from './select-client.model';
import {SelectClientService} from './select-client.service';
import {SessionService} from '../common/session/session.service';
import {AppConfigService} from '../app-config.service';

@Component({
    selector: 'hfe-select-client-popup',
    templateUrl: './select-client-popup.component.html',
    styleUrls: ['./select-client-popup.component.less']
})
export class SelectClientPopupComponent implements OnInit {

    constructor(
        private selectClientService: SelectClientService,
        private router: Router,
        private modalService: NgbModal,
        private sessionService: SessionService,
        private appConfigService: AppConfigService,
        private route: ActivatedRoute
    ) {
    }

    noClients = false;
    clients: ClientSummaryDto[];
    errorMessage = '';


    ngOnInit(): void {
        this.selectClientService.getClientList()
            .subscribe(clients => {
                this.clients = clients;
                if (this.clients.length === 1) {
                    this.setClient(this.clients[0].id);
                }
            });
    }

    selectClient(client: ClientSummaryDto): void {
        this.errorMessage = '';
        this.setClient(client.id);
    }

    setClient(id: number): void {
        this.sessionService.setClient(id)
            .subscribe(result => {
                if (result.success) {
                    this.modalService.dismissAll();
                    this.navigateUser();
                } else {
                    this.errorMessage = result.message;
                }
            }, error => {
                console.log(error);
                this.errorMessage = 'An unexpected error has occurred';
            });
    }

    navigateUser(): void {

        // Figure out a return path
        let returnPath = '/dashboard';
        if (this.route.snapshot.queryParams.return) {
            returnPath = this.route.snapshot.queryParams.return;
        }

        // If they asked for something other than dashboard then allow it
        if (returnPath !== '/dashboard') {
            this.router.navigateByUrl(returnPath);
            return;
        }

        // Apply logic based on permission
        this.sessionService
            .getCurrentSessionData()
            .subscribe(sessionData => {
                if (sessionData.canSellProducts && !sessionData.canGenerateReports) {
                    this.router.navigate(['/product-selection']);
                } else {
                    this.router.navigate(['/dashboard']);
                }
            });
    }

    logout(): void {
        window.location.href = this.appConfigService
            .getConfig()
            .serverAppRoot + 'logout';
    }

}
