import {Injectable} from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {AppConfigService} from '../../app-config.service';
import {WebSiteSettings} from '../../app.model';
import {environment} from '../../../environments/environment';
import {catchError, map} from 'rxjs/operators';
import {IdleService} from '../idle/idle.service';

@Injectable({
    providedIn: 'root'
})
export class B2BApiClientService {

    constructor(
        private httpClient: HttpClient,
        private appConfigService: AppConfigService,
        private idleService: IdleService
    ) {
    }

    public getRealB2BUrl(relativeUrl: string): string {
        let root = this.appConfigService.getConfig().b2BApiRoot;
        if (!root.endsWith('/')) {
            root += '/';
        }
        if (relativeUrl.startsWith('/')) {
            relativeUrl = relativeUrl.substr(0, relativeUrl.length - 1);
        }
        return root + relativeUrl;
    }

    redirectIfNotAuthorized(err: HttpErrorResponse): Observable<never> {
        if (err.status === 401) {
            return this.httpClient.get<WebSiteSettings>(environment.settingsUrl,
                {
                    withCredentials: true
                })
                .pipe(map(settings => {
                    if (!settings.authenticated) {
                        window.location.href = this.appConfigService.getConfig().serverAppRoot + 'login';
                    }
                    throw err;
                }));
        }
        return throwError(err);
    }

    b2bGet<T>(url: string): Observable<T> {
        url = this.getRealB2BUrl(url);
        this.idleService.resetIdle();
        return this.httpClient.get<T>(url, {
            withCredentials: true
        }).pipe(catchError(err => this.redirectIfNotAuthorized(err)));
    }

    b2bGetText(url: string): Observable<string> {
        url = this.getRealB2BUrl(url);
        this.idleService.resetIdle();
        return this.httpClient.get(url, {
            responseType: 'text',
            withCredentials: true
        }).pipe(catchError(err => this.redirectIfNotAuthorized(err)));
    }

    b2bPost<T>(url: string, payload: any, headers?: HttpHeaders, overrideResponseType?: string): Observable<T> {
        const options = { withCredentials: true };
        /* tslint:disable:no-string-literal */
        if (headers) {
            options['headers'] = headers;
        }
        if (overrideResponseType) {
            options['responseType'] = overrideResponseType;
        }
        url = this.getRealB2BUrl(url);
        this.idleService.resetIdle();
        return this.httpClient.post<T>(url, payload, options).pipe(catchError(err => this.redirectIfNotAuthorized(err)));
    }

    b2bPut<T>(url: string, payload: any): Observable<T> {
        url = this.getRealB2BUrl(url);
        this.idleService.resetIdle();
        return this.httpClient.put<T>(url, payload, {
            withCredentials: true
        }).pipe(catchError(err => this.redirectIfNotAuthorized(err)));
    }

    b2bDelete<T>(url: string): Observable<T> {
        url = this.getRealB2BUrl(url);
        this.idleService.resetIdle();
        return this.httpClient.delete<T>(url, {
            withCredentials: true
        }).pipe(catchError(err => this.redirectIfNotAuthorized(err)));
    }
}
