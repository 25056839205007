import { Injectable, Injector } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { WebSiteSettings } from './app.model';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AppConfigService {

  private settings: WebSiteSettings;

  constructor(
    private http: HttpClient
  ) { }

  public get webSiteName(): string { return this.settings.webSiteName; }

  loadSettings(): Promise<void | WebSiteSettings> {
    return this.http.get<WebSiteSettings>(environment.settingsUrl,
      {
        withCredentials: true
      })
      .toPromise()
      .then(data => {
        this.settings = data;
      });
  }

  getConfig(): WebSiteSettings {
    return this.settings;
  }
}
