import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ResourceService } from "./resource.service";

@Injectable({
    providedIn: 'root'
})
export class ComponentStringResourceLoader  {

    constructor(
        private resourceService: ResourceService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {

        if (Array.isArray(route.data.stringResources)) {
            const resources: string[] = route.data.stringResources;
            return this.resourceService.preLoadValues(resources);
        }

        // nothing to do
        return of(true);
    }
}
