import { Component, OnInit } from '@angular/core';
import { KeyValue } from '@angular/common';
import { ReportingService } from './reports.service';

@Component({
  selector: 'hfe-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.less']
})
export class ReportsComponent implements OnInit {
  reports: KeyValue<number, string>[];

  constructor(private reportingService: ReportingService) { }

  ngOnInit(): void {
    this.reportingService.getReports().subscribe(reports => this.reports = reports);
  }

}
