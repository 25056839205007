<hfe-page-header>
    <a routerLink="/dashboard">Dashboard</a>
    <span class="px-2">/</span>
    <a routerLink="/product-selection">Products</a>
    <span class="px-2">/</span>
    Shopping Cart
</hfe-page-header>
<hfe-page-section>
    <div *ngIf="cartDetails" class="shoppingCartDetails m-3">
        <div class="d-flex justify-content-end">
            <button class="btn btn-secondary ghost me-2" routerLink="/product-selection">Add More Items</button>
            <button class="btn btn-primary" routerLink="/checkout" [disabled]="cartDetails.cartEmpty">Checkout</button>
        </div>
        <div class="alert alert-warning m-3" *ngIf="cartDetails.itemGroups.length == 0">
            <hfe-resource extResourceId="b2bCartEmptyText">Your shopping cart is empty</hfe-resource>
        </div>
        <table class="table table-striped table-hover" *ngIf="cartDetails.itemGroups.length > 0">
            <thead>
            <tr class="line-title">
                <th>Description</th>
                <th class="w-75">&nbsp;</th>
                <th class="w-10">Qty</th>
                <th class="w-15">Price</th>
                <th class="w-10">Total</th>
            </tr>
            </thead>
            <tbody *ngFor="let group of cartDetails.itemGroups">
            <tr>
                <td colspan="5" class="product-name">
                    <div class="product-name">
                        {{group.productName}}
                    </div>
                    <div class="d-flex"></div>
                </td>
            </tr>
            <tr *ngFor="let item of group.items" (click)="updateItem(group, item)">
                <td>
                    <button class="btn btn-danger" [routerLink]="null" (click)="removeItem(item);$event.stopPropagation();">
                        <fa-icon icon="window-close" class="far"></fa-icon>
                    </button>
                    <button class="btn btn-primary" [routerLink]="null" (click)="updateItem(group, item);$event.stopPropagation();">
                        <fa-icon icon="edit" class="far"></fa-icon>
                    </button>
                </td>
                <td>
                    <div>{{item.displayName}}</div>
                    <div>{{item.showName}}</div>
                    <div>{{item.performanceDate}}</div>
                    <div>{{item.performanceTime}}</div>
                    <div>{{item.showCategoryName}}</div>
                </td>
                <td>{{item.quantity}}</td>
                <td>{{item.unitPrice | currency:'USD':'symbol':'1.2-2'}}</td>
                <td>{{item.itemTotal | currency:'USD':'symbol':'1.2-2'}}</td>
            </tr>
            </tbody>
            <tbody>

            <tr *ngIf="cartDetails.deliveryMethodName">
                <td>&nbsp;</td>
                <td>Delivery method: {{cartDetails.deliveryMethodName}}</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
                <td>&nbsp;</td>
            </tr>
            <tr class="taxFeeBreakdown">
                <td colspan="2"></td>
                <td colspan="2">
                    <div class="d-flex flex-column">
                        <span>Sub-Total</span>
                        <span>Taxes</span>
                    </div>
                </td>
                <td>
                    <div class="d-flex flex-column">
                        <span>{{cartDetails.cartTotalNetTaxesAndFees| currency:'USD':'symbol':'1.2-2'}}</span>
                        <span>{{cartDetails.taxTotal| currency:'USD':'symbol':'1.2-2'}}</span>
                    </div>
                </td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
                <td colspan="2"></td>
                <td colspan="2">
                    <div>
                        <strong>Total Price</strong>
                    </div>
                </td>
                <td>
                    <div>
                        <strong>{{cartDetails.cartTotal| currency:'USD':'symbol':'1.2-2'}}</strong>
                    </div>
                </td>
            </tr>
            </tfoot>
        </table>

        <div class="d-flex justify-content-end" *ngIf="cartDetails.itemGroups.length > 0">
            <button class="btn btn-secondary ghost me-2" routerLink="/product-selection">Add More Items</button>
            <button class="btn btn-primary" routerLink="/checkout">Checkout</button>
        </div>
    </div>
</hfe-page-section>
<hfe-page-footer></hfe-page-footer>
