import {EventEmitter, Injectable} from '@angular/core';
import {B2BApiClientService} from '../b2b-api-client/b2b-api-client.service';
import {CurrentSessionDetailsDto} from './session.model';
import {Observable} from 'rxjs';
import {shareReplay, tap} from 'rxjs/operators';
import {ApiResultDto} from 'lib-commerce-shared';
import {AnalyticsService} from '../analytics/analytics.service';

@Injectable({
    providedIn: 'root'
})
export class SessionService {

    private cachedSessionData$: Observable<CurrentSessionDetailsDto>;
    private creditWarningShown = false;
    private cssSiteKey = '';
    public siteLogUrl = '';
    public analyticsSiteCode = '';
    public isProxy = false;
    public proxyUsername = '';
    public username = '';
    public siteMessage = '';
    public OnSessionChange = new EventEmitter<any>();

    constructor(
        private apiClient: B2BApiClientService,
        private analyticsService: AnalyticsService
    ) {
    }

    getCurrentSessionData(): Observable<CurrentSessionDetailsDto> {
        if (!this.cachedSessionData$) {
            this.cachedSessionData$ = this.apiClient.b2bGet<CurrentSessionDetailsDto>('session')
                .pipe(
                    tap(session => {
                        this.OnSessionChange.emit();
                        const oldSiteKey = this.cssSiteKey;
                        this.cssSiteKey = session.cssSiteKey;
                        if (oldSiteKey !== this.cssSiteKey) {
                            if (oldSiteKey) {
                                document.body.classList.remove(oldSiteKey);
                            }
                            if (this.cssSiteKey) {
                                document.body.classList.add(this.cssSiteKey);
                            }
                        }
                        this.siteLogUrl = session.siteLogoUrl;
                        this.proxyUsername = session.proxyUsername;
                        this.username = session.username;
                        this.siteMessage = session.siteMessage;

                        // Establish analytics
                        if (session.analyticsId !== this.analyticsService.trackingId) {
                            this.analyticsService.loadAnalyticsIfNotAlreadyLoaded(
                                session.analyticsId,
                                session.analyticsSiteCode
                            );
                        }
                    }),
                    shareReplay(1));
        }
        return this.cachedSessionData$;
    }

    clearCachedData(): void {
        this.cachedSessionData$ = null;
        this.creditWarningShown = false;
        this.siteLogUrl = '';
    }

    setClient(clientId: number): Observable<ApiResultDto> {
        return this.apiClient.b2bPost<ApiResultDto>('session', {
            clientId
        }).pipe(tap(result => {
            this.clearCachedData();
        }));
    }

    displayCreditWarning(): boolean {
        return !this.creditWarningShown;
    }

    creditWarningHasBeenDisplayed(): void {
        this.creditWarningShown = true;
    }
}
