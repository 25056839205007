import { KeyValue } from "@angular/common";
import { Observable } from "rxjs";

export interface Report {
  title: string;
  path: string;
  parameters: ReportParameter[];
  hasExcel?: boolean;
}

export interface ReportParameter {
  label: string;
  ssrsName: string;
  type: ReportParameterType;
  required?: boolean;
  dataSource?: string;
  dataSourceValues?: Observable<KeyValue<string, string>[]>;
  value?: string;
}

export enum ReportParameterType {
  date = 1,
  checkbox = 2,
  text = 3,
  numeric = 4,
  dropdown = 5,
  multiselect = 6,
  startDate = 7,
  endDate = 8
}