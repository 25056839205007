import {Injectable} from '@angular/core';
import {B2BApiClientService} from '../common/b2b-api-client/b2b-api-client.service';
import {OrderSearchParametersDto, OrderSearchResponseDto} from './order-lookup.model';
import {Observable} from 'rxjs';
import {OrderDetailsDto} from 'lib-commerce-shared';

@Injectable({
    providedIn: 'root'
})
export class OrderLookupService {

    constructor(
        private apiClient: B2BApiClientService
    ) {
    }

    search(searchParameters: OrderSearchParametersDto): Observable<OrderSearchResponseDto> {
        return this.apiClient.b2bPost<OrderSearchResponseDto>('order/search', searchParameters);
    }

    getOrderDetails(extOrderId: string): Observable<OrderDetailsDto> {
        return this.apiClient.b2bGet<OrderDetailsDto>(`order/details/review/${extOrderId}`);
    }

    getTicketContent(extOrderId: string): Observable<string> {
        return this.apiClient.b2bGetText(`order/digital-tickets/review/${extOrderId}`);
    }
}
