import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'payment-freedom-pay',
    templateUrl: './payment-freedom-pay.component.html',
    styleUrls: ['./payment-freedom-pay.component.less']
})
export class PaymentFreedomPayComponent implements OnInit {

  constructor() {}

  ngOnInit(): void {
  }
}
