import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AppConfigService} from '../../app-config.service';

@Component({
  selector: 'hfe-session-expiration-warning',
  templateUrl: './session-expiration-warning.component.html',
  styleUrls: ['./session-expiration-warning.component.less']
})
export class SessionExpirationWarningComponent implements OnInit {

  constructor(
      public activeModal: NgbActiveModal,
      private appConfig: AppConfigService
  ) { }

  message: string;

  ngOnInit(): void {
      this.message = this.appConfig.getConfig().sessionWarningMessage;
  }

  continue(): void {

  }

}
