import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MiniCartService} from '../common/mini-cart/mini-cart.service';
import {CartItemChangeComponent} from './cart-item-change.component';
import {CartDetailItemDto, CartDetailItemGroupDto, CartDetailsDto} from './shopping-cart.model';
import {ShoppingCartService} from './shopping-cart.service';
import {AnalyticsService} from '../common/analytics/analytics.service';

@Component({
    selector: 'hfe-shopping-cart',
    templateUrl: './shopping-cart.component.html',
    styleUrls: ['./shopping-cart.component.less']
})
export class ShoppingCartComponent implements OnInit {

    constructor(
        private shoppingCartService: ShoppingCartService,
        private modalService: NgbModal,
        private miniCartService: MiniCartService,
        private analyticsService: AnalyticsService
    ) {
    }

    cartDetails: CartDetailsDto;
    clearCartError: string;

    ngOnInit(): void {
        this.analyticsService.logPageView('/shopping-cart');
        this.load();
    }

    load(): void {
        this.shoppingCartService
            .getCartDetails()
            .subscribe(result => {
                this.cartDetails = result;
            });
    }

    reload(): void {
        this.miniCartService.refreshCartDetails();
        this.load();
    }

    updateItem(group: CartDetailItemGroupDto, item: CartDetailItemDto): void {
        const modalRef = this.modalService.open(CartItemChangeComponent, {
            centered: true
        });
        modalRef.componentInstance.productGroup = group;
        modalRef.result.then(result => {
            this.reload();
        }, result => {
            this.reload();
        });
    }

    removeItem(item: CartDetailItemDto): void {
        this.shoppingCartService.modifyCartItem(
            [
                {
                    id: item.id,
                    performanceId: item.performanceId,
                    showCategoryId: item.showCategoryId,
                    quantity: 0
                }

            ])
            .subscribe(result => {
                this.reload();
            });
    }

}
