import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Observable, of} from 'rxjs';
import {map, tap, timeout} from 'rxjs/operators';
import {ResourceService} from './resource.service';
import {SessionService} from '../session/session.service';

@Component({
    selector: 'hfe-resource',
    templateUrl: './resource.component.html',
    styleUrls: ['./resource.component.less']
})
export class ResourceComponent implements OnInit, OnChanges {

    constructor(
        private resourceService: ResourceService,
        private sessionService: SessionService
    ) {
        sessionService.OnSessionChange.subscribe(() => {
            this.refresh(true);
        });
    }

    @Input() extResourceId = '';

    useDefault$: Observable<boolean>;
    resourceText$: Observable<string>;

    ngOnInit(): void {
        this.refresh(false);
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.refresh(false);
    }

    refresh(forceRefresh = false): void {
        if (this.extResourceId) {
            const val$ = this.resourceService.getResourceValue(this.extResourceId, null, forceRefresh);
            this.resourceText$ = val$;
            this.useDefault$ = val$
                .pipe(map(result => {
                    return !result;
                }));
        } else {
            this.resourceText$ = of('');
            this.useDefault$ = of(true);
        }
    }

}
