<div class="app-container b2b">
    <router-outlet></router-outlet>
</div>

<div class="spinner-overlay" *ngIf="spinnerService.visible">
</div>

<div class="spinner-container-container d-flex justify-content-center align-items-center"
     *ngIf="spinnerService.visible">
    <div class="spinner-container m-auto" *ngIf="spinnerService.visible">
        <fa-icon [spin]="true" icon="sync"></fa-icon>
        {{spinnerService.loadingMessage}}
    </div>
</div>
