import { Injectable } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
    providedIn: 'root'
})
export class StandardDateAdapterService {

    constructor() { }

    readonly DELIMITER_US = '/';
    readonly DELIMITER_ISO = '-';

    fromModel(value: string | null): NgbDateStruct | null {
        if (value) {
            let date = value.split(this.DELIMITER_US);
            if (date.length === 3) {
                return {
                    month: parseInt(date[0], 10),
                    day: parseInt(date[1], 10),
                    year: parseInt(date[2], 10)
                };
            }

            date = value.split(this.DELIMITER_ISO);
            if (date.length === 3) {
                return {
                    year: parseInt(date[0], 10),
                    month: parseInt(date[1], 10),
                    day: parseInt(date[2], 10)
                }
            }
        }
        return null;
    }

    toModel(date: NgbDateStruct | null): string | null {
        return date ? date.month + this.DELIMITER_US + date.day + this.DELIMITER_US + date.year : null;
    }

}
