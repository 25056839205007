<hfe-page-header [hasCart]="false">
    <a routerLink="/dashboard">Dashboard</a>
    <span class="px-2">/</span>
    Order History
</hfe-page-header>

<hfe-page-section sectionClass="order-lookup">

    <ngb-accordion #mainAccordion activeIds="mainPanel">
        <ngb-panel id="mainPanel">
            <ng-template ngbPanelHeader>
                <div class="header-container d-flex align-items-center justify-content-between w-100"
                     (click)="mainAccordion.toggle('mainPanel')">
                    <h5 class="m-3">Search Criteria</h5>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <div class="alert alert-danger w-100 m-2 errors" *ngIf="errorMessage">
                    {{errorMessage}}
                </div>
                <div class="row">
                    <div class="form-field mb-3 col col-12 col-md-6 col-lg-4">
                        <label class="form-label" for="ticketingReservationCode">Reservation Code</label>
                        <input class="form-control" type="text" id="ticketingReservationCode"
                               aria-label="Reservation Code"
                               [(ngModel)]="searchParameters.ticketingReservationCode" (keyup.enter)="load()">
                    </div>
                    <div class="form-field mb-3 col col-12 col-md-6 col-lg-4">
                        <label class="form-label" for="customerName">Customer Name</label>
                        <input class="form-control" type="text" id="customerName" aria-label="Customer Name"
                               [(ngModel)]="searchParameters.customerName" (keyup.enter)="load()">
                    </div>
                    <div class="form-field mb-3 col col-12 col-md-6 col-lg-4">
                        <label class="form-label" for="customerPhone">Phone Number</label>
                        <input class="form-control" type="text" id="customerPhone" aria-label="Phone Number"
                               [(ngModel)]="searchParameters.customerPhone" (keyup.enter)="load()">
                    </div>
                    <div class="form-field mb-3 col col-12 col-md-6 col-lg-4">
                        <label class="form-label" for="sellerName">Seller Name</label>
                        <input class="form-control" type="text" id="sellerName" aria-label="Seller Name"
                               [(ngModel)]="searchParameters.sellerName" (keyup.enter)="load()">
                    </div>
                    <div class="form-field mb-3 col col-12 col-md-6 col-lg-4">
                        <label class="form-label" for="dpFromDate">From Date</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="mm/dd/yyyy" id="dpFromDate" name="dpFromDate"
                                   ngbDatepicker
                                   #dpFromDate="ngbDatepicker" [firstDayOfWeek]="7"
                                   [(ngModel)]="searchParameters.startDate" container="body"/>
                                <button class="h-100 btn btn-outline-secondary calendar" (click)="dpFromDate.toggle()"
                                        type="button">
                                    <fa-icon icon="calendar-alt"></fa-icon>
                                </button>
                        </div>
                    </div>
                    <div class="form-field mb-3 col col-12 col-md-6 col-lg-4">
                        <label class="form-label" for="dpToDate">To Date</label>
                        <div class="input-group">
                            <input class="form-control" placeholder="mm/dd/yyyy" id="dpToDate" name="dpToDate"
                                   ngbDatepicker [firstDayOfWeek]="7"
                                   #dpToDate="ngbDatepicker" [(ngModel)]="searchParameters.endDate" container="body"/>
                                <button class="h-100 btn btn-outline-secondary calendar" (click)="dpToDate.toggle()"
                                        type="button">
                                    <fa-icon icon="calendar-alt"></fa-icon>
                                </button>
                        </div>
                    </div>
                </div>
                <div class="row d-flex justify-content-end">
                    <button class="w-auto btn btn-secondary ms-auto me-2 mt-5 ghost" (click)="resetSearch()">Reset Parameters</button>
                    <button class="w-auto btn btn-primary me-2 mt-5" (click)="load()">Search</button>
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>

    <ngb-accordion #resultsAccordion activeIds="resultsPanel" *ngIf="searchResponse">
        <ngb-panel id="resultsPanel">
            <ng-template ngbPanelHeader>
                <div class="header-container d-flex align-items-center justify-content-between w-100"
                     (click)="resultsAccordion.toggle('resultsPanel')">
                    <h5 class="m-3">Orders</h5>
                </div>
            </ng-template>
            <ng-template ngbPanelContent>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Reservation Code</th>
                            <th>Order Date / Time</th>
                            <th>Name</th>
                            <th>Email</th>
                            <th>Phone Number</th>
                            <th>Reference Number</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let order of searchResponse.results" [routerLink]="['/order-lookup', order.extOrderId]" class="cursor-pointer">
                            <td>
                                {{ order.ticketingReservationCode }}
                            </td>
                            <td>
                                {{ order.orderDateTime | date : 'M/d/yyyy h:mm:ss a'}}
                            </td>
                            <td>
                                {{order.lastName}}, {{order.firstName}}
                            </td>
                            <td>
                                <a href="mailto:{{order.emailAddress}}" *ngIf="order.emailAddress">
                                    {{order.emailAddress}}
                                </a>
                            </td>
                            <td>
                                <a href="tel:{{order.phoneNumber}}" *ngIf="order.phoneNumber">
                                    {{order.phoneNumber}}
                                </a>
                            </td>
                            <td>
                                {{order.referenceNumber}}
                            </td>
                    </tr>
                    </tbody>
                </table>
                <div class="alert alert-info" *ngIf="searchResponse.results.length == 0">
                    No orders matched your search criteria
                </div>
            </ng-template>
        </ngb-panel>
    </ngb-accordion>

</hfe-page-section>

<hfe-page-footer></hfe-page-footer>
