import {Component, OnInit} from '@angular/core';
import {OrderSearchParametersDto, OrderSearchResponseDto} from './order-lookup.model';
import {OrderLookupService} from './order-lookup.service';
import {SpinnerService} from '../common/spinner/spinner.service';

@Component({
    selector: 'hfe-order-lookup',
    templateUrl: './order-lookup.component.html',
    styleUrls: ['./order-lookup.component.less']
})
export class OrderLookupComponent implements OnInit {

    constructor(
        private orderLookupService: OrderLookupService,
        private spinner: SpinnerService
    ) {
    }

    searchParameters: OrderSearchParametersDto = {
        customerName: '',
        customerPhone: '',
        endDate: '',
        startDate: '',
        sellerName: '',
        ticketingReservationCode: '',
        sortDescending: false,
        sortField: null,
        maxRecordsDisplay: 200
    };
    searchResponse: OrderSearchResponseDto;
    errorMessage: string;

    ngOnInit(): void {
        const previousSearchParams = sessionStorage.getItem('B2BOrderLookupParams');
        if (previousSearchParams) {
            this.searchParameters = JSON.parse(previousSearchParams);
            this.load();
        }
    }

    load(): void {
        this.errorMessage = '';
        this.spinner.pushShow('Loading Orders');
        this.orderLookupService.search(this.searchParameters)
            .subscribe(response => {
                if (response.exceededMax) {
                    this.errorMessage = 'Too many orders to be displayed. Refine the search criteria.';
                } else {
                    this.searchResponse = response;
                    sessionStorage.setItem('B2BOrderLookupParams', JSON.stringify(this.searchParameters));
                }
                this.spinner.popShow();
            }, error => {
                console.log(error);
                this.errorMessage = error.message;
                this.spinner.popShow();
            });
    }

    resetSearch(): void {
        this.errorMessage = '';
        sessionStorage.removeItem('B2BOrderLookupParams');
        this.searchParameters.customerName = '';
        this.searchParameters.customerPhone = '';
        this.searchParameters.sellerName = '';
        this.searchParameters.ticketingReservationCode = '';
        this.searchParameters.startDate = '';
        this.searchParameters.endDate = '';
        this.searchResponse = null;
    }
}
