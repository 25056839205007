import {Component, OnInit} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CartItemChangeComponent} from '../../shopping-cart/cart-item-change.component';
import {CartItemRemoveConfirmationComponent} from '../../shopping-cart/cart-item-remove-confirmation.component';
import {CartDetailItemDto, CartDetailItemGroupDto, CartDetailsDto} from '../../shopping-cart/shopping-cart.model';
import {ShoppingCartService} from '../../shopping-cart/shopping-cart.service';
import {MiniCartService} from '../mini-cart/mini-cart.service';
import {SessionService} from '../session/session.service';
import {SpinnerService} from '../spinner/spinner.service';
import {CreditWarningComponent} from './credit-warning.component';
import {AnalyticsService} from '../analytics/analytics.service';
import {AppConfigService} from '../../app-config.service';

@Component({
    selector: 'hfe-cart-summary',
    templateUrl: './cart-summary.component.html',
    styleUrls: ['./cart-summary.component.less']
})
export class CartSummaryComponent implements OnInit {

    constructor(
        private shoppingCartService: ShoppingCartService,
        private modalService: NgbModal,
        private miniCartService: MiniCartService,
        private spinnerService: SpinnerService,
        private sessionService: SessionService,
        private analyticsService: AnalyticsService,
        private appConfigService: AppConfigService
    ) {
    }

    cartDetails: CartDetailsDto;
    itemCount = 0;

    ngOnInit(): void {
        this.load();
    }

    load(): void {
        this.spinnerService.pushShow('Loading Shopping Cart');
        this.shoppingCartService
            .getCartDetails()
            .subscribe(result => {
                if (result.creditWarningMessage && this.sessionService.displayCreditWarning()) {
                    const modalRef = this.modalService.open(CreditWarningComponent, {backdrop: 'static', centered: true});
                    modalRef.componentInstance.message = result.creditWarningMessage;
                    this.sessionService.creditWarningHasBeenDisplayed();
                }
                this.cartDetails = result;
                this.itemCount = 0;
                this.miniCartService.refreshCartDetails();
                this.spinnerService.popShow();
            });
    }

    updateItem(group: CartDetailItemGroupDto, item: CartDetailItemDto): void {

        this.analyticsService.logPageEventWithPrefix(
            'click',
            'engagement',
            `b2b-update-open`);

        const modalRef = this.modalService.open(CartItemChangeComponent, {
            centered: true
        });
        modalRef.componentInstance.productGroup = group;
        modalRef.result.then(result => {
            if (result) {
                this.load();
            }
        }, result => {
            if (result) {
                this.load();
            }
        });
    }

    removeItem(item: CartDetailItemDto): void {

        this.analyticsService.logPageEventWithPrefix(
            'click',
            'engagement',
            `b2b-remove-open`);

        const modalRef = this.modalService.open(CartItemRemoveConfirmationComponent, {centered: true});

        modalRef.componentInstance.items = new Array({
            id: item.id,
            displayName: item.displayName
        });

        modalRef.result.then(result => {
            if (result) {
                this.removeItemFinalize(item);
            }
        }, reason => {
            if (reason) {
                this.removeItemFinalize(item);
            }
        });
    }

    removeItemFinalize(item: CartDetailItemDto): void {

        this.shoppingCartService.modifyCartItem(
            [
                {
                    id: item.id,
                    performanceId: item.performanceId,
                    showCategoryId: item.showCategoryId,
                    quantity: 0
                }
            ])
            .subscribe(result => {

                this.analyticsService.logPageEventWithPrefix(
                    'click',
                    'engagement',
                    `b2b-remove-removed`);

                this.load();
            });
    }
}
