import { Injectable } from '@angular/core';
import { CartAddress, CartAddressValidation } from '../api-clients/shared-cart-api.model';

@Injectable({
    providedIn: 'root'
})
export class ValidationService {

    //#endregion

    constructor() {
    }

    //#region Properties

    readonly passwordMinLength = 8;
    private rePasswordLower = /[a-z]/;
    private rePasswordUpper = /[A-Z]/;
    private rePasswordDigit = /[0-9]/;
    private rePasswordSpecial = /[^A-Za-z0-9]/;
    private emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
    private reUSZipRegex = /^\d{5}$/;
    private reCNPostalCodeRegex = /^[a-z]\d[a-z]\d[a-z]\d$/i;
    private readonly firstNameMaxLength = 35;
    private readonly lastNameMaxLength = 35;
    private readonly cityMaxLength = 50;
    private readonly zipCodeMaxLength = 10;
    public readonly CountryId_USA = 80;
    public readonly CountryId_CAN = 17;

    //#region Public validation methods

    /**
     * True if phone number is 10 digits after non-digits are removed
     */
    phoneNumber10digit(s: string): boolean {
        const cleanPhone = this.cleanPhoneNumber(s);
        return cleanPhone.length === 10;
    }

    /**
     * True if phone number is between 10 digits (USA, CAN) and 15 digits (international) after non-digits are removed
     */
    phoneNumberValidLength(s: string): boolean {
        const cleanPhone = this.cleanPhoneNumber(s);
        return cleanPhone.length >= 10 && cleanPhone.length <= 15;
    }

    /**
     * Returns only the digits of the phone number (or empty string)
     */
    cleanPhoneNumber(phoneNumber: string): string {
        if (!phoneNumber) {
            return '';
        }
        return phoneNumber.replace(/\D/g, '');
    }

    /**
     * True if the password is complex enough. Based on 4 categories required, 8 min length
     */
    password(s: string): boolean {
        return s != null &&
            this.passwordMinLength <= s.length &&
            this.rePasswordLower.test(s) &&
            this.rePasswordUpper.test(s) &&
            this.rePasswordDigit.test(s) &&
            this.rePasswordSpecial.test(s);
    }

    emailAddress(s: string): boolean {
        return s && this.emailRegex.test(s);
    }

    /**
     * Removes non-alpha-numeric characters (e.g. spaces)
     */
    cleanPostalCode(postalCode: string): string {
        if (!postalCode) {
            return '';
        }
        return postalCode.replace(/[^0-9a-z]/ig, '');
    }

    usZipCode(s: string): boolean {
        return this.reUSZipRegex.test(s);
    }

    cnPostalCode(s: string): boolean {
        return this.reCNPostalCodeRegex.test(s);
    }

    //#endregion

    //#region CartAddress methods

    cartAddressIsEmpty(addr?: CartAddress): boolean {
        return !(addr &&
            (addr.firstName
                || addr.lastName
                || addr.phoneNumber
                || addr.countryId
                || addr.address1
                || addr.address2
                || addr.city
                || addr.stateProvinceId
                || addr.otherStateProvince
                || addr.zipCode));
    }

    cartAddressesEqual(lhsAddr: CartAddress | undefined, rhsAddr: CartAddress | undefined): boolean {
        if (lhsAddr === undefined && rhsAddr === undefined) { return true; }
        if (lhsAddr !== undefined && rhsAddr === undefined) { return false; }
        if (lhsAddr === undefined && rhsAddr !== undefined) { return false; }

        return lhsAddr.firstName === rhsAddr.firstName
            && lhsAddr.lastName === rhsAddr.lastName
            && lhsAddr.phoneNumber === rhsAddr.phoneNumber
            && lhsAddr.countryId === rhsAddr.countryId
            && lhsAddr.address1 === rhsAddr.address1
            && lhsAddr.address2 === rhsAddr.address2
            && lhsAddr.city === rhsAddr.city
            && lhsAddr.stateProvinceId === rhsAddr.stateProvinceId
            && lhsAddr.otherStateProvince === rhsAddr.otherStateProvince
            && lhsAddr.zipCode === rhsAddr.zipCode;
    }

    cartAddressIsValid(addr: CartAddress | undefined, skipIfEmpty: boolean): CartAddressValidation {
        if (!addr) { return new CartAddressValidation(); }

        let retValidation = new CartAddressValidation();

        this.cartFirstName(retValidation, addr, skipIfEmpty);
        this.cartLastName(retValidation, addr, skipIfEmpty);
        this.cartPhone(retValidation, addr, skipIfEmpty);
        this.cartAddress1(retValidation, addr, skipIfEmpty);
        this.cartCity(retValidation, addr, skipIfEmpty);
        this.cartState(retValidation, addr, skipIfEmpty);
        this.cartZip(retValidation, addr, skipIfEmpty);
        this.cartCountry(retValidation, addr, skipIfEmpty);

        return retValidation;
    }

    // cartFirstName(addr: CartAddress, skipIfEmpty?: boolean): boolean {
    //     return (skipIfEmpty === true && !addr.firstName)
    //         || (addr.firstName?.length > 0
    //             && addr.firstName?.length <= this.firstNameMaxLength);
    // }

    cartFirstName(v: CartAddressValidation, addr: CartAddress, skipIfEmpty: boolean): void {
        v.firstName = (skipIfEmpty && !addr.firstName)
            || (addr.firstName?.length > 0
                && addr.firstName?.length <= this.firstNameMaxLength);
    }

    cartLastName(v: CartAddressValidation, addr: CartAddress, skipIfEmpty: boolean): void {
        v.lastName = (skipIfEmpty && !addr.lastName)
            || (addr.lastName?.length > 0
                && addr.lastName?.length <= this.lastNameMaxLength);
    }

    cartPhone(v: CartAddressValidation, addr: CartAddress, skipIfEmpty: boolean): void {
        const digitsOnly = this.cleanPhoneNumber(addr.phoneNumber);
        if (skipIfEmpty && !digitsOnly) {
            v.phoneNumber = true;
            return;
        }

        if (addr.phoneUseNanpFormat) {
            v.phoneNumber = digitsOnly.length === 10;
        } else {
            v.phoneNumber = digitsOnly.length > 0 && digitsOnly.length <= 15;
        }
    }

    cartAddress1(v: CartAddressValidation, addr: CartAddress, skipIfEmpty: boolean): void {
        v.address1 = (skipIfEmpty || !!addr.address1);
    }

    cartCity(v: CartAddressValidation, addr: CartAddress, skipIfEmpty: boolean): void {
        v.city = (skipIfEmpty && !addr.city)
            || (addr.city?.length > 0
                && addr.city?.length <= this.cityMaxLength);
    }

    cartState(v: CartAddressValidation, addr: CartAddress, skipIfEmpty: boolean): void {
        if (skipIfEmpty) {
            v.stateProvinceId = true;
            v.otherStateProvince = true;
        }
        else if (addr.countryId === this.CountryId_USA || addr.countryId === this.CountryId_CAN) {
            v.stateProvinceId = !!addr.stateProvinceId;
            v.otherStateProvince = true;
        }
        else {
            v.stateProvinceId = true;
            v.otherStateProvince = true; // !!addr.otherStateProvince; // I don't see this currently required
        }
    }

    cartZip(v: CartAddressValidation, addr: CartAddress, skipIfEmpty: boolean): void {
        const cleanZip = this.cleanPostalCode(addr.zipCode);

        if (skipIfEmpty && !cleanZip) {
            v.zipCodeFormat = true;
            v.zipCodeLength = true;
            return;
        }

        if (!addr.countryId || addr.countryId === this.CountryId_USA) {
            v.zipCodeFormat = cleanZip && this.usZipCode(cleanZip);
            v.zipCodeLength = true;
        }
        else if (addr.countryId === this.CountryId_CAN) {
            v.zipCodeFormat = cleanZip && this.cnPostalCode(cleanZip);
            v.zipCodeLength = true;
        }
        else {
            v.zipCodeLength = cleanZip && cleanZip.length <= this.zipCodeMaxLength;
            
            // Only length is validated for other countries
            v.zipCodeFormat = true
        }
    }

    cartCountry(v: CartAddressValidation, addr: CartAddress, skipIfEmpty: boolean): void {
        v.countryId = (skipIfEmpty || !!addr.countryId);
    }

    //#endregion
}
