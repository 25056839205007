<div class="modal-header">
  <h4 class="modal-title">Credit Card Information</h4>
  <button type="button" class="btn-close close" (click)="activeModal.dismiss()">
  </button>
</div>
<div class="container-fluid">  
  <div *ngIf="error" class="col-12 alert alert-danger">{{error}}</div>
  <div class="row" *ngIf="iframeData">
    <div *ngIf="canOverridePrice" class="form-group col-12 overridePrice">
      <label class="form-label" for="overridePrice">Override Price</label>
      <input class="form-control" type="text" name="overridePrice" id="overridePrice" [(ngModel)]="overridePrice">
    </div>
    <div class="form-group col-12">
      <label class="form-label" for="cardholderName">Cardholder Name</label>
      <input class="form-control" type="text" name="cardholderName" id="cardholderName" [(ngModel)]="cardholderName" maxlength="26">
    </div>
    <div class="form-group col-12">
      <label class="form-label" for="streetAddress">Cardholder Street Address</label>
      <input class="form-control" type="text" name="streetAddress" id="streetAddress" [(ngModel)]="streetAddress" (blur)="addressBlur()">
    </div>
  </div>
  <div #freedomPayContainer *ngIf="iframeData" [innerHTML]="iframeData | safeHtml"></div>
</div>
