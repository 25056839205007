import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SessionService} from '../session/session.service';
import {AppConfigService} from '../../app-config.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizedGuard  {

    constructor(
        private sessionService: SessionService,
        private router: Router,
        private appConfigService: AppConfigService
    ) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
        return this.sessionService.getCurrentSessionData()
            .pipe(map(sessionData => {
                if (!sessionData || !sessionData.userAuthenticated) {
                    const config = this.appConfigService.getConfig();
                    window.location.href = `${config.serverAppRoot}security/auth-redirect?returnUrl=${encodeURI(state.url)}`;
                    return false;
                }
                return true;
            }));
    }
}
