<div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Session Ending</h4>
</div>
<div class="modal-body text-center">
    <div class="container-fluid">
        <p>
            {{message}}
        </p>
    </div>
</div>
<div class="modal-footer">
    <button class="btn btn-primary" (click)="activeModal.close()" >Continue</button>
</div>
