import {Component, Input, OnInit} from '@angular/core';
import {SessionService} from '../session/session.service';

@Component({
    selector: 'hfe-external-page-header',
    templateUrl: './external-page-header.component.html',
    styleUrls: ['./external-page-header.component.less']
})
export class ExternalPageHeaderComponent implements OnInit {

    constructor(
        public sessionService: SessionService,
    ) {
    }

    @Input() title: string;

    ngOnInit(): void {
    }

}
