import { Component, OnInit } from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TimeoutPopupComponent} from './timeout-popup.component';

@Component({
  selector: 'hfe-session-timeout',
  templateUrl: './session-timeout.component.html',
  styleUrls: ['./session-timeout.component.less']
})
export class SessionTimeoutComponent implements OnInit {

  constructor(
      private modalService: NgbModal
  ) { }

  ngOnInit(): void {
      this.modalService.open(TimeoutPopupComponent, {
          backdrop: 'static',
          centered: true,
          keyboard: false
      });
  }

}
