import {Injectable} from '@angular/core';
import {B2BApiClientService} from '../common/b2b-api-client/b2b-api-client.service';
import {Observable} from 'rxjs';
import {OrderDetailsDto} from 'lib-commerce-shared';

@Injectable({
    providedIn: 'root'
})
export class ConfirmationService {

    constructor(
        private apiClient: B2BApiClientService
    ) {
    }

    getOrderDetails(): Observable<OrderDetailsDto> {
        return this.apiClient.b2bGet<OrderDetailsDto>('order/details');
    }

    getTicketContent(): Observable<string> {
        return this.apiClient.b2bGetText('order/digital-tickets');
    }
}
