import {Component, ElementRef, HostListener, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {LocalSpinnerService} from '../local-spinner/local-spinner.service';
import {FreedomPayResponse} from './freedom-pay.model';
import {FreedomPayService} from './freedom-pay.service';
import {CheckoutService} from '../checkout/checkout.service';
import {AnalyticsService} from '../common/analytics/analytics.service';

@Component({
    selector: 'freedom-pay',
    templateUrl: './freedom-pay.component.html',
    styleUrls: ['./freedom-pay.component.less']
})
export class FreedomPayComponent implements OnInit {

    constructor(
        private freedomPayService: FreedomPayService,
        private checkoutService: CheckoutService,
        private router: Router,
        public activeModal: NgbActiveModal,
        private spinner: LocalSpinnerService,
        private element: ElementRef,
        private analyticsService: AnalyticsService) {
    }

    iframeData: string;
    sessionKey: string;
    error: string;
    canOverridePrice = false;
    overridePrice: number;
    cardholderName: string;
    streetAddress: string;
    processing = false;
    @ViewChild('freedomPayContainer', {static: false}) freedomPayContainer: ElementRef;


    ngOnInit(): void {
        this.spinner.element = this.element;
        this.setupSession();
    }

    setupSession() {
        this.spinner.show();
        this.freedomPayService.getSession().subscribe(results => {
            if (results.success) {
                if (results.iFrame) {
                    this.iframeData = results.iFrame;
                }
                if (results.sessionKey) {
                    this.sessionKey = results.sessionKey;
                }
                this.canOverridePrice = results.canOverridePrice;
            } else {
                this.error = results.message;
                this.spinner.hide();
            }
        }, error => {
            this.error = 'Credit card processing is unavailable';
            console.log(error);
            this.spinner.hide();
        });
    }

    addressBlur() {
        this.freedomPayContainer.nativeElement.firstChild.focus();
    }

    @HostListener('window:message', ['$event']) onMessage(event) {
        if (event.origin.indexOf('freedompay.com') < 0) {
            return;
        }
        switch (event.data.type) {
            case 1:
                this.error = '';
                for (var i = 0; i < event.data.data.errors.length; i++) {
                    console.log('Error message: ' + event.data.data.errors[i].message);
                }
                this.error = 'An error occurred initializing your transaction.  Please try again.';
                this.setupSession();
                break;
            case 2:
                this.freedomPayContainer.nativeElement.firstChild.height = event.data.data.height;
                if (!this.processing) {
                    this.spinner.hide();
                }
                break;
            case 3:
                this.error = '';
                this.processing = true;
                this.spinner.show();

                this.analyticsService.logPageEventWithPrefix(
                    'click',
                    'engagement',
                    `b2b-checkout-pay`);

                this.checkoutService.submitOrder(
                    event.data.data.paymentKeys[0],
                    this.cardholderName,
                    this.streetAddress,
                    this.overridePrice
                ).subscribe(result => {
                    this.spinner.hide();
                    if (result.success) {
                        this.activeModal.close({success: true});
                    } else {
                        if (result.paymentMessage) {
                            this.error = result.paymentMessage;
                        } else {
                            this.activeModal.close({success: false, message: result.message});
                        }
                    }
                    this.processing = false;
                }, error => {
                    this.error = 'Credit card processing is unavailable';
                    console.log(error);
                    this.spinner.hide();
                    this.processing = false;
                });
                break;
            case 4:
                if (!event.data.data.isValid) {
                    console.log(event.data.data.message);
                }
                break;
        }
    }
}
