<hfe-page-header [hasCart]="false">
    <span>Dashboard</span>
</hfe-page-header>
<hfe-page-section>
    <div class="dashboard-section">
        <div class="dashboard-container d-flex flex-row justify-content-start mt-5">
            <div class="dashboard-button-container d-flex flex-row flex-sm-column" *ngIf="canSellProducts"
                routerLink="/product-selection">
                <fa-icon icon="shopping-cart"></fa-icon>
                <div class="button-title justify-self-center align-self-center">Sales</div>
            </div>
            <div class="dashboard-button-container d-flex flex-row flex-sm-column" *ngIf="canGenerateReports"
                routerLink="/reports">
                <fa-icon icon="cog"></fa-icon>
                <div class="button-title justify-self-center align-self-center">Reports</div>
            </div>
            <div class="dashboard-button-container d-flex flex-row flex-sm-column" *ngIf="canReviewOrders"
                routerLink="/order-lookup">
                <fa-icon icon="receipt"></fa-icon>
                <div class="button-title justify-self-center align-self-center">Order History</div>
            </div>
        </div>
        <div class="d-flex flex-row justify-content-center mt-5">
            <div class="contact-message">
                <hfe-resource extResourceId="B2BDashboardContactMessage">

                </hfe-resource>
            </div>
        </div>
    </div>
</hfe-page-section>
<hfe-page-footer></hfe-page-footer>