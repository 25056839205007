import { NgModule } from '@angular/core';
import { DatePipe } from '@angular/common';
import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
    NgbModalModule,
    NgbDatepickerModule,
    NgbAccordionModule,
    NgbDropdownModule
} from '@ng-bootstrap/ng-bootstrap';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faCalendarAlt } from '@fortawesome/pro-solid-svg-icons';
import { LibCommerceSharedComponent } from './lib-commerce-shared.component';
import { QuantitySelectorComponent } from './components/quantity-selector/quantity-selector.component';
import { SpinnerOverlayComponent } from './components/spinner-overlay/spinner-overlay.component';
import { ReportParametersComponent } from './components/report-parameters/report-parameters.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { ElementLoadingComponent } from './components/element-loading/element-loading.component';
import { ElementLoadingDirective } from './components/element-loading/element-loading.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { MaskPhoneDirective } from './directives/MaskPhoneDirective';
import { MaskDateDirective } from './directives/MaskDateDirective';
import { ConfirmationModalComponent } from './components/order/confirmation-modal.component';
import { CartSummaryViewComponent } from './components/cart/cart-summary-view.component';
import { CartViewComponent } from './components/cart/cart-view.component';
import { VariantPassSelectorComponent } from './components/variant-pass-selector/variant-pass-selector.component';
import { VariantQuantitySelectorComponent } from './components/variant-quantity-selector/variant-quantity-selector.component';
import { InstallmentBillingDetailsViewComponent } from './components/installment-billing/installment-billing-details-view.component';
import { DndDirective } from './directives/dnd.directive';
import { InstallmentBillingTermsComponent } from './components/installment-billing/installment-billing-terms.component';
import { ConfirmationDetailsViewComponent } from './components/order/confirmation-details-view.component';
import { ConfirmationResendModalComponent } from './components/order/confirmation-resend-modal.component';
import { SharedResourceComponent } from './components/shared-resource/shared-resource.component';
import { InstallmentBillingTruthInLendingComponent } from './components/installment-billing/installment-billing-truth-in-lending.component';
import { HotelPartySelectionComponent } from './components/hotel/hotel-party-selection.component';
import { HotelPropertySelectionComponent } from './components/hotel/hotel-property-selection.component';
import { HotelRateSelectionComponent } from './components/hotel/hotel-rate-selection.component';


@NgModule({
    declarations: [
        LibCommerceSharedComponent,
        QuantitySelectorComponent,
        SpinnerOverlayComponent,
        ReportParametersComponent,
        MultiSelectComponent,
        ElementLoadingComponent,
        ElementLoadingDirective,
        SafeHtmlPipe,
        MaskPhoneDirective,
        MaskDateDirective,
        ConfirmationModalComponent,
        CartSummaryViewComponent,
        CartViewComponent,
        VariantPassSelectorComponent,
        VariantQuantitySelectorComponent,
        InstallmentBillingDetailsViewComponent,
        DndDirective,
        InstallmentBillingTermsComponent,
        ConfirmationDetailsViewComponent,
        ConfirmationResendModalComponent,
        SharedResourceComponent,
        InstallmentBillingTruthInLendingComponent,
        HotelPartySelectionComponent,
        HotelPropertySelectionComponent,
        HotelRateSelectionComponent,
    ],
    imports: [
        FormsModule,
        NgbModalModule,
        NgbDatepickerModule,
        NgbAccordionModule,
        NgbDropdownModule,
        CommonModule,
        BrowserModule,
        FontAwesomeModule,
        RouterModule
    ],
    exports: [
        LibCommerceSharedComponent,
        MultiSelectComponent,
        QuantitySelectorComponent,
        ReportParametersComponent,
        ElementLoadingComponent,
        ElementLoadingDirective,
        SafeHtmlPipe,
        MaskPhoneDirective,
        MaskDateDirective,
        ConfirmationModalComponent,
        CartSummaryViewComponent,
        CartViewComponent,
        VariantPassSelectorComponent,
        VariantQuantitySelectorComponent,
        InstallmentBillingDetailsViewComponent,
        DndDirective,
        InstallmentBillingTermsComponent,
        ConfirmationDetailsViewComponent,
        SharedResourceComponent,
        InstallmentBillingTruthInLendingComponent,
        HotelPartySelectionComponent,
        HotelPropertySelectionComponent,
        HotelRateSelectionComponent,
    ],
    providers: [
        DatePipe
    ]
})
export class LibCommerceSharedModule {
    constructor(library: FaIconLibrary) {
        library.addIcons(
            faCalendarAlt);
    }
}
