import {Injectable, Injector} from '@angular/core';
import {AppConfigService} from '../../app-config.service';
import {Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SessionExpirationWarningComponent} from './session-expiration-warning.component';
import {HttpClient} from '@angular/common/http';
import {CurrentSessionDetailsDto} from '../session/session.model';

@Injectable({
    providedIn: 'root'
})
export class IdleService {

    constructor(
        private injector: Injector,
    ) {
    }

    running = false;
    idleSince = new Date();
    hasWarning = false;
    interval: number;

    resetIdle(): void {

        // Make sure the timer is running
        if (!this.running) {
            this.interval = setInterval(() => {
                this.tick();
            }, 1000);
            this.running = true;
        }

        this.idleSince = new Date();
    }

    tick(): void {
        const appConfig = this.injector.get(AppConfigService);

        const expirationDT = new Date(Date.now() - (appConfig.getConfig().sessionTimeout * 1000));

        if (expirationDT > this.idleSince) {
            const modalService = this.injector.get(NgbModal);
            modalService.dismissAll();
            const router = this.injector.get(Router);
            clearInterval(this.interval);
            router.navigate(['session-timeout']);
        }

        const warningDT = new Date(Date.now() - (appConfig.getConfig().sessionWarningTimeout * 1000));
        if (warningDT > this.idleSince && !this.hasWarning) {
            const modalService = this.injector.get(NgbModal);
            const warningModal = modalService.open(SessionExpirationWarningComponent,
                {
                    centered: true,
                    backdrop: 'static',
                    keyboard: false
                });
            this.hasWarning = true;
            warningModal.result.then(() => {
                this.refreshSession();
                this.hasWarning = false;
            }, () => {
                this.hasWarning = false;
            });
        }
    }

    refreshSession(): void {
        const httpClient = this.injector.get(HttpClient);
        httpClient.get<CurrentSessionDetailsDto>('/api/session')
            .subscribe();
        this.resetIdle();
    }
}
