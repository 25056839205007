import {Component, Injectable, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Observable, of, Subscription} from 'rxjs';
import {map} from 'rxjs/operators';
import {FreedomPayComponent} from '../payment/freedom-pay.component';
import {ShoppingCartService} from '../shopping-cart/shopping-cart.service';
import {AvailableDeliveryMethodDto, AvailablePaymentMethodDto} from './checkout.model';
import {CheckoutService} from './checkout.service';
import {MiniCartService} from '../common/mini-cart/mini-cart.service';
import {SpinnerService} from '../common/spinner/spinner.service';
import {AnalyticsService} from '../common/analytics/analytics.service';
import { TermsComponent } from '../terms/terms.component';
import { TermsService } from '../terms/terms.service';

@Injectable()
export class CanDeactivateCheckout  {

    canDeactivate(
        component: CheckoutComponent,
        currentRoute: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (nextState.url.indexOf('/payment/') < 0
            && nextState.url.indexOf('/checkout-confirmation') < 0) {
            return component.navigateAway();
        }
        return true;
    }
}

@Component({
    selector: 'hfe-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.less']
})
export class CheckoutComponent implements OnInit, OnDestroy {

    constructor(
        private checkoutService: CheckoutService,
        private cartService: ShoppingCartService,
        private miniCartService: MiniCartService,
        private router: Router,
        private modalService: NgbModal,
        private spinnerService: SpinnerService,
        private analyticsService: AnalyticsService,
        private termsService: TermsService
    ) {
    }

    deliveryMethods: AvailableDeliveryMethodDto[];
    paymentMethods: AvailablePaymentMethodDto[];

    loaded = false;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    zipCode: string;
    deliveryMethodId: string;
    paymentMethodId: string;
    referenceNumber: string;
    errorMessages: string[];
    successMessage = '';
    skipNavSave = false;
    itemChangedSubscription: Subscription;
    invalidDeliveryMethod = false;
    invalidPaymentMethod = false;
    invalidFirstName = false;
    invalidLastName = false;
    invalidPhone = false;
    invalidEmailRequired = false;
    invalidEmail = false;
    invalidZip = false;
    acceptTerms: boolean;
    termsOfSaleVersionId: string;
    termsOfUseVersionId: string;

    ngOnInit(): void {

        this.analyticsService.logPageView('/checkout');

        this.load();

        // go to the product selection page if the items in the cart go to zero
        this.itemChangedSubscription = this.miniCartService.itemCountChanged.subscribe(itemCount => {
            if (itemCount === 0) {
                this.router.navigate(['/product-selection']).then();
            }
        });
    }

    ngOnDestroy(): void {
        // make sure we unsubscribe from the subject or we will add a subscription every time the checkout component is loaded
        this.itemChangedSubscription.unsubscribe();
    }

    load(): void {
        this.loaded = false;
        this.checkoutService.getDeliveryMethods()
            .subscribe(deliveryMethods => {
                this.deliveryMethods = deliveryMethods;
                this.setDeliveryMethodIfSingle();
            });
        this.checkoutService.getPaymentMethods()
            .subscribe(paymentMethods => {
                this.paymentMethods = paymentMethods;
                this.setPaymentMethodIfSingle();
            });
        this.termsService.getCurrentTermsSummary()
            .subscribe(termsSummary => {
                this.termsOfUseVersionId = termsSummary.termsOfUseVersionId;
                this.termsOfSaleVersionId = termsSummary.termsOfSaleVersionId;
            });
        this.cartService.getCartDetails()
            .subscribe(result => {
                this.loaded = true;
                if (result != null) {
                    this.deliveryMethodId = result.deliveryMethodId;
                    this.paymentMethodId = result.paymentMethodId;
                    this.firstName = result.firstName;
                    this.lastName = result.lastName;
                    this.emailAddress = result.emailAddress;
                    this.phoneNumber = result.phoneNumber;
                    this.zipCode = result.zipCode;
                }
                this.setDeliveryMethodIfSingle();
                this.setPaymentMethodIfSingle();
            });
    }

    setDeliveryMethodIfSingle(): void {
        if (this.deliveryMethods
            && this.deliveryMethods.length === 1
            && this.loaded
            && !this.deliveryMethodId) {
            this.deliveryMethodId = this.deliveryMethods[0].id;
        }
    }

    setPaymentMethodIfSingle(): void {
        if (this.paymentMethods
            && this.paymentMethods.length === 1
            && this.loaded
            && !this.paymentMethodId) {
            this.paymentMethodId = this.paymentMethods[0].id;
        }
    }

    headerCartChange(): void {
        // Assume that a cart change is a clear and prevent saving of cart details
        this.skipNavSave = true;
    }

    navigateAway(): Observable<boolean> {

        if (this.skipNavSave) {
            return of(true);
        }

        return this.checkoutService.updateCartSettings({
            firstName: this.firstName,
            lastName: this.lastName,
            zipCode: this.zipCode,
            emailAddress: this.emailAddress,
            phoneNumber: this.phoneNumber,
            deliveryMethodId: this.deliveryMethodId,
            paymentMethodId: this.paymentMethodId,
            ignoreValidationErrors: true,
            referenceNumber: this.referenceNumber
        }).pipe(map(() => {
            return true;
        }));
    }

    saveNewCheckout(): void {
        this.errorMessages = [];
        const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;
        const phone = this.phoneNumber ? this.phoneNumber.replace(/[^0-9]/g, '') : '';
        const zip = this.zipCode ? this.zipCode.replace('-', '').replace(' ', '') : '';
        const zipRegex = /^\d{5}$|[a-zA-Z]\d[a-zA-Z]\d[a-zA-Z]\d$/;

        this.invalidDeliveryMethod = !this.deliveryMethodId;
        this.invalidPaymentMethod = !this.paymentMethodId;
        this.invalidFirstName = !this.firstName || this.firstName.length > 35;
        this.invalidLastName = !this.lastName || this.firstName.length > 35;

        const cleanPhone = (!phone ? '' : phone).replace(/\D/g, '');
        this.invalidPhone = cleanPhone.length !== 10;

        if (this.deliveryMethodId) {
            const deliveryMethod = this.deliveryMethods
                .filter(o => o.id === this.deliveryMethodId)[0];
            this.invalidEmailRequired = deliveryMethod.emailAddressRequired
                && !this.emailAddress;
        } else {
            this.invalidEmailRequired = !this.emailAddress;
        }

        this.invalidEmail = this.emailAddress && !emailRegex.test(this.emailAddress);

        this.invalidZip = !zipRegex.test(zip);

        // Found an error
        if (this.invalidDeliveryMethod
            || this.invalidPaymentMethod
            || this.invalidFirstName
            || this.invalidLastName
            || this.invalidPhone
            || this.invalidEmailRequired
            || this.invalidEmail
            || this.invalidZip) {
            return;
        }

        this.spinnerService.pushShow('Saving cart settings');

        // Update cart settings
        this.checkoutService.updateCartSettings({
            firstName: this.firstName,
            lastName: this.lastName,
            zipCode: this.zipCode,
            emailAddress: this.emailAddress,
            phoneNumber: cleanPhone,
            deliveryMethodId: this.deliveryMethodId,
            paymentMethodId: this.paymentMethodId,
            ignoreValidationErrors: false,
            referenceNumber: this.referenceNumber,
            acceptTerms: this.acceptTerms,
            termsOfSaleVersionId: this.termsOfSaleVersionId,
            termsOfUseVersionId: this.termsOfUseVersionId
        }).subscribe(updateResult => {
            this.spinnerService.popShow();
            if (!updateResult.success) {
                this.errorMessages = updateResult.messages;
            } else {
                // see if we navigate to payment
                const paymentMethod = this.paymentMethods.find(method => method.id === this.paymentMethodId);
                if (paymentMethod.processor === 'freedom-pay') {
                    this.spinnerService.popShow();
                    const modalRef = this.modalService.open(FreedomPayComponent, {backdrop: 'static', keyboard: false});
                    modalRef.result.then(result => {
                        if (result.success) {
                            this.skipNavSave = true;
                            this.router.navigate(['/confirmation']).then()
                        } else {
                            this.errorMessages = [result.message];
                        }
                    }, () => {
                    });
                }

                // done, so checkout
                else {
                    this.checkout();
                }
            }
        });
    }

    checkout(): void {

        this.analyticsService.logPageEventWithPrefix(
            'click',
            'engagement',
            `b2b-checkout-pay`);

        this.spinnerService.pushShow('Processing Order');
        this.checkoutService.submitOrder(null, null, null, null)
            .subscribe(result => {
                this.spinnerService.popShow();
                if (result.success) {
                    this.skipNavSave = true;
                    this.router.navigate(['/confirmation']).then()
                } else {
                    this.errorMessages = [result.message];
                }
            }, error => {
                this.spinnerService.popShow();
                console.log(error);
                this.errorMessages = error.message;
            });
    }

    showTerms(versionId: string, $event: Event): void {
        $event.preventDefault();
        const modal = this.modalService.open(TermsComponent, {
            size: 'xl'
        });

        modal.componentInstance.extVersionId = versionId;
        modal.componentInstance.isModal = true;
    }
}
