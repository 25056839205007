import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'hfe-external-page-footer',
  templateUrl: './external-page-footer.component.html',
  styleUrls: ['./external-page-footer.component.less']
})
export class ExternalPageFooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
