import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'hfe-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.css']
})
export class QuantitySelectorComponent implements OnInit {

  constructor() { }

  @Input() quantity: number;
  @Output() quantityChanged: EventEmitter<number> = new EventEmitter<number>();
  @Output() validSelection: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() min = 1;
  @Input() max = 100;
  absMin: number = 0;
  invalidSelection = false;

  ngOnInit(): void {
    if (!this.quantity) this.quantity = 0;
    if(!this.min) this.min = 1;
    if(!this.max) this.max = 100;
  }

  textChange(value): void {
    this.quantity = +value;
    if(!this.quantity) this.quantity = 0;
    if(value < this.absMin || (value < this.min && value > this.absMin) || value > this.max){
      this.invalidSelection = true;
    }
    else{
      this.invalidSelection = false;
    }
    this.validSelection.emit(!this.invalidSelection);
    this.quantityChanged.emit(this.quantity);
  }

  incrementDown(): void {
    if(this.quantity > this.max){
      this.quantity = this.max;
    }
    else{
      (this.quantity > this.min) ? this.quantity -= 1 : this.quantity = this.absMin;
    }

    this.invalidSelection = false;
    this.validSelection.emit(!this.invalidSelection);
    this.quantityChanged.emit(this.quantity);
  }

  incrementUp(): void {
    if(this.quantity == this.absMin){
      this.quantity = this.min;
    }
    else if (this.quantity < this.max) {
      this.quantity += 1;
    }
    this.invalidSelection = false;
    this.validSelection.emit(!this.invalidSelection);
    this.quantityChanged.emit(this.quantity);
  }

}
