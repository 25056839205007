import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {B2BApiClientService} from '../b2b-api-client/b2b-api-client.service';
import {MiniCartDetailsDto} from './mini-cart.model';
import {SpinnerService} from '../spinner/spinner.service';

@Injectable({
    providedIn: 'root'
})
export class MiniCartService {

    constructor(
        private apiClient: B2BApiClientService,
        private spinnerService: SpinnerService
    ) {
    }

    loaded = false;
    itemCount: number;
    itemCountChanged = new Subject();

    refreshCartDetails(): void {
        this.spinnerService.pushShow('Loading Cart Summary');
        this.apiClient.b2bGet<MiniCartDetailsDto>('cart/mini-cart')
            .subscribe(details => {
                if (this.itemCount !== details.itemCount) {
                    this.itemCountChanged.next(details.itemCount);
                }
                this.itemCount = details.itemCount;
                this.loaded = true;
                this.spinnerService.popShow();
            });
    }
}
