<div class="navbar header-container-item">
    <div class="col-md-4">
        <div class="navbar-logo">
            <img *ngIf="sessionService?.siteLogUrl"
                 [src]="sessionService.siteLogUrl"
                 alt="Logo">
        </div>
    </div>
    <div class="col-md-4 simulate-user-notify d-flex justify-content-center">
        <div *ngIf="sessionService?.isProxy">
            Simulate User by {{sessionService.proxyUsername}}
        </div>
    </div>
    <div class="col-md-4">
        <div class="d-flex">
            <span class="navbar-brand">Client</span>
            <select name="clients" id="clients" class="form-select w-100"
                    [(ngModel)]="clientId"
                    (change)="checkCartItems() ? openModal(clearCartWarning) : clientSelectionChange(false)">
                <option *ngFor="let client of clients" [ngValue]="client.id">
                    {{client.tenantDisplayName}} ({{client.name}})
                </option>
            </select>
        </div>
    </div>
</div>
<div class="title-container px-3 py-2">
    <div class="row">
        <div class="col-sm-6 me-auto">
            <ng-content></ng-content>
            <a *ngIf="title" routerLink="/dashboard">Dashboard</a>
            <span *ngIf="title" class="px-2">/ {{title}}</span>
        </div>
        <div class="col-sm-6 ms-auto d-flex flex-row justify-content-end">
            <hfe-mini-cart (clearCart)="onMiniCartClear()" *ngIf="hasCart"></hfe-mini-cart>
            <div class="icon-container cursor-pointer">
                <a href="{{b2bPortalHelpUrl}}" target="_blank" (click)="helpClick()">
                    <fa-icon icon="question"></fa-icon>
                </a>
            </div>
            <div class="icon-container cursor-pointer" (click)="logout()">
                <fa-icon icon="sign-out-alt"></fa-icon>
            </div>
            <div class="username-container">
                <fa-icon icon="user">
                </fa-icon>
                {{sessionService.username}}
            </div>
        </div>
    </div>
</div>

<div *ngIf="sessionService?.siteMessage" class="alert alert-danger m-3">
    <span [innerHTML]="sessionService.siteMessage">
    </span>
</div>

<div class="alert alert-danger m-3" *ngIf="clearCartError">
    {{clearCartError}}
</div>

<ng-template #clearCartWarning let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Clear Cart Confirmation</h4>
    </div>
    
    <div class="modal-body">
        <p>Are you sure you want to clear the cart?</p>
    </div>

    <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="clearCart()">Yes</button>
        <button type="button" class="btn btn-secondary" (click)="closeModal()">No</button>
    </div>
</ng-template>
